import useCurrentLanguage from "../../../Components/userCurrentLanguage"

const MuiNe2024 = ({ openModal, closeModal, selectedImage }) => {

    const lang = useCurrentLanguage()
    if (lang == 'en') return (
        <>
            <h1>Skylink JSC’s 2024 Phan Thiet - Mui Ne Trip</h1>
            <p></p>
            <p>From June 6 to 9, 2024, Skylink JSC organized an exciting trip to Phan Thiet - Mui Ne. This was an opportunity for all employees to relax, enjoy the seaside atmosphere, and participate in valuable team-building activities.</p>
            <p>The trip not only provided moments of relaxation but also helped strengthen solidarity and cohesion among the company’s members. Here are some highlights from the trip:</p>
            <div className="image-container">
                <img src="/images/news/muine1.jpg" alt="Group Photo 1" onClick={() => openModal('/images/news/muine1.jpg', 'Group Photo 1')} />
                <img src="/images/news/muine2.jpg" alt="Team Building Activity" onClick={() => openModal("/images/news/muine2.jpg", 'Team Building Activity')} />
                <img src="/images/news/muine3.jpg" alt="Gala Dinner Evening" onClick={() => openModal("/images/news/muine3.jpg", 'Gala Dinner Evening')} />
            </div>

            {selectedImage && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal" onClick={closeModal}>
                            &times;
                        </button>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                                width: '100%', maxHeight: 700,
                                objectFit: 'contain', objectPosition: 'center'
                            }} />
                    </div>
                </div>
            )}

            <p>The team-building activities during the trip helped members understand each other better, work more effectively, and overcome challenges together. The trip concluded successfully, leaving many beautiful memories and promising future bonding opportunities.</p>
            <p>We, Skylink JSC, aim to provide more opportunities like this for all employees to interact, learn, and grow together.</p>
        </>
    )
    
    return (
        <>
            <h1>Chuyến Du Lịch Phan Thiết - Mũi Né 2024 của Skylink JSC</h1>
            <p></p>
            <p>Từ ngày 06 đến 09 tháng 6 năm 2024, công ty Skylink JSC đã tổ chức một chuyến du lịch đầy thú vị đến Phan Thiết - Mũi Né. Đây là dịp để toàn thể nhân viên cùng nhau thư giãn, tận hưởng không khí biển và tham gia các hoạt động team building bổ ích.</p>
            <p>Chuyến đi không chỉ mang lại những giây phút nghỉ ngơi, mà còn giúp tăng cường tình đoàn kết và sự gắn bó giữa các thành viên trong công ty. Dưới đây là một số hình ảnh nổi bật trong chuyến du lịch:</p>
            <div className="image-container">
                <img src="/images/news/muine1.jpg" alt="Hình ảnh nhóm 1" onClick={() => openModal('/images/news/muine1.jpg', 'Hình ảnh nhóm 1')} />
                <img src="/images/news/muine2.jpg" alt="Hoạt động team building" onClick={() => openModal("/images/news/muine2.jpg", 'Hoạt động team building')} />
                <img src="/images/news/muine3.jpg" alt="Buổi tối gala dinner" onClick={() => openModal("/images/news/muine3.jpg", 'Buổi tối gala dinner')} />
            </div>

            {selectedImage && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal" onClick={closeModal}>
                            &times;
                        </button>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                                width: '100%', maxHeight: 700,
                                objectFit: 'contain', objectPosition: 'center'
                            }} />
                    </div>
                </div>
            )}

            <p>Những hoạt động team building được tổ chức trong chuyến đi đã giúp các thành viên hiểu nhau hơn, làm việc hiệu quả hơn và cùng nhau vượt qua các thử thách. Chuyến du lịch đã kết thúc thành công tốt đẹp, để lại nhiều kỷ niệm đẹp và hứa hẹn những dịp gắn kết tiếp theo.</p>
            <p>Chúng tôi, Skylink JSC, mong muốn mang đến nhiều cơ hội như thế này để toàn thể nhân viên có thể giao lưu, học hỏi và cùng phát triển.</p>

        </>
    )
}

export default MuiNe2024