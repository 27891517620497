import React, { useEffect } from 'react';

const SkyLinkME = () => {

    return (
        <a href='http://skylinkme.vn' target='_blank'>
            <div id="skylink_me" style={{ borderWidth: 1, borderStyle: "solid", borderColor: 'gray', borderRadius: 99 }}>
                <span><img src='/images/skylink-me.jpg'></img></span>
            </div>
        </a>
    );
};

export default SkyLinkME;