export const SteelDoorSkylink = () => {
    return (
        <>
            <h3>General Requirements</h3>
            <p>Sky Link is a partner of TECKENTRUP, GOLDEN HOUSE, and KENVO Shanghai for steel doors. Door leaf and frame are made from galvanized steel. Surface is treated with electrostatic powder coating, offering better adhesion and durability compared to traditional painting. The color will not fade or come off over time. Heavy-duty stainless steel hinges are used for smooth opening and closing. The door has passed fire certification under TCVN, as well as international standards such as BS and UL.</p>

            <h3>Color</h3>
            <p>Any RAL color is available.</p>

            <h3>Door and Frame Handing Chart</h3>
            <p>Available on the website.</p>

            <h3>Door Accessories</h3>
            <p>Available on the website.</p>

            <h3>SSD-FR: Fire Rated Steel Door</h3>
            <p>Features: Dust-proof, easy to clean, pure plain, air-tight, fireproof protection. Covers the opening on fire walls.</p>
            <p>Frame: 1.5mm thick galvanized steel;</p>
            <p>Leaf: 1.0mm thick galvanized steel, filled with perlite with density 200kg/m3</p>
            <p>Surface Treatment: Powder coating, with RAL colors for choice;</p>
            <p>Hardware: Leveler lock, knob, auto seal, closer;</p>
            <p>Window: Option with clear fire glass or wire mesh glass.</p>

            <h3>SSD-STD: Steel Door</h3>
            <p>Features: Dust-proof, easy to clean, pure plain, air-tight, beautiful. Suitable for pharmaceutical, food, electric, lab, and industrial areas.</p>
            <p>Frame: 1.5mm thick galvanized steel;</p>
            <p>Leaf: 0.8mm thick galvanized steel, filled with honeycomb paper or rock wool;</p>
            <p>Surface Treatment: Powder coating, with RAL colors for choice;</p>
            <p>Hardware: Leveler lock, knob, auto seal, closer;</p>
            <p>Window: Double layer tempered glass, 400x600mm or customized.</p>
        </>
    )
}