export const PHONE_NUMBER = '(+84) 932 318 582'
export const EMAIL_SKYLINK = 'info@skylink.com.vn'
export const ADDRESS_MAIN = 'address_main'
export const ADDRESS_2 = 'address_2'
export const WORK_TIME = 'work_time'

export const INFO = {
    experience: new Date().getFullYear() - 2010,
    staff: 100,
    finished_project: 300,
    ongoing_project: 36,
    province: 40,
    national: 6,
    doanh_thu: 190
}

export const APP_NAME = " | Skylink JSC"