import React from 'react';
import FunBox02 from '../../Components/FunFactElements/FunBox02';
import { INFO } from '../../common';

const FunFactAbout = () => {
    const FunDataAbout = [
        {
            id: '1',
            img: 'images/funfact/p3.png',
            number: INFO.staff,
            name: 'staff'
        },
        {
            id: '2',
            img: 'images/funfact/p2.png',
            number: INFO.finished_project,
            name: 'projects',
            bf:"> "
        },
        {
            id: '3',
            img: 'images/funfact/vietnam.png',
            number: INFO.province,
            // name: 'Expert Builders'
            name: 'provinces',
        },
       
        {
            id: '5',
            img: 'images/funfact/money.png',
            number: INFO.doanh_thu,
            // name: 'Ongoing Project'
            name: 'revenue_2023',
            at:'billion'
        },
        {
            id: '4',
            img: 'images/funfact/earth.png',
            number: INFO.national,
            // name: 'Ongoing Project'
            name: 'contries2'
        }, 
    ]

    return (
        <div id="funfacts" className="funfacts2 section">
            <div className="funfacts3_bg_img">
                <div className="container">

                    <div className="row">
                        <div className="col">
                            <div className="funfacts3_bg">
                                {
                                    FunDataAbout.map(data =>
                                        <FunBox02
                                            key={data.id}
                                            data={data}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunFactAbout;