const RollShutterSkylink = () => {
    return (
        <>
            <h3>Aluminium Roller Shutter Door ARD-01</h3>
            <p>Aluminum roller shutter door doors are divided into 2 types: single skin layer and double skin layer doors. Shutter door can be most sunlight reflected, can be adjusted into the interior of the sun/light, reduce the heat into the room.</p>

            <h3>Technical Specifications</h3>
            <p>Maximum dimension: W x H - 12000mm x 8000mm</p>
            <p>Door slat: Single layer Aluminum - 1.0mm; Double layer Aluminium - 0.8 mm thick</p>
            <p>Slat height: 77mm or 100mm (Depending on door size)</p>
            <p>Optional: the addition of UP foam in the roller shutter door - double layer</p>
            <p>Side guides: Aluminum as standard and seals around side guide and adjustable seals on bottom of door; 1.5-2mm thickness depending on location</p>
            <p>Bottom bar: Aluminum alloy with PVC seal and safety sensor</p>
            <p>Motor: 1Phase 20V Industrial Motor with hauling chain to operate in case of power failures</p>
            <p>Power supply: 1Phase 220V, 50/60HZ</p>
            <p>Rotating shaft: 2.5mm thick steel tube</p>
            <p>Opening functions: Remote control & wall mounted button</p>
            <p>Color: RAL 9002, RAL 9006, RAL 9016</p>
            <p>Safety function: During the process of closing, when encountering any obstacle below the door, the motor will immediately stop</p>

            <h3>Standard Specifications</h3>
            <p>Single layer Aluminum</p>

            <h3>Optional Specifications</h3>
            <p>Insulate slat (PU inside); Double layer Aluminum</p>
            <p>Color: RAL 9002, RAL 9006, RAL 9016 Standard color</p>
            <p>Optional colors: Other RAL colors available with additional fee</p>

            <h3>Windows</h3>
            <p>Window slat</p>
        </>
    )
}

const SingleLayer = () => {
    return (
        <>
            <h3>Steel Single Skinned Roller Shutter Door SRD-02</h3>
            <p>Single skinned roller shutter door slats are made of single-layer 1.2mm galvanized steel, providing outstanding durability and color options.</p>
            <p>The bottom bar of the door is made up of aluminum profiles with rubber gaskets, including a sensor for the foot of the curtain, which helps the door stop when it encounters an obstacle.</p>

            <h3>Technical Specifications</h3>
            <p>Maximum dimension: W x H - 12000mm x 10000mm</p>
            <p>Door slat: Single layer galvanized steel - 1.2mm thickness</p>
            <p>Side guides: The top of the curtains and the gap between the wall and the door have seals to prevent dirt and insects from entering the warehouse from the outside.</p>
            <p>Bottom bar: Aluminum material, Rubber seal with Safety edge sensor</p>
            <p>Motor: 10W - 30W Electrical motor (Motor power depends on Door size); Hauling chain to operate in case of power failure</p>
            <p>Power supply: 1Phase 220V or 3 phases 380V, 50/60HZ</p>
            <p>Barrel: D165 × 5mm thick Steel tube</p>
            <p>Safety function: During the process of closing, when encountering any obstacle below the door, the motor will immediately stop</p>

            <h3>Color Options</h3>
            <p>Standard colors: RAL 9002 Grey white, RAL 9006 White aluminium</p>
            <p>Custom colors: Other RAL colors available with additional fee</p>

            <h3>Optional Specification</h3>
            <p>Increase windproof: An additional option can be provided to increase the windload by reinforcing the curtain, door rails, and rolling system so that the door can operate stably in environments with high wind levels.</p>
            <p>Window slat, Air vent slat</p>
        </>
    )
}

const DoubleLayer = () => {
    return (
        <>
            <h3>Steel Insulated Roller Shutter Door SRD-01</h3>
            <p>Steel insulated roller shutter doors are made of double-layer galvanized steel, with a PU core inside the curtain, providing outstanding features of heat transfer and soundproofing compartments.</p>

            <h3>Technical Specifications</h3>
            <p>Maximum dimension: WxH - 12000mm x 10000mm</p>
            <p>Double layer galvanized steel</p>
            <p>Slat height: 95mm</p>
            <p>Outer cladding: 0.45mm Galvanized Steel sheet</p>
            <p>Inner layer: Polyurethane (Density 40 - 45 kg/m<sup>3</sup>)</p>
            <p>Overall thickness: 26mm</p>
            <p>Color: Powder coated RAL 902</p>

            <h3>Aluminium Alloy with Brush Seal</h3>
            <p>Aluminium material, Rubber seal with Safety edge sensor</p>

            <h3>Motor</h3>
            <p>10W - 30W Electrical motor (Motor power depends on Door size)</p>
            <p>Hauling chain to operate in case of power failure</p>

            <h3>Galvanized Steel Thickness</h3>
            <p>0.8mm, inside with steel box frame</p>
            <p>Powder coated RAL 902</p>

            <h3>Power Supply</h3>
            <p>1Phase 220V or 3phases 380V, 50/60HZ</p>

            <h3>Barrel</h3>
            <p>D165 × 52mm thick Steel tube</p>

            <h3>Safety Function</h3>
            <p>During the process of closing, when encountering any obstacle below the door, the motor will immediately stop</p>

            <h3>Color</h3>
            <p>Standard RAL 9002 - Grey white</p>

            <h3>Bottom Bar</h3>
            <p>The bottom bar of the door is made up of Door slat aluminum profiles with rubber gaskets, including a sensor for the foot of the curtain, which helps the door stop when it encounters an obstacle.</p>

            <h3>Brush Seals</h3>
            <p>The top of the curtains and the gap between the wall and the door have brush seals that prevent dirt and insects from entering the warehouse from the outside.</p>

            <h3>Optional Specifications</h3>
            <p>An additional option can be provided to increase the windload by reinforcing the curtain, door rails, and rolling system so that the door can operate stably in environments with high wind levels.</p>

            <h3>Thermal Insulation</h3>
            <p>35 Thermal Insulation</p>

            <h3>Sound Insulation</h3>
            <p>Sound Insulation</p>

            <h3>Window Slat</h3>
            <p>Windproof Slat: Slat with windproof wheel to hold on the windproof side guides to prevent the slat from derailing</p>

            <h3>Windproof Side Guides</h3>
            <p>Galvanized steel material with rubber seal</p>

            <h3>Rolling Support</h3>
            <p>Automatic adjustment mechanism of rolling door, reducing the resistance and extending the service life</p>
        </>
    )
}

export { RollShutterSkylink, SingleLayer, DoubleLayer }