const DockShelterSkylink = () => {
    return (
        <>
            <h3>Mechanical Dock Shelter</h3>
            <p>Sky Link Joint Stock Company offers high-quality mechanical dock shelters designed to provide excellent protection and energy efficiency during loading and unloading operations. The dock shelters are built to accommodate various truck sizes and offer durable performance in different environmental conditions.</p>

            <h3>Technical Specifications</h3>
            <p>Width: 3400/3600 mm (Other dimensions available upon request)</p>
            <p>Height: 3400/3600 mm (Other dimensions available upon request)</p>
            <p>Projection: 600 mm</p>
            <p>Top curtain height: 1000 mm (Other dimensions available upon request)</p>
            <p>Side curtain material: Black PVC coated polyester trevira fabric, 3000gm/m²</p>
            <p>Material thickness: 3.0 mm</p>
            <p>Frame: Galvanised steel box section</p>
            <p>Fixing heights: 4600mm based on 1200mm dock height</p>
            <p>Color: Black</p>

            <h3>Features</h3>
            <p>- Collapsible, rotation upwards</p>
            <p>- Yellow guide</p>
            <p>- Prevents insects and dirt</p>

            <h3>After-Sales Service</h3>
            <p>All SKY LINK products have repair and maintenance spare parts stocked in Binh Duong Province and Ha Noi City. Our standard is providing after-sales service within 8 working hours.</p>

            <h3>Contact Information</h3>
            <p><strong>Head Office:</strong> C5 Dong Dua Area, Ha Cau Ward, Ha Dong District, Ha Noi City</p>
            <p><strong>Hanoi After Sale Warehouse and North Part Service Center</strong></p>
            <p><strong>Thai Nguyen Warehouse</strong></p>
            <p><strong>Binh Duong Service Office:</strong> Warehouse and service center for the southern part</p>
            <p><strong>Hotline:</strong> 0084 904 129 118</p>
            <p><strong>After Sale:</strong> 0084 904 726 998</p>
            <p><strong>Email:</strong> john.nguyen@skylink.com.vn</p>
            <p><strong>Website:</strong> <a href="http://www.skylink.com.vn">www.skylink.com.vn</a></p>

        </>
    )
}

const DockShelterLoadingSystem = () => {
    return (
        <>
            <h3>Loading Systems</h3>
            <p>Loading Systems provides a range of products and services designed to enhance the efficiency and safety of loading and unloading operations. Their offerings include advanced dock equipment, door systems, and integrated solutions tailored to meet the specific needs of various industries.</p>

            <h3>Products and Solutions</h3>
            <h4>Dock Levellers</h4>
            <p>Loading Systems' dock levellers are designed to ensure smooth and safe transition between the dock and the vehicle, accommodating different heights and loads efficiently.</p>

            <h4>Dock Shelters</h4>
            <p>Dock shelters by Loading Systems offer optimal protection against weather conditions, enhancing the energy efficiency and safety of loading bays.</p>

            <h4>Industrial Doors</h4>
            <p>Industrial doors from Loading Systems are built to meet the demands of various industrial environments, providing security, durability, and ease of use.</p>

            <h3>Technical Specifications</h3>
            <p>The document provides detailed technical specifications for each product, including dimensions, materials, and performance features, ensuring that customers can select the appropriate solutions for their specific requirements.</p>

            <h3>After-Sales Service</h3>
            <p>Loading Systems offers comprehensive after-sales support, including maintenance, repairs, and spare parts, ensuring that all products continue to perform optimally throughout their lifecycle.</p>

            <h3>Contact Information</h3>
            <p>For more information about Loading Systems' products and services, customers can reach out via the provided contact details:</p>
            <p><strong>Website:</strong> <a href="http://www.loadingsystems.com">www.loadingsystems.com</a></p>
        </>
    )
}

export { DockShelterSkylink, DockShelterLoadingSystem }