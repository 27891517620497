export const products = [
    {
        id: 1,
        img: '/images/services/icon1.png',
        bg: '/images/products/dock-leveler-1.png',
        title: 'Sàn nâng hạ',
        description: 'Sản phẩm Sàn nâng hạ giúp việc xếp dỡ hàng hóa diễn ra suôn sẻ, an toàn và hiệu quả trong hoạt động kho bãi...',
        tag: 'docking',
        route: 'dock-leveler',
        contents: [
            { title: 'Skylink', content: 'dock-leveler-skylink', pdffile: 'SKY LINK HDL-02_Dock leveler with Hinged Lip.pdf' },
            { title: 'Loading System', content: 'dock-leveler-loading-system', pdffile: 'Dock Equirement Loading system brochure.pdf' },
            { title: 'Fastlink', content: 'dock-leveler-fastlink', pdffile: 'Fastlink Dock leveler.pdf' }
        ]
    },
    {
        id: 2,
        img: '/images/services/icon1.png',
        bg: '/images/products/dock-shelter-1.png',
        title: 'Bộ trùm đuôi xe',
        description: 'Bộ trùm đuôi xe cung cấp khả năng bảo vệ khỏi thời tiết, tăng cường hiệu quả và an toàn trong quá trình bốc xếp hàng hóa...',
        tag: 'docking',
        route: 'dock-shelter',
        contents: [
            { title: 'Skylink', content: 'dock-shelter-skylink', pdffile: 'SKY LINK Dock shelter Catalogue.pdf' },
            { title: 'Loading System', content: 'dock-shelter-loading-system', pdffile: 'LOADING SYSTEMS 21EN-407NG.pdf' }
        ],
        hide_title: false
    },
    {
        id: 3,
        img: '/images/services/icon1.png',
        bg: '/images/products/fire-door-1.png',
        title: 'Cửa ngăn cháy',
        description: 'Cửa ngăn cháy tăng cường an toàn bằng cách ngăn chặn sự lan truyền của lửa và khói...',
        tag: 'industrial',
        route: 'fire-door',
        contents: [
            { title: 'Curtain', content: 'fire-door-curtain', pdffile: 'SKY LINK Fire curtain.pdf' },
            { title: 'Shutter EL60', content: 'fire-shutter-el60', pdffile: 'Fire shutter door Catalogue EI60.pdf' },
            { title: 'Shutter EL120', content: 'fire-shutter-el120', pdffile: 'Fire shutter door Catalogue EI120.pdf' }
        ],
        hide_title: false
    },
    {
        id: 4,
        img: '/images/services/icon1.png',
        bg: '/images/products/high-speed-door-1.png',
        title: 'Cửa cuốn tốc độ cao',
        description: 'Lắp đặt cửa cuốn tốc độ cao linh hoạt giúp giao thông nội bộ thông suốt…',
        tag: 'industrial',
        route: 'high-speed-door',
        contents: [
            { title: 'Skylink Catalogue', content: 'skylink-high-speed-door-catalogue', pdffile: 'SKY LINK Aluminum High speed door Catalogue.pdf' },
            { title: 'Ditec SectorReset', content: 'ditect-sector-reset', pdffile: 'DYNACO_D-311 EN.pdf' },
            { title: 'Ditec SoftReset', content: 'ditect-soft-reset', pdffile: 'Ditec-SoftReset-TDS-EN-LowRes.pdf' },
            { title: 'Ditec SoftResetFood', content: 'ditect-soft-reset-food', pdffile: 'Ditec-SoftResetFood-TDS-EN-LowRes.pdf' },
            { title: 'Ditec Traffic CM', content: 'ditect-traffic-cm', pdffile: 'Ditec-TrafficCM-TDS-EN-LowRes.pdf' },

            { title: 'DYNACO D-311', content: 'dynaco-d311', pdffile: 'DYNACO_D-311 EN.pdf' },
            { title: 'DYNACO D-313 CLEANROOM', content: 'dynaco-d313-cleanroom', pdffile: 'DYNACO_D-313_CLEANROOM_EN.pdf' },
            { title: 'DYNACO D-313 EMERGENCY', content: 'dynaco-d313-emegency', pdffile: 'DYNACO_D-313_EMERGENCY.pdf' },
            { title: 'DYNACO D-501 COMPACT', content: 'dynaco-d501-compact', pdffile: 'DYNACO_D-501_COMPACT_EN.pdf' },
            { title: 'DYNACO D-501 POWER', content: 'dynaco-d501-power', pdffile: 'DYNACO_D-501_POWER_EN.pdf' },
            { title: 'DYNACO M2 COMPACT', content: 'dynaco-m2-compact', pdffile: 'DYNACO_M2_COMPACT_EN.pdf' },
            { title: 'DYNACO M2 FREEZER', content: 'dynaco-m2-freezer', pdffile: 'DYNACO_M2_FREEZER_EN.pdf' },
            { title: 'DYNACO M2 POWER', content: 'dynaco-m2-power', pdffile: 'DYNACO_M2_POWER_EN.pdf' },
        ],
        hide_title: true
    },
    {
        id: 5,
        img: '/images/services/icon1.png',
        bg: '/images/products/roller-shutter-1.png',
        title: 'Cửa cuốn',
        description: 'Cửa cuốn cung cấp sự an toàn và bảo vệ cho cửa ra vào và cửa sổ…',
        tag: 'industrial',
        route: 'roller-shutter',
        contents: [
            { title: 'Skylink Catalogue', content: 'roll-shutter-skylink-catalogue', pdffile: 'SKYLINK Aluminum roller shutter door Catalogue.pdf' },
            { title: 'Single Layer', content: 'roll-shutter-single-layer', pdffile: 'SKY LINK Roller shutter Catalogue (Single layer).pdf' },
            { title: 'Double Layer', content: 'roll-shutter-double-layer', pdffile: 'SKY LINK Roller shutter Catalogue (Double layer).pdf' }
        ],
        hide_title: false
    },
    {
        id: 6,
        img: '/images/services/icon1.png',
        bg: '/images/products/sectional-door-1.png',
        title: 'Cửa trượt công nghiệp',
        description: 'Cửa trượt công nghiệp mang lại hiệu quả về không gian và khả năng cách nhiệt cho gara và các tòa nhà công nghiệp...',
        tag: 'industrial',
        route: 'sectional-door',
        contents: [
            { title: 'Skylink Section Door', content: 'skylink-section-door', pdffile: 'Fastlink Section door.pdf' },
            { title: 'Industrial Sectional Door', content: 'industrial-section-door', pdffile: 'Loading System industrial sectional door brochures.pdf' },
            { title: 'Sectional Door 21EN-601N', content: 'section-door-21en', pdffile: 'SECTIONAL DOOR 21EN-601N.pdf' },
            { title: 'Fastlink Section Door', content: 'fastlink-section-door', pdffile: 'Fastlink Section door.pdf' },
        ],
        hide_title: true
    },
    {
        id: 7,
        img: '/images/services/icon1.png',
        bg: '/images/products/steel-door-1.png',
        title: 'Cửa thép',
        description: 'Cửa thép mang lại sự an toàn và độ bền cao cho mục đích thương mại và công nghiệp…',
        tag: 'industrial',
        route: 'steel-door',
        contents: [
            { title: 'Skylink Steel Door', content: 'skylink-steel-door', pdffile: 'Sky Link Steel door brochures.pdf' },
        ],
        hide_title: true
    },
    {
        id: 8,
        img: '/images/services/icon1.png',
        bg: '/images/products/table-lift-1.png',
        title: 'Bàn nâng hạ',
        description: 'Bàn nâng giúp điều chỉnh chiều cao linh hoạt theo nhu cầu cho mọi điều kiện làm việc và cải thiện năng suất...',
        tag: 'docking',
        route: 'table-lift',
        contents: [
            { title: 'Skylink Table Lift', content: 'skylink-table-lift', pdffile: 'SKY LINK Table lift Catalogue.pdf' },
        ],
        hide_title: true
    },
    {
        id: 9,
        img: '/images/services/icon1.png',
        bg: '/images/products/accessory-1.png',
        title: 'Phụ kiện',
        description: 'Giúp ăng cường chức năng và khả năng tùy chỉnh cho nhiều thiết bị và hệ thống khác nhau...',
        tag: 'docking',
        route: 'accessory',
        contents: [
            { title: 'Dock Light', content: 'dock-light', pdffile: 'Catalog_Dock light.pdf' },
        ],
        hide_title: true
    },
]

export const products_en = [
    {
        id: 1,
        img: '/images/services/icon1.png',
        bg: '/images/products/dock-leveler-1.png',
        title: 'Dock Leveler',
        description: 'The Dock Leveler product helps facilitate smooth, safe, and efficient loading and unloading activities in the warehouse...',
        tag: 'docking',
        route: 'dock-leveler',
        contents: [
            { title: 'Skylink', content: 'dock-leveler-skylink', pdffile: 'SKY LINK HDL-02_Dock leveler with Hinged Lip.pdf' },
            { title: 'Loading System', content: 'dock-leveler-loading-system', pdffile: 'Dock Equirement Loading system brochure.pdf' },
            { title: 'Fastlink', content: 'dock-leveler-fastlink', pdffile: 'Fastlink Dock leveler.pdf' }
        ]
    },
    {
        id: 2,
        img: '/images/services/icon1.png',
        bg: '/images/products/dock-shelter-1.png',
        title: 'Dock Shelter',
        description: 'The Dock Shelter provides protection from the weather, enhancing efficiency and safety during loading and unloading operations...',
        tag: 'docking',
        route: 'dock-shelter',
        contents: [
            { title: 'Skylink', content: 'dock-shelter-skylink', pdffile: 'SKY LINK Dock shelter Catalogue.pdf' },
            { title: 'Loading System', content: 'dock-shelter-loading-system', pdffile: 'LOADING SYSTEMS 21EN-407NG.pdf' }
        ],
        hide_title: false
    },
    {
        id: 3,
        img: '/images/services/icon1.png',
        bg: '/images/products/fire-door-1.png',
        title: 'Fire Door',
        description: 'The Fire Door enhances safety by preventing the spread of fire and smoke...',
        tag: 'industrial',
        route: 'fire-door',
        contents: [
            { title: 'Curtain', content: 'fire-door-curtain', pdffile: 'SKY LINK Fire curtain.pdf' },
            { title: 'Shutter EL60', content: 'fire-shutter-el60', pdffile: 'Fire shutter door Catalogue EI60.pdf' },
            { title: 'Shutter EL120', content: 'fire-shutter-el120', pdffile: 'Fire shutter door Catalogue EI120.pdf' }
        ],
        hide_title: false
    },
    {
        id: 4,
        img: '/images/services/icon1.png',
        bg: '/images/products/high-speed-door-1.png',
        title: 'High-Speed Door',
        description: 'Installing flexible high-speed doors ensures smooth internal traffic…',
        tag: 'industrial',
        route: 'high-speed-door',
        contents: [
            { title: 'Skylink Catalogue', content: 'skylink-high-speed-door-catalogue', pdffile: 'SKY LINK Aluminum High speed door Catalogue.pdf' },
            { title: 'Ditec SectorReset', content: 'ditect-sector-reset', pdffile: 'DYNACO_D-311 EN.pdf' },
            { title: 'Ditec SoftReset', content: 'ditect-soft-reset', pdffile: 'Ditec-SoftReset-TDS-EN-LowRes.pdf' },
            { title: 'Ditec SoftResetFood', content: 'ditect-soft-reset-food', pdffile: 'Ditec-SoftResetFood-TDS-EN-LowRes.pdf' },
            { title: 'Ditec Traffic CM', content: 'ditect-traffic-cm', pdffile: 'Ditec-TrafficCM-TDS-EN-LowRes.pdf' },
            { title: 'DYNACO D-311', content: 'dynaco-d311', pdffile: 'DYNACO_D-311 EN.pdf' },
            { title: 'DYNACO D-313 CLEANROOM', content: 'dynaco-d313-cleanroom', pdffile: 'DYNACO_D-313_CLEANROOM_EN.pdf' },
            { title: 'DYNACO D-313 EMERGENCY', content: 'dynaco-d313-emegency', pdffile: 'DYNACO_D-313_EMERGENCY.pdf' },
            { title: 'DYNACO D-501 COMPACT', content: 'dynaco-d501-compact', pdffile: 'DYNACO_D-501_COMPACT_EN.pdf' },
            { title: 'DYNACO D-501 POWER', content: 'dynaco-d501-power', pdffile: 'DYNACO_D-501_POWER_EN.pdf' },
            { title: 'DYNACO M2 COMPACT', content: 'dynaco-m2-compact', pdffile: 'DYNACO_M2_COMPACT_EN.pdf' },
            { title: 'DYNACO M2 FREEZER', content: 'dynaco-m2-freezer', pdffile: 'DYNACO_M2_FREEZER_EN.pdf' },
            { title: 'DYNACO M2 POWER', content: 'dynaco-m2-power', pdffile: 'DYNACO_M2_POWER_EN.pdf' },
        ],
        hide_title: true
    },
    {
        id: 5,
        img: '/images/services/icon1.png',
        bg: '/images/products/roller-shutter-1.png',
        title: 'Roller Shutter',
        description: 'The Roller Shutter provides safety and protection for doors and windows…',
        tag: 'industrial',
        route: 'roller-shutter',
        contents: [
            { title: 'Skylink Catalogue', content: 'roll-shutter-skylink-catalogue', pdffile: 'SKYLINK Aluminum roller shutter door Catalogue.pdf' },
            { title: 'Single Layer', content: 'roll-shutter-single-layer', pdffile: 'SKY LINK Roller shutter Catalogue (Single layer).pdf' },
            { title: 'Double Layer', content: 'roll-shutter-double-layer', pdffile: 'SKY LINK Roller shutter Catalogue (Double layer).pdf' }
        ],
        hide_title: false
    },
    {
        id: 6,
        img: '/images/services/icon1.png',
        bg: '/images/products/sectional-door-1.png',
        title: 'Industrial Sectional Door',
        description: 'The Industrial Sectional Door offers space efficiency and insulation for garages and industrial buildings...',
        tag: 'industrial',
        route: 'sectional-door',
        contents: [
            { title: 'Skylink Section Door', content: 'skylink-section-door', pdffile: 'Fastlink Section door.pdf' },
            { title: 'Industrial Sectional Door', content: 'industrial-section-door', pdffile: 'Loading System industrial sectional door brochures.pdf' },
            { title: 'Sectional Door 21EN-601N', content: 'section-door-21en', pdffile: 'SECTIONAL DOOR 21EN-601N.pdf' },
            { title: 'Fastlink Section Door', content: 'fastlink-section-door', pdffile: 'Fastlink Section door.pdf' },
        ],
        hide_title: true
    },
    {
        id: 7,
        img: '/images/services/icon1.png',
        bg: '/images/products/steel-door-1.png',
        title: 'Steel Door',
        description: 'The Steel Door offers safety and durability for commercial and industrial purposes…',
        tag: 'industrial',
        route: 'steel-door',
        contents: [
            { title: 'Skylink Steel Door', content: 'skylink-steel-door', pdffile: 'Sky Link Steel door brochures.pdf' },
        ],
        hide_title: true
    },
    {
        id: 8,
        img: '/images/services/icon1.png',
        bg: '/images/products/table-lift-1.png',
        title: 'Table Lift',
        description: 'The Table Lift allows flexible height adjustment according to the needs of any working condition and improves productivity...',
        tag: 'docking',
        route: 'table-lift',
        contents: [
            { title: 'Skylink Table Lift', content: 'skylink-table-lift', pdffile: 'SKY LINK Table lift Catalogue.pdf' },
        ],
        hide_title: true
    },
    {
        id: 9,
        img: '/images/services/icon1.png',
        bg: '/images/products/accessory-1.png',
        title: 'Accessory',
        description: 'Enhances functionality and customization for various equipment and systems...',
        tag: 'docking',
        route: 'accessory',
        contents: [
            { title: 'Dock Light', content: 'dock-light', pdffile: 'Catalog_Dock light.pdf' },
        ],
        hide_title: true
    },
]
