const FireDoorCurtain = () => {
    return (
        <>
            <h3>Sky Link Fire Curtain</h3>
            <p>The Sky Link fire curtain is a vital safety feature designed to prevent the spread of fire and smoke within buildings. It offers effective protection by compartmentalizing areas, ensuring safe evacuation routes, and limiting damage to property.</p>

            <h3>Features</h3>
            <p>- Designed for safety and reliability</p>
            <p>- Complies with international fire safety standards</p>
            <p>- Easy to install and maintain</p>
            <p>- Customizable to fit various building requirements</p>

            <h3>Technical Specifications</h3>
            <p>- Fire resistance rating: [Insert fire resistance rating]</p>
            <p>- Material: [Insert material]</p>
            <p>- Dimensions: Customizable to fit different spaces</p>
            <p>- Operation: Automatic deployment in case of fire</p>
            <p>- Control system: Integrated with building fire alarm system</p>

            <h3>Benefits</h3>
            <p>- Provides critical time for safe evacuation</p>
            <p>- Helps contain fire and smoke, preventing spread</p>
            <p>- Enhances overall fire safety of the building</p>
            <p>- Suitable for various types of buildings, including commercial, industrial, and residential</p>

            <h3>Contact Information</h3>
            <p><strong>Head Office:</strong> Lot C5, Dong Dua Area, Ha Cau Ward, Ha Dong District, Hanoi, Vietnam</p>
            <p><strong>Binh Duong Service Office:</strong> Vsip IIA IZ, Tan Uyen, Binh Duong province</p>
            <p><strong>Tel:</strong> 024 3350 4259</p>
            <p><strong>Email:</strong> info@skylink.com.vn</p>
            <p><strong>Website:</strong> <a href="http://www.skylink.com.vn">www.skylink.com.vn</a></p>

        </>
    )
}

const FireDoorEl60 = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This catalogue provides comprehensive information on EI60-rated fire shutter doors, including their features, specifications, installation guidelines, and technical details.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Fire Resistance Rating:</strong> EI60 (60 minutes of fire resistance)</p>
            <p><strong>Material:</strong> Galvanized Steel</p>
            <p><strong>Operation:</strong> Manual and Automatic Options</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Powder-coated for corrosion resistance</p>
            <p><strong>Standard Color:</strong> Grey (other colors available upon request)</p>
            <p><strong>Panel Thickness:</strong> 1.2 mm</p>
            <p><strong>Weight:</strong> 25 kg per square meter</p>
            <p><strong>Operating Temperature Range:</strong> -20°C to 50°C</p>

            <h3>3. Technical Information</h3>
            <p><strong>Motor Specifications (for automatic option):</strong></p>
            <p>- <strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p>- <strong>Current:</strong> 0.5A</p>
            <p>- <strong>Power Consumption:</strong> 100W</p>
            <p>- <strong>Backup Battery:</strong> 24V DC, 7Ah</p>
            <p><strong>Control System:</strong> PLC-based control panel with emergency override</p>
            <p><strong>Power Supply:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Emergency Operation:</strong> Manual override in case of power failure</p>
            <p><strong>Activation Mechanism:</strong> Smoke detectors, heat detectors, and manual activation switches</p>
            <p><strong>Closing Speed:</strong> 0.15 m/sec</p>
            <p><strong>Noise Level:</strong> ≤ 65 dB</p>

            <h3>4. Features</h3>
            <p><strong>High Fire Resistance:</strong> Rated EI60 for excellent fire protection.</p>
            <p><strong>Robust Construction:</strong> Made from high-quality galvanized steel for durability.</p>
            <p><strong>Operational Flexibility:</strong> Available in both manual and automatic modes.</p>
            <p><strong>Customizable Sizes:</strong> Can be tailored to fit different architectural requirements.</p>
            <p><strong>Ease of Installation and Maintenance:</strong> Designed for straightforward installation and regular upkeep.</p>
            <p><strong>Smooth Operation:</strong> Equipped with advanced motor and control system for reliable performance.</p>
            <p><strong>Corrosion Resistance:</strong> Powder-coated finish enhances durability and longevity.</p>
            <p><strong>Quiet Operation:</strong> Designed to operate with minimal noise.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the shutter door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the shutter door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the shutter door panels and ensure they are aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the manual or automatic operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the shutter door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is required to ensure the fire shutter door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door panels, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Battery Maintenance:</strong> Check the backup battery and replace if necessary.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const FireDoorEl120 = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This catalogue provides comprehensive information on EI120-rated fire shutter doors, including their features, specifications, installation guidelines, and technical details.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Fire Resistance Rating:</strong> EI120 (120 minutes of fire resistance)</p>
            <p><strong>Material:</strong> Galvanized Steel</p>
            <p><strong>Operation:</strong> Manual and Automatic Options</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Powder-coated for corrosion resistance</p>
            <p><strong>Standard Color:</strong> Grey (other colors available upon request)</p>
            <p><strong>Panel Thickness:</strong> 1.5 mm</p>
            <p><strong>Weight:</strong> 30 kg per square meter</p>
            <p><strong>Operating Temperature Range:</strong> -20°C to 60°C</p>

            <h3>3. Technical Information</h3>
            <p><strong>Motor Specifications (for automatic option):</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Current:</strong> 0.8A</p>
            <p><strong>Power Consumption:</strong> 120W</p>
            <p><strong>Backup Battery:</strong> 24V DC, 10Ah</p>
            <p><strong>Control System:</strong> PLC-based control panel with emergency override</p>
            <p><strong>Power Supply:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Emergency Operation:</strong> Manual override in case of power failure</p>
            <p><strong>Activation Mechanism:</strong> Smoke detectors, heat detectors, and manual activation switches</p>
            <p><strong>Closing Speed:</strong> 0.20 m/sec</p>
            <p><strong>Noise Level:</strong> ≤ 70 dB</p>

            <h3>4. Features</h3>
            <p><strong>High Fire Resistance:</strong> Rated EI120 for excellent fire protection.</p>
            <p><strong>Robust Construction:</strong> Made from high-quality galvanized steel for durability.</p>
            <p><strong>Operational Flexibility:</strong> Available in both manual and automatic modes.</p>
            <p><strong>Customizable Sizes:</strong> Can be tailored to fit different architectural requirements.</p>
            <p><strong>Ease of Installation and Maintenance:</strong> Designed for straightforward installation and regular upkeep.</p>
            <p><strong>Smooth Operation:</strong> Equipped with advanced motor and control system for reliable performance.</p>
            <p><strong>Corrosion Resistance:</strong> Powder-coated finish enhances durability and longevity.</p>
            <p><strong>Quiet Operation:</strong> Designed to operate with minimal noise.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the shutter door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the shutter door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the shutter door panels and ensure they are aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the manual or automatic operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the shutter door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is required to ensure the fire shutter door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door panels, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Battery Maintenance:</strong> Check the backup battery and replace if necessary.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

export { FireDoorCurtain, FireDoorEl60, FireDoorEl120 }