"use client";

import React, { useMemo, useState } from 'react';
import PageHeader from '../Components/common/PageHeader';
import CommunicationCard from '../Components/ContactElements/CommunicationCard';
import Map from '../Components/ContactElements/Map';
import Banner from '../Containers/Banner';
import { PHONE_NUMBER } from '../common';
import PageHeaderSkylink from '../Components/common/PageHeaderSkylink';
// import { sendEmail } from '../common/sendMail';
import toast from "react-hot-toast";
import useDocumentTitle from '../Components/useDocumentTitle';
import { sendEmail } from '../action/sendMail';
import useCurrentLanguage from '../Components/userCurrentLanguage';
import { Trans } from 'react-i18next';
// import { sendEmail2 } from '../action/sendMail2';

const ContactPage = () => {

    useDocumentTitle("contact_title")

    const lang = useCurrentLanguage()
    const text_form = useMemo(() => {
        if (lang == "vi") return {
            name: "Tên*",
            mail: "Địa chỉ Email*",
            phone: "Số điện thoại",
            title: "Tiêu đề",
            content: "Nội dung*",
            name_empty: "Tên không được để trống",
            email_empty: "Địa chỉ Email không được để trống",
            email_invalid: "Địa chỉ Email không hợp lệ",
            content_empty: "Nội dung không được để trống",
            email_success: "Email đã được gửi thành công!",
            send_now: "Gửi ngay",
            sending: "Đang gủi ..."
        }

        return {
            "name": "Name*",
            "mail": "Email Address*",
            "phone": "Phone Number",
            "title": "Title",
            "content": "Content*",
            "name_empty": "Name cannot be empty",
            "email_empty": "Email Address cannot be empty",
            "email_invalid": "Invalid Email Address",
            "content_empty": "Content cannot be empty",
            "email_success": "Email sent successfully!",
            "send_now": "Send Now",
            "sending": "Sending ..."
        }
    }, [lang])

    const ContactData = [
        {
            id: '1',
            iconClass: 'ion-ios-telephone',
            // infoBody01: 'Phone No',
            infoBody01: 'Điện thoại',
            infoBody02: '(+84) 932.318.582 (Mr. John)',
            infoBody03: '(+84) 904.568.536 (Mr. Thang)',
        },
        {
            id: '2',
            iconClass: 'fa fa-envelope-o',
            // infoBody01: 'Email Address',
            infoBody01: 'Email',
            infoBody02: 'info@skylink.com.vn',
            // infoBody03: 'care@skylink.com',
        },
        {
            id: '3',
            iconClass: 'fa fa-map-marker',
            // infoBody01: 'Office Address',
            infoBody01: 'Địa chỉ',
            infoBody02: 'Lô C5, Khu Đồng Dưa, Phường Hà Cầu, Quận Hà Đông, TP Hà Nội.',
            infoBodyClass: 'd-none',
        },
    ];


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const [isSending, setLoading] = useState(false)
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        // Basic validation logic
        if (!formData.name) newErrors.name = text_form.name_empty;
        if (!formData.email) newErrors.email = text_form.email_empty;
        if (!formData.message) newErrors.message = text_form.content_empty;

        // Additional validation for email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            newErrors.email = text_form.email_invalid;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const sendMessage = async (formData) => {
        formData.preventDefault(); // Prevent default form submission

        if (validateForm()) {
            const senderEmail = formData.target["email"].value;
            const senderName = formData.target["name"].value;
            const message = formData.target["message"].value;
            const subject = formData.target["subject"].value;
            const phoneNumber = formData.target["phone"].value;

            try {
                setLoading(true)
                await sendEmail(senderEmail, senderName, subject, message, phoneNumber)
                setLoading(false)
            } catch (error) {

            }
            setLoading(false)
            toast.success(text_form.email_success);
            // alert('Đã gửi mail thành công!')
            formData.target.reset();
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            })
            return
        }
    };


    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/contact',
                        routeName: 'lien_he'
                    }
                ]}
                pageName='lien_he'
            />

            <div className="main_wrapper">

                <div className="contact_inner">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-4 col-md-5">
                                <div className="keepintouch_3">
                                    {
                                        ContactData.map(data => <CommunicationCard
                                            key={data.id}
                                            data={data}
                                        />)
                                    }
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12">
                                <div className="contact_us_1">
                                    {/* <div className="section_header">
                                        <h6 className="section_title">Contact Us</h6>
                                        <h1 className="section_title">Want to Ask anything?<br />
                                            Send Us a Mail Anytime</h1>
                                    </div> */}
                                    <div className="section_header" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        {/* <h6 className="section_title" style={{ textAlign: 'center' }}>Liên hệ</h6> */}
                                        <img src="/images/logo-skylink-white.png" alt="logo" style={{ padding: 10, height: 150 }} />
                                        <h1 className="section_title" style={{ textAlign: 'center' }}><Trans>{"send_us"}</Trans></h1>
                                        <p style={{ textAlign: 'center', color: 'white', fontSize: 18 }}><Trans>{"contact_us"}</Trans></p>
                                    </div>

                                    <form className="contact_form" onSubmit={sendMessage}>
                                        <div className="form-container">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text"
                                                            name="name"
                                                            className={`form-control ${errors.name ? 'error' : ''}`}
                                                            placeholder={errors.name || text_form.name}
                                                            required=""
                                                            onChange={handleChange}
                                                        />
                                                        {/* {errors.name && <div className="error-message">{errors.name}</div>} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="email" name="email"
                                                            className={`form-control ${errors.email ? 'error' : ''}`}
                                                            placeholder={errors.email || text_form.mail}
                                                            required="" onChange={handleChange} />
                                                        {/* {errors.email && <div className="error-message">{errors.email}</div>} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="phone" className="form-control" placeholder={text_form.phone} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="subject" className="form-control" placeholder={text_form.title} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <textarea name="message"
                                                            className={`form-control ${errors.message ? 'error' : ''}`}
                                                            placeholder={errors.message || text_form.content}
                                                            required="" onChange={handleChange}></textarea>
                                                        {/* {errors.message && <div className="error-message">{errors.message}</div>} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="form-group" style={{ justifyContent: 'center', display: 'flex' }}>
                                                        <button className="button" type="submit" value={text_form.send_now} disabled={isSending}
                                                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                            {isSending && <div className="loading-spinner" style={{ marginRight: '8px' }}></div>}
                                                            {isSending ? text_form.sending : text_form.send_now}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.591296850872!2d105.7668390111911!3d20.96892198058482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313453897470c66d%3A0x67b13b8a618aa3eb!2zQ8O0bmcgdHkgY-G7lSBwaOG6p24gU2t5IExpbms!5e0!3m2!1svi!2s!4v1720551597742!5m2!1svi!2s"></iframe>
                    </div>
                </div>
                {/* <Banner
                    title='Is Your House Secured Enough? Call Us to install Security Devices'
                    heading='Call Us Anytime'
                    phone={PHONE_NUMBER}
                    bannerType='banner banner_bg_color'
                /> */}
            </div>

        </>
    );
};

export default ContactPage;