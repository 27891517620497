import * as React from 'react';
import { Html, Body, Container, Section, Heading, Button, Text, Link } from "@react-email/components";

export function Email(props) {
  const { senderName, senderEmail, title, message, phoneNumber } = props;

  return (
    <Html lang="en">
      <Body style={styles.body}>
        <Container style={styles.container}>
          <Section style={styles.header}>
            <Heading style={styles.heading}>{title}</Heading>
          </Section>

          <Section style={styles.content}>
            <Text style={styles.text}>{message}</Text>
          </Section>

          <Section style={styles.senderInfo}>
            <Text style={styles.senderText}><strong>From:</strong> {senderName}</Text>
            <Text style={styles.senderText}><strong>Email:</strong> <Link href={`mailto:${senderEmail}`}>{senderEmail}</Link></Text>
            <Text style={styles.senderText}><strong>Phone:</strong> {phoneNumber}</Text>
          </Section>

          <Section style={styles.footer}>
            <Text style={styles.footerText}>
              If you have any questions, feel free to <Link href={`mailto:${senderEmail}`}>contact me</Link>.
            </Text>
            <Text style={styles.footerText}>
              Best regards,<br />{senderName}
            </Text>
          </Section>
        </Container>
      </Body>
    </Html>
  );
}

const styles = {
  body: {
    backgroundColor: "#f4f4f4",
    fontFamily: "Arial, sans-serif",
  },
  container: {
    backgroundColor: "#ffffff",
    margin: "0 auto",
    padding: "20px",
    maxWidth: "600px",
    borderRadius: "8px",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  heading: {
    color: "#333333",
    fontSize: "24px",
    fontWeight: "bold",
  },
  content: {
    padding: "20px",
    textAlign: "center",
  },
  text: {
    color: "#333333",
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "20px",
  },
  senderInfo: {
    paddingTop: "20px",
    borderTop: "1px solid #dddddd",
    marginTop: "20px",
  },
  senderText: {
    color: "#555555",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  footer: {
    marginTop: "20px",
    borderTop: "1px solid #dddddd",
    paddingTop: "10px",
    textAlign: "center",
  },
  footerText: {
    color: "#777777",
    fontSize: "14px",
  },
};

export default Email;
