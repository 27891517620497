import useCurrentLanguage from "../../../Components/userCurrentLanguage"

const YEP2023 = ({ openModal, closeModal, selectedImage }) => {

    const lang = useCurrentLanguage()

    if (lang == 'en') return (
        <>
            <h1>Company Year-End Party 2023</h1>
            <p></p>
            <p>On December 22, 2023, our company held a grand year-end party to mark the end of a year filled with effort and success. The party took place in a joyful and warm atmosphere, with the full participation of all company employees.</p>

            <h2>Entertainment Program</h2>
            <p>The party began with impressive performances by the company's employees. These performances not only brought laughter and joy but also showcased the unity and talent of the members. The program included singing, dancing, and fashion shows, each meticulously prepared and performed with enthusiasm.</p>
            <div className="image-container">
                <img src="/images/news/yep2023-4.JPG" alt="Entertainment Performance" onClick={() => openModal('/images/news/yep2023-4.JPG', 'Entertainment Performance')} />
            </div>
            <div class="image-caption">Colorful and lively entertainment performances.</div>

            <h2>Lucky Draw</h2>
            <p>The lucky draw was the most anticipated part of the party. With many attractive prizes such as phones, tablets, and shopping vouchers, everyone was excited and nervous waiting for the results. Laughter and applause filled the air as prizes were awarded to the lucky winners.</p>

            <div class="image-container">
                <img src="/images/news/yep2023-2.JPG" alt="Lucky Draw" onClick={() => openModal('/images/news/yep2023-2.JPG', 'Lucky Draw')} />
            </div>
            <div class="image-caption">Exciting moments during the lucky draw.</div>

            <h2>Recognition of Outstanding Individuals and Departments</h2>
            <p>Finally, the party concluded with the recognition of outstanding individuals and departments, acknowledging their significant contributions over the past year. This was an opportunity for the company to express gratitude for the efforts and dedication of the employees, while also encouraging the spirit of teamwork among everyone. Awards and certificates were presented in a formal and warm atmosphere.</p>
            <div class="image-container">
                <img src="/images/news/yep2023-3.JPG" alt="Outstanding Individuals Recognition" onClick={() => openModal('/images/news/yep2023-3.JPG', 'Outstanding Individuals Recognition')} />
                <img src="/images/news/yep2023-1.JPG" alt="Outstanding Individuals Recognition" onClick={() => openModal('/images/news/yep2023-1.JPG', 'Outstanding Individuals Recognition')} />
            </div>
            <div class="image-caption">Outstanding individuals and departments recognized.</div>
            <p>The year-end party 2023 was not only a chance to reflect on the past year but also an opportunity for everyone to connect, bond, and prepare for new challenges in the coming year. We hope that with the unity and efforts of all employees, the company will continue to achieve even greater successes in the future.</p>

            {selectedImage && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal" onClick={closeModal}>
                            &times;
                        </button>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                                width: '100%', maxHeight: 700,
                                objectFit: 'contain', objectPosition: 'center'
                            }} />
                    </div>
                </div>
            )}

            <p>The team-building activities organized during the trip helped members understand each other better, work more effectively, and overcome challenges together. The trip ended successfully, leaving many beautiful memories and promising future bonding opportunities.</p>
            <p>We, Skylink JSC, aim to provide more opportunities like this for all employees to interact, learn, and grow together.</p>
        </>
    )

    return (
        <>
            <h1>Tiệc Cuối Năm 2023 của Công Ty</h1>
            <p></p>
            <p>Ngày 22/12/2023, công ty chúng tôi đã tổ chức một buổi tiệc cuối năm hoành tráng để đánh dấu sự kết thúc của một năm làm việc đầy nỗ lực và thành công. Buổi tiệc diễn ra trong không khí vui tươi và ấm cúng, với sự tham gia đông đủ của toàn thể nhân viên công ty.</p>

            <h2>Chương Trình Văn Nghệ</h2>
            <p>Buổi tiệc bắt đầu với các tiết mục văn nghệ đặc sắc do chính các nhân viên trong công ty biểu diễn. Những tiết mục này không chỉ mang lại tiếng cười và niềm vui, mà còn thể hiện sự đoàn kết và tài năng của các thành viên. Các tiết mục bao gồm hát, múa, và trình diễn thời trang, mỗi tiết mục đều được chuẩn bị kỹ lưỡng và biểu diễn đầy nhiệt huyết.</p>
            <div className="image-container">
                <img src="/images/news/yep2023-4.JPG" alt="Tiết mục văn nghệ" onClick={() => openModal('/images/news/yep2023-4.JPG', 'Tiết mục văn nghệ')} />
            </div>
            <div class="image-caption">Tiết mục văn nghệ đầy màu sắc và sôi động.</div>

            <h2>Bốc Thăm Trúng Thưởng</h2>
            <p>Phần bốc thăm trúng thưởng luôn là phần được mong chờ nhất trong buổi tiệc. Với nhiều phần quà hấp dẫn như điện thoại, máy tính bảng, và các phiếu mua sắm, mọi người đều hào hứng và hồi hộp chờ đợi kết quả. Tiếng cười và những tràng pháo tay không ngớt khi các phần quà được trao đến tay những người may mắn.</p>

            <div class="image-container">
                <img src="/images/news/yep2023-2.JPG" alt="Bốc thăm trúng thưởng" onClick={() => openModal('/images/news/yep2023-2.JPG', 'Bốc thăm trúng thưởng')} />
            </div>
            <div class="image-caption">Những khoảnh khắc hồi hộp trong phần bốc thăm trúng thưởng.</div>

            <h2>Tôn Vinh Cá Nhân và Phòng Ban Xuất Sắc</h2>
            <p>Cuối cùng, buổi tiệc kết thúc với phần tôn vinh các cá nhân và phòng ban xuất sắc, ghi nhận những đóng góp quan trọng của họ trong năm qua. Đây là dịp để công ty tri ân những nỗ lực và cống hiến của nhân viên, đồng thời khích lệ tinh thần làm việc của tất cả mọi người. Những phần quà và giấy khen được trao tặng trong không khí trang trọng và ấm áp.</p>
            <div class="image-container">
                <img src="/images/news/yep2023-3.JPG" alt="Tôn vinh cá nhân xuất sắc" onClick={() => openModal('/images/news/yep2023-3.JPG', 'Tôn vinh cá nhân xuất sắc')} />
                <img src="/images/news/yep2023-1.JPG" alt="Tôn vinh cá nhân xuất sắc" onClick={() => openModal('/images/news/yep2023-1.JPG', 'Tôn vinh cá nhân xuất sắc')} />
            </div>
            <div class="image-caption">Những cá nhân và phòng ban xuất sắc được vinh danh.</div>
            <p>Buổi tiệc cuối năm 2023 không chỉ là dịp để tất cả cùng nhau nhìn lại một năm đã qua, mà còn là cơ hội để mọi người giao lưu, gắn kết và chuẩn bị tinh thần cho những thử thách mới trong năm tới. Chúng tôi hy vọng rằng, với sự đoàn kết và nỗ lực của toàn thể nhân viên, công ty sẽ tiếp tục đạt được những thành công lớn hơn nữa trong tương lai.</p>

            {selectedImage && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal" onClick={closeModal}>
                            &times;
                        </button>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                                width: '100%', maxHeight: 700,
                                objectFit: 'contain', objectPosition: 'center'
                            }} />
                    </div>
                </div>
            )}

            <p>Những hoạt động team building được tổ chức trong chuyến đi đã giúp các thành viên hiểu nhau hơn, làm việc hiệu quả hơn và cùng nhau vượt qua các thử thách. Chuyến du lịch đã kết thúc thành công tốt đẹp, để lại nhiều kỷ niệm đẹp và hứa hẹn những dịp gắn kết tiếp theo.</p>
            <p>Chúng tôi, Skylink JSC, mong muốn mang đến nhiều cơ hội như thế này để toàn thể nhân viên có thể giao lưu, học hỏi và cùng phát triển.</p>

        </>
    )
}

export default YEP2023