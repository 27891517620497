import React, { useMemo, useState } from 'react';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import { PHONE_NUMBER } from '../../common';
import TestimonialSkylink from '../../Containers/Testimonials/TestimonialSkylink';
import ImageGallery from '../../Containers/ProjectContainers/ImageGallery';

import SwiperCore, { Controller, Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useDocumentTitle from '../../Components/useDocumentTitle';
import useCurrentLanguage from '../../Components/userCurrentLanguage';
import { Trans } from 'react-i18next';

const Customer2 = () => {

    useDocumentTitle("partner_title")
    SwiperCore.use([Autoplay])

    const lang = useCurrentLanguage()

    const customers = [
        {
            id: 0,
            group: "partner",
            folder: 'core',
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        {
            id: 1,
            group: "in_rbf",
            folder: 'RBF_RBW_BTS',
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },
        {
            id: 2,
            group: "food_factory",
            folder: 'food',
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        },
        {
            id: 3,
            group: "pharma_factory",
            folder: 'pharma',
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        },
        {
            id: 4,
            group: "package_factory",
            folder: 'other',
            items: [1, 2, 3, 4, 5]
        },
        {
            id: 5,
            group: "other",
            folder: 'other',
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
        }
    ]

    const [active, setActive] = useState(0)
    const customer = useMemo(() => { return customers[active] }, [active])
    const images = useMemo(() => {
        return customer.items.map(it => `/images/partner/${customer.folder}/${it}.png`)
    }, [customer])

    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/doi-tac',
                        routeName: 'partner_customer'
                    }
                ]}
                pageName={'partner_customer'}
            />
            <div className="main_wrapper">
                <div className="container">
                    <div className="project_details section">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="project_details_inner">
                                    <div className="post_content">

                                        {
                                            lang == 'vi' ?
                                                <>
                                                    <p>
                                                        Trong suốt quá trình thi công dự án trên khắp cả nước, <strong>Skylink</strong> đã gặt hái được những thành tựu nhất định. Trong đó, niềm tự hào lớn nhất chính là sự đánh giá cao từ Chủ đầu tư khi đón nhận những công trình được thi công với chất lượng vượt trội, đúng tiến độ cam kết và mang lại những lợi ích lâu dài về mặt tài chính và khai thác thương mại. <strong>Skylink</strong> tự hào vì đã, đang và sẽ liên tục đồng hành cùng nhiều khách hàng lớn và đối tác uy tín.
                                                    </p>
                                                    <strong>
                                                        Họ yên tâm khi đồng hành cùng chúng tôi.
                                                    </strong>
                                                </>
                                                : <>
                                                    <p>
                                                        Throughout the project execution process across the country, <strong>Skylink</strong> has achieved significant milestones. The greatest pride is the high appreciation from investors when receiving projects constructed with superior quality, meeting committed timelines, and delivering long-term financial and commercial exploitation benefits. <strong>Skylink</strong> is proud to have, is currently, and will continue to accompany many major clients and reputable partners.
                                                    </p>
                                                    <strong>
                                                        They feel secure when partnering with us.
                                                    </strong>

                                                </>
                                        }
                                        {
                                            customers.map(it => (
                                                <div key={it.folder} style={{ marginBottom: 100 }}>
                                                    <div className="post_header">
                                                        <h3 className="post_title"><Trans>{it.group}</Trans></h3>
                                                    </div>

                                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                                        <div className={`${it.folder}-custom-swiper-button-prev`}>
                                                            <p style={{ fontSize: 100, cursor: "pointer" }} className='my-hover'>‹</p>
                                                        </div>
                                                        <Swiper
                                                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                                                            className="owl_testimonial1 owl-carousel owl-theme"
                                                            modules={[Pagination, Controller, Navigation]}
                                                            effect={"slide"}
                                                            breakpoints={{
                                                                320: {
                                                                    width: 320,
                                                                    slidesPerView: 1,
                                                                },
                                                                434: {
                                                                    width: 384,
                                                                    slidesPerView: 2,
                                                                },
                                                            }}
                                                            loop={false}
                                                            controller={{ inverse: true }}
                                                            spaceBetween={50}
                                                            // centeredSlides={true}
                                                            pagination={{
                                                                el: `.${it.folder}-pagination`,
                                                                clickable: true,
                                                                // dynamicBullets: true,
                                                            }}
                                                            navigation={{ // Add navigation buttons
                                                                enabled: true,
                                                                prevEl: `.${it.folder}-custom-swiper-button-prev`,
                                                                nextEl: `.${it.folder}-custom-swiper-button-next`
                                                            }}
                                                        >
                                                            {
                                                                it.items.map(image =>
                                                                    <SwiperSlide>
                                                                        <img
                                                                            src={`/images/partner/${it.folder}/${image}.png`}
                                                                            key={`/images/partner/${it.folder}/${image}.png`}
                                                                            style={{
                                                                                height: 130, borderWidth: 1, borderColor: 'gray', borderStyle: 'solid',
                                                                                padding: it.id == 0 ? 15 : 0
                                                                            }} />
                                                                    </SwiperSlide>)
                                                            }
                                                        </Swiper>


                                                        <div className={`${it.folder}-custom-swiper-button-next`}>
                                                            <p style={{ fontSize: 100, cursor: "pointer" }} className='my-hover'>›</p>
                                                        </div>
                                                        {/* <div className={`${it.folder}-pagination`}></div> */}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <TestimonialSkylink pb='pd_btom_80 pd_top_80' />
            </div>
        </>
    );
};

export default Customer2;