import Email from "./Email";
import ReactDOMServer from 'react-dom/server';
import axios from "axios";

export const sendEmail = async (senderEmail, senderName, subject, message, phoneNumber) => {

    const emailHtml = ReactDOMServer.renderToString(
        <Email
            senderName={senderName}
            senderEmail={senderEmail}
            title={subject}
            message={message}
            phoneNumber={phoneNumber}
        />
    );

    const res = await axios.post('https://sendmail.hieutm9x.com/api/send-email', {
        to: 'hieutm9x@gmail.com',
        from: senderEmail,
        subject: subject,
        html: emailHtml
    }, { timeout: 15000 })

    console.log(res)
}