import React from 'react';
import SwiperCore, { Controller, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Isotope from 'isotope-layout';
import ProjectCard02 from '../../Components/ProjectElements/ProjectCard02';
import { projects } from '../../common/projects';
import { Trans } from 'react-i18next';


function getRandomElements(arr, num) {
    // Shuffle the array using the Fisher-Yates (Knuth) shuffle algorithm
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    // Return the first `num` elements of the shuffled array
    return arr.slice(0, num);
}

const ProjectHomeSkylink = (props) => {
    SwiperCore.use([Autoplay])
    
    const ProjectHomeData01 = getRandomElements(projects, 10)

    return (
        <div className={`testimonial ${props.pb} `}>
            <div className="container">
                <div className="section_header text-center">
                    <h1 className="section_title"><Trans>{"notable_projects"}</Trans></h1>
                </div>
                <div className="row">
                    <div className="col-12" >
                        <Swiper
                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                            className="owl_testimonial1 owl-carousel owl-theme"
                            modules={[Pagination, Controller]}
                            effect={"slide"}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                            }}
                            loop={false}
                            controller={{ inverse: true }}
                            spaceBetween={50}
                            // centeredSlides={true}
                            pagination={{
                                el: '.project-pagination',
                                clickable: true,
                                // dynamicBullets: true,
                            }}
                        >
                            {
                                ProjectHomeData01.map(data =>
                                    <SwiperSlide>
                                        <ProjectCard02
                                            key={data.id}
                                            data={data}
                                        />
                                    </SwiperSlide>)
                            }
                        </Swiper>

                        {/* <!-- Add Pagination --> */}
                        <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', width: '20%', marginLeft: '41.5%', marginTop: 30 }}>
                            <div className="project-pagination"></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectHomeSkylink;