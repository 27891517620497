// src/components/ImageGallery.js
import React, { useState } from 'react';
import './ImageGallery.css';

// const images = [
//   '/images/image1.jpg',
//   '/images/image2.jpg',
//   '/images/image3.jpg',
//   // Add more image URLs
// ];

const ImageGallery = ({ images, containerStyle, disablePress }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-gallery" style={containerStyle}>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-item" onClick={disablePress ? () => { } : () => openModal(image)}>
            <img src={image} alt={`Gallery ${index}`} style={{ borderRadius: 5 }} />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                width: '100%', maxHeight: 700,
                objectFit: 'contain', objectPosition: 'center'
              }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;