import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import ProductCard from '../../Components/ServiceCards/ProductCard';
import { products, products_en } from '../../common/product';
import useDocumentTitle from '../../Components/useDocumentTitle';
import { Trans } from 'react-i18next';
import useCurrentLanguage from '../../Components/userCurrentLanguage';

function getName(path) {
    if (path == 'docking-system') return 'Docking System'
    if (path == 'industrial-door') return 'Industrial Door'
    if (path == 'special-product') return 'Special Products'
    return 'san_pham'
}

function getTitle(path) {
    if (path == 'docking-system') return 'docking_system_title'
    if (path == 'industrial-door') return 'industrial_door_title'
    if (path == 'special-product') return 'special_product_title'
    return 'san_pham'
}

function getFilterKey(path) {
    if (path == 'docking-system') return 'docking'
    if (path == 'industrial-door') return 'industrial'
    if (path == 'special-product') return 'special'
    return '*'
}

const Products = () => {

    const location = useLocation();
    const pathname = location.pathname.split("/")[2]

    useDocumentTitle(pathname ? getTitle(pathname) : 'product_title')

    const lang = useCurrentLanguage()

    const [filterKey, setFilterKey] = useState(pathname ? getFilterKey(pathname) : '*')
    const [name, setName] = useState('all')

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.element-item',
            percentPosition: true,
            // layoutMode: 'packery',
            // masonry: {
            //     columnWidth: '.grid-sizer'
            // },
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            // columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy()
    }, []);

    // handling filter key change
    useEffect(() => {
        filterKey === '*'
            ? Isotope.current.arrange({ filter: `*` })
            : Isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey]);

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setTimeout(() =>
            filterKey === '*'
                ? Isotope.current.arrange({ filter: `*` })
                : Isotope.current.arrange({ filter: `.${filterKey}` })
            , 100)
    }, [])

    const handleFilterKeyChange = (key, name) => () => {
        setFilterKey(key)
        setName(name)
    };

    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/product',
                        routeName: 'san_pham'
                    }
                ]}
                pageName={pathname ? getName(pathname) : 'san_pham'}
            />
            {/* <ServiceContainer02 /> */}
            <div className="main_wrapper">
                <div className="project_iso project_iso1">
                    <div className="container">
                        {
                            !pathname &&
                            <p className="highlight" style={{ marginTop: 50, marginBottom: 50 }}>
                                <Trans>{"products"}</Trans> - <Trans>{name}</Trans>
                            </p>
                        }
                        <div className="row">
                            <div className="col">
                                <div className="button-group filters-button-group">
                                    {!pathname &&
                                        <>
                                            <button className="button" onClick={handleFilterKeyChange('*', 'all')}><Trans>{"all"}</Trans> <sup className="filter-count">9</sup></button>
                                            <button className="button" onClick={handleFilterKeyChange('docking', 'docking_system')}><Trans>{"docking_system"}</Trans> <sup className="filter-count">4</sup></button>
                                            <button className="button" onClick={handleFilterKeyChange('industrial', 'industrial_door')}><Trans>{"industrial_door"}</Trans> <sup className="filter-count">5</sup></button>
                                            <button className="button" onClick={handleFilterKeyChange('special', 'special_product')}><Trans>{"special_product"}</Trans> <sup className="filter-count">0</sup></button>
                                        </>}
                                </div>

                                <div className="grid grid-3 gutter-15">
                                    {
                                        (lang == 'vi' ? products : products_en).map(data => <ProductCard
                                            key={data.id}
                                            data={data}
                                        />)
                                    }
                                </div>

                            </div>

                            {/* <PaginationDiv /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;