export const TableLiftSkylink = () => {
    return (
        <>
            <h3>Scissor Lift Table</h3>
            <p>Hydraulic lifting actuation</p>

            <h3>Features</h3>
            <ul>
                <li>Anti-Explosion safety valves</li>
                <li>Aluminum safety bar</li>
                <li>Anti-skid platform</li>
                <li>Emergency valves</li>
                <li>Number of floor layers indicator</li>
                <li>Buzzer for overload</li>
            </ul>

            <h3>Technical Specifications</h3>
            <p>Platform Length: 1800/2000/2500/3000 mm (Other dimensions upon request)</p>
            <p>Platform Width: 1800/2000/2500 mm (Other dimensions upon request)</p>
            <p>Min Height: 300/400/500 mm (Other dimensions upon request)</p>
            <p>Lifting Stroke: 1600/1800/2000 to 5000 mm (Other dimensions upon request)</p>
            <p>Max Height: 1600/1800/2000 to 5000 mm (Other dimensions upon request)</p>
            <p>Capacity: 1000/2000/3000/4000 kg</p>
            <p>Platform Thickness: 6mm diamond-shape steel plate</p>
            <p>Control Box: With up, down, stop button</p>
            <p>Power Pack: 1.5KW, 2.2KW, 3KW...380V 50HZ</p>
            <p>Color: Green/Black/Blue/Grey</p>
            <p>Handrail: Nil, 2 sides, 3 sides</p>

            <h3>After-Sales Service</h3>
            <p>All SKYLINK products have repair and maintenance spare parts stocked in Binh Duong Province and Hanoi City. Our standard is to provide after-sales service within 8 working hours.</p>

            <h3>Locations</h3>
            <p>THAI NGUYEN WAREHOUSE: After sale warehouse and north part service center</p>
            <p>BINH DUONG SERVICE OFFICE: Warehouse and service center for the south part</p>
        </>
    )
}