import React, { useEffect } from 'react';

const ThemeSwitcher = () => {
    useEffect(() => {
        // Day-Night Mode Switcher
        var icon = document.getElementById("mode_switcher");
        let localData = localStorage.getItem("theme");

        if (localData === "light") {
            icon.innerHTML = '<span><i class="ion-ios-sunny"></i></span>';
            document.body.classList.remove("dark-theme");
            document.body.classList.add("white-theme");
        } else if (localData === "dark") {
            icon.innerHTML = '<span><i class="ion-ios-moon"></i></span>';
            document.body.classList.remove("white-theme");
            document.body.classList.add("dark-theme");
        }

        if (!localData) {
            localStorage.setItem("theme", "dark");
        }

        icon.onclick = function () {
            document.body.classList.toggle("dark-theme");
            if (document.body.classList.contains("dark-theme")) {
                icon.innerHTML = '<span><i class="ion-ios-moon"></i></span>';
                localStorage.setItem("theme", "dark");
            } else {
                icon.innerHTML = '<span><i class="ion-ios-sunny"></i></span>';
                localStorage.setItem("theme", "light");
            }
        }
    }, []);
    return (
        <div id="mode_switcher">
            <span><i className="ion-ios-moon"></i></span>
        </div>
    );
};

export default ThemeSwitcher;