import React from 'react';
import ProjectDetailsContainer from '../../Containers/ProjectContainers/ProjectDetailsContainer';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import { useParams } from 'react-router-dom';
import { projects } from '../../common/projects';
import useDocumentTitle from '../../Components/useDocumentTitle';


const ProjectDetails = () => {

    const { route } = useParams();
    const project = projects.find(it => it.name == route)
    useDocumentTitle(project.name)
    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/du-an',
                        routeName: 'du_an'
                    }
                ]}
                pageName={project.name}
            />
            <ProjectDetailsContainer project={project}/>
        </>
    );
};

export default ProjectDetails;