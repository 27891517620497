import React from 'react';
import { Trans } from 'react-i18next';

const VisionDetail = ({ data }) => {
    const { title, descriptions, img } = data;

    return (
        <div className="about_below_content">
            <img src={img} alt="" />
            <div className="about_below_content_text">
                <h2><Trans>{title}</Trans></h2>
                {
                    descriptions.map(description => <p><span style={{fontWeight:'bold'}}><Trans>{description.highlight}</Trans></span> <Trans>{description.content}</Trans></p>)
                    // descriptions.map(description => <p><Trans>{description.content}</Trans></p>)
                }
            </div>
        </div>
    );
};

export default VisionDetail