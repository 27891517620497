import React from 'react';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import useDocumentTitle from '../../Components/useDocumentTitle';
import useCurrentLanguage from '../../Components/userCurrentLanguage';

const Introduction = () => {

    useDocumentTitle('overview_title')
    const lang = useCurrentLanguage()

    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/gioi-thieu',
                        routeName: 'gioi_thieu'
                    },
                    {
                        route: '/gioi-thieu-chung',
                        routeName: 'gioi_thieu_chung'
                    }
                ]}
                pageName='gioi_thieu_chung'
            />

            {
                lang == 'vi' ?
                    <Vi_html />
                    : <En_html />
            }

        </>
    );
};

const Vi_html = () => {
    return (
        <div className="main_wrapper section">
            <div className="container">
                <div className="blog_details">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 pe-4">
                            <div className="blog_details_inner">
                                <div className="post_content">
                                    <div className="post_header">
                                        {/* <div className="categ"><a >SKYLINK JSC</a></div> */}
                                        <h3 className="post_title">CÔNG TY CỔ PHẦN SKYLINK</h3>
                                    </div>
                                    <div className="fulltext">


                                        <p>
                                            Công ty cổ phần Sky Link được thành lập ngày 13/04/2010. Là doanh nghiệp hoạt động trong lĩnh vực cung cấp và lắp đặt hệ thống thiết bị nâng hạ hàng hóa và cửa công nghiệp.
                                            Với sự đóng góp và xây dựng trên nền tảng đội ngũ nhân viên là các kỹ sư được đào tạo bài bản từ các chuyên gia đến từ nước ngoài như Hà Lan, Bỉ, Anh,… có năng lực chuyên môn cao cùng với đội ngũ công nhân thi công tay nghề cao .
                                            Sky Link thành lập với mục tiêu đem đến cho khách hàng những trải nghiệm tốt nhất về chất lượng sản phẩm dịch vụ cũng như sự hài lòng về phong cách làm việc chuyên nghiệp, đội ngũ nhân viên chúng tôi luôn nỗ lực phấn đấu tiếp thu và ứng dụng khoa học kỹ thuật tiên tiến hiện đại nhằm tạo ra hệ thống vận hành ổn định, chất lượng phục vụ cho các công trình.
                                        </p>

                                        <p className="highlight">
                                            Tên công ty : CÔNG TY CỔ PHẦN SKYLINK
                                        </p>
                                        <p className="highlight">
                                            Viết tắt : SKYLINK,.JSC
                                        </p>
                                        <p className="highlight">
                                            Logo :
                                        </p>
                                        <div className="post_gallery">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="anim_box">
                                                        <img src="images/logo-skylink.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="highlight">
                                            Đăng ký kinh doanh lần đầu ngày 13 tháng 4 năm 2010.
                                        </p>
                                        <p className="highlight">
                                            Địa chỉ theo đăng ký kinh doanh : Lô C5, Khu Đồng Dưa, Phường Hà Cầu, Quận Hà Đông, Hà Nội, Việt Nam
                                        </p>
                                        <p className="highlight">
                                            Mã số thuế : 0104585730
                                        </p>
                                        <p className="highlight">
                                            Loại hình : Công ty cổ phần
                                        </p>
                                        <p className="highlight">
                                            Vốn điều lệ : 35.000.000.000 VND
                                        </p>
                                        <p className="highlight">
                                            Ngành nghề kinh doanh chính :
                                        </p>
                                        <ul className="point_order">
                                            <li> Cung cấp và lắp đặt hệ thống cửa cho nhà ở và công nghiệp</li>
                                            <li> Xây lắp hệ thống cơ điện cho nhà ở và công nghiệp.</li>
                                            <li> Lắp đặt máy công nghiệp và dây truyền máy</li>
                                            <li> Các sản phẩn máy móc, thiết bị cơ điện khác.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="border_line"></div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

const En_html = () => {
    return (
        <div className="main_wrapper section">
            <div className="container">
                <div className="blog_details">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 pe-4">
                            <div className="blog_details_inner">
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">SKYLINK JOINT STOCK COMPANY</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p>
                                            Skylink Joint Stock Company was established on April 13, 2010. It is an enterprise operating in the field of supplying and installing goods handling and industrial door systems.
                                            With contributions and construction based on a team of employees who are engineers trained by experts from abroad such as the Netherlands, Belgium, the UK, etc., with high professional competence and a skilled workforce.
                                            Sky Link was established with the goal of providing customers with the best experience in product and service quality, as well as satisfaction with our professional working style. Our team constantly strives to learn and apply modern advanced science and technology to create stable operating systems and high-quality services for projects.
                                        </p>

                                        <p className="highlight">
                                            Company Name: SKYLINK JOINT STOCK COMPANY
                                        </p>
                                        <p className="highlight">
                                            Abbreviation: SKYLINK,.JSC
                                        </p>
                                        <p className="highlight">
                                            Logo:
                                        </p>
                                        <div className="post_gallery">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="anim_box">
                                                        <img src="images/logo-skylink.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="highlight">
                                            First business registration on April 13, 2010.
                                        </p>
                                        <p className="highlight">
                                            Registered business address: Lot C5, Dong Dua Area, Ha Cau Ward, Ha Dong District, Hanoi, Vietnam
                                        </p>
                                        <p className="highlight">
                                            Tax code: 0104585730
                                        </p>
                                        <p className="highlight">
                                            Type of business: Joint Stock Company
                                        </p>
                                        <p className="highlight">
                                            Charter capital: 35,000,000,000 VND
                                        </p>
                                        <p className="highlight">
                                            Main business activities:
                                        </p>
                                        <ul className="point_order">
                                            <li> Supply and installation of residential and industrial door systems</li>
                                            <li> Installation of electrical and mechanical systems for residential and industrial buildings</li>
                                            <li> Installation of industrial machinery and conveyor systems</li>
                                            <li> Other mechanical and electrical machinery and equipment</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="border_line"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;