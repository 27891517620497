export const projects = [
    {
      "name": "BWID Dong An 2 Logistic Park",
      "location": "Dong An IP, Binh Duong Province",
      "client": "Golden Lotus",
      "tags": [
        "Logistic"
      ],
      "start_time": "6/2024",
      "end_time": null,
      "cate": "Dock leveler, Sectional door, Roller shutter, Fire roller shutter",
      "imgs": [
        "BWDA2.jpg"
      ],
      "id": 1
    },
    {
      "name": "BWID Nam Dinh Vu CN3-03",
      "location": "Nam Dinh Vu IP, Hai Phong City",
      "client": "Kansai Vina",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door Packages",
      "imgs": [
        "BWNDV.jpg"
      ],
      "id": 2
    },
    {
      "name": "BWID NAM HAI CN10-1",
      "location": "Nam Dinh Vu IP, Hai Phong City",
      "client": "Megaspace Viet Nam",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door & Dock Leveler Packages",
      "imgs": [
        "BWNH.jpg"
      ],
      "id": 3
    },
    {
      "name": "BWID WINLOCK 2A",
      "location": "Vinh Loc 2 IP, Long Hiep Commune, Ben Luc District, Long An Province",
      "client": "Newtecons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door & Dock Leveler Packages",
      "imgs": [
        "BWWL2A.jpg"
      ],
      "id": 4
    },
    {
      "name": "READY-BUILT FACTORIES FOR RENT IN BW THUAN THANH 3B INDUSTRIAL",
      "location": "Thuan Thanh III IP, Gia Dong Ward, Thuan Thanh Town, Bac Ninh Province",
      "client": "VKC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door Packages",
      "imgs": [
        "BWTT3B.jpg"
      ],
      "id": 5
    },
    {
      "name": "BWID Bau Bang 06",
      "location": "C-7-CN, Bau Bang Expansion Industrial Park, Lai Uyen Town, Bau Bang District, Binh Duong Province",
      "client": "Vitecons & Golden Lotus",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door Packages",
      "imgs": [
        "BWBB06.jpg"
      ],
      "id": 6
    },
    {
      "name": "BWID Thuan Thanh",
      "location": null,
      "client": "VKC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door Packages",
      "imgs": [
        "BWTT3B.jpg"
      ],
      "id": 7
    },
    {
      "name": "BWID XENIA 1",
      "location": null,
      "client": "Hoa Binh",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door & Dock Leveler Packages",
      "imgs": [
        "BWXN1.jpg"
      ],
      "id": 8
    },
    {
      "name": "BWID BAC TIEN PHONG CN2.1C",
      "location": null,
      "client": "Vitecons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door Packages",
      "imgs": [
        "BWBTPCN21C.jpg"
      ],
      "id": 9
    },
    {
      "name": "BWID 20- TAN DONG HIEP",
      "location": null,
      "client": "Hoa Binh",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door & Dock Leveler Packages",
      "imgs": [
        "BW20TDH.jpg"
      ],
      "id": 10
    },
    {
      "name": "BWID Nhon Trach, Kim Truc",
      "location": null,
      "client": "Central",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Industrial Door & Dock Leveler Packages",
      "imgs": [
        "BWNT.jpg"
      ],
      "id": 11
    },
    {
      "name": "Bati Ha Noi logistic phase 1",
      "location": "\r\nVSIP BAC NINH, BAC NINH PROVINCE, VIETNAM",
      "client": "NG Concept, Tuan Le Construcsion",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock, leveler, industrial door, steel door.",
      "imgs": [
        "BATIHN1.jpg"
      ],
      "id": 12
    },
    {
      "name": "LOGOS VSIP Bac Ninh 1 Logistics Park",
      "location": "VSIP Bac Ninh Phase 1, Bac Ninh Province",
      "client": "Newtecons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Industrial Dock Leveler, Roller shutter",
      "imgs": [
        "LOGOSBN1.jpg"
      ],
      "id": 13
    },
    {
      "name": "SLP PARK HẢI PHÒNG",
      "location": "Nam Dinh Vu IP, Hai Phong City",
      "client": "Ricons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors &, Dock leveler packages",
      "imgs": [
        "SLPHP.jpg"
      ],
      "id": 14
    },
    {
      "name": "ITL Logistic ICD Song Than",
      "location": "ICD SNP Song Than - No. 7/20, street DT743, ward Binh Hoa, Thuan An district, Binh Duong province.",
      "client": "ITL ",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Docking, equipment",
      "imgs": [
        "ITLSONGTHAN.jpg"
      ],
      "id": 15
    },
    {
      "name": "ITL WAREHOUSE DA NANG",
      "location": "Lô X11, Đường ▪️ 10B, Khu Công nghiệp Hòa Khánh Mở Rộng, Xã Hòa Liên, Huyện Hòa Vang, Thành phố Đà Nẵng",
      "client": " ITL & TST",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock Leveler & Doors packages",
      "imgs": [
        "ITLDANANG.jpg"
      ],
      "id": 16
    },
    {
      "name": "KTG Industrial YP II Logistic",
      "location": "Lô CN04-1, KCN Yên Phong, Bắc Ninh",
      "client": "Kansai Vina",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors& Dock Leveler packages",
      "imgs": [
        "KTGYPII.jpg"
      ],
      "id": 17
    },
    {
      "name": "FRASERS Property RBF phase 2",
      "location": "Binh Duong Industrial Park, Thu Dau Mot, Binh Duong",
      "client": "SOL ENC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock leveler, industrial door",
      "imgs": [
        "FRASERSBD2.jpg"
      ],
      "id": 18
    },
    {
      "name": "GAWNP Hai Phong project",
      "location": "KCN Nam Đình Vũ, Hải Phòng",
      "client": "Hai Long Construction",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "GAWNPHP.jpg"
      ],
      "id": 19
    },
    {
      "name": "GAWNP Hà Nam",
      "location": "Dong Van IP, Ha Nam",
      "client": "GAWNP",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "GAWNPHN.jpg"
      ],
      "id": 20
    },
    {
      "name": "GAWNP Thái Nguyên",
      "location": "Yen Binh IP, Thai Nguyen",
      "client": "Kansai Vina",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install heavy duty roller shutter",
      "imgs": [
        "GAWNPTN.jpg"
      ],
      "id": 21
    },
    {
      "name": "NEWLAND COLD STORAGE",
      "location": "Long An Province",
      "client": "DaiwaHouse Vietnam",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors and Docking system",
      "imgs": [
        "NLCS.jpg"
      ],
      "id": 22
    },
    {
      "name": "MEITO COLD STORAGE",
      "location": "Long An Province",
      "client": "DaiwaHouse Vietnam",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "MTCS.jpg"
      ],
      "id": 23
    },
    {
      "name": "DH LOGISTIC PROPERTY VIETNAM PHASE 2",
      "location": "Loc An Binh Son IP, Dong Nai",
      "client": "DaiwaHouse Vietnam",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock Leveler, Sectional door",
      "imgs": [
        "DHL2.jpg"
      ],
      "id": 24
    },
    {
      "name": "KCN PAT phase 2",
      "location": "Long An province",
      "client": "IVY Build",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock leveler, industrial door",
      "imgs": [
        "KCNPAT2.jpg"
      ],
      "id": 25
    },
    {
      "name": "KCN Ho Nai phase2",
      "location": "Dong Nai province",
      "client": "Unicons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock leveler, industrial door",
      "imgs": [
        "KCNHN.jpg"
      ],
      "id": 26
    },
    {
      "name": "KCN TAN VU",
      "location": "Nam Dinh Vu IZ, Hai Phong",
      "client": "Unicons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "KCNTV.jpg"
      ],
      "id": 27
    },
    {
      "name": "KCN PAT ",
      "location": "Long An province",
      "client": "IVY Construction",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "KCNPAT1.jpg"
      ],
      "id": 28
    },
    {
      "name": "KCN Nhon Trach Dong Nai",
      "location": "Dong Nai province",
      "client": "APG construction",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors packages",
      "imgs": [
        "KCNNT.jpg"
      ],
      "id": 29
    },
    {
      "name": "KCN Ho Nai, EPS factory",
      "location": "Ho Nai IZ, Dong Nai province",
      "client": "Newtecons",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors and Docking system",
      "imgs": [
        "KCNEPS.jpg"
      ],
      "id": 30
    },
    {
      "name": "Sembcorp Hai Duong Logistic",
      "location": "VSIP Hải Dương, tỉnh Hải Dương",
      "client": "Hoang Thinh",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install industrial sectional door",
      "imgs": [
        "SCHD.jpg"
      ],
      "id": 31
    },
    {
      "name": "SEMBCORP LOGISTIC phase3",
      "location": "VSIP Hai Phong, Hai Phong",
      "client": "Hoang Thinh Construction",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock Leveler, Overhead sectional door",
      "imgs": [
        "SCHP.jpg"
      ],
      "id": 32
    },
    {
      "name": "VIDIFI Duyen Hai Logistic Warehouse K1,K2,K3,K4,K5",
      "location": "Dinh Vu IZ, Hai Phong",
      "client": "Vidifi Duyen Hai",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock Leveler, Sectional door",
      "imgs": [
        "VDFDH.jpg"
      ],
      "id": 33
    },
    {
      "name": "IDEC YEN PHONG",
      "location": "Yen Phong expansion IP, Bac Ninh",
      "client": "IDEC Vietnam",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors, Dock systems",
      "imgs": [
        "IDECYP.jpg"
      ],
      "id": 34
    },
    {
      "name": "CORE5 HP Phase 1A",
      "location": "Nam Dinh Vu IP, Hai Phong",
      "client": "Core 5",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors",
      "imgs": [
        "CORE5HP.jpg"
      ],
      "id": 35
    },
    {
      "name": "CANADA Project",
      "location": "Le Minh Xuan IP, HCM",
      "client": "Toda construction",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "CANADA.jpg"
      ],
      "id": 36
    },
    {
      "name": "CAINIAO SMART LOGISTIC",
      "location": "Dong Nai province",
      "client": "NEWTECONS & SOL ENC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "CAINIAO.jpg"
      ],
      "id": 37
    },
    {
      "name": "SWAN Smart Logistic Vietnam (CAINIAO)",
      "location": "Long An province",
      "client": "SOL ENC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Industrial, sectional door",
      "imgs": [
        "SWAN.jpg"
      ],
      "id": 38
    },
    {
      "name": "JD Tan Duc project",
      "location": "Long An province",
      "client": "CSCEC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors & Dock Leveler packages",
      "imgs": [
        "JDTD.jpg"
      ],
      "id": 39
    },
    {
      "name": "LOGIS VALLEY LOGISTIC",
      "location": "Hai Phong province",
      "client": "KAS ENC",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Overhead sectional door",
      "imgs": [
        "LSVL.jpg"
      ],
      "id": 40
    },
    {
      "name": "GHTK VINH PHUC",
      "location": "Vinh Phuc province",
      "client": "Vinh Phuc province",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock leveler, industrial door",
      "imgs": [
        "GHTKVP.jpg"
      ],
      "id": 41
    },
    {
      "name": "VNPOST Logistic- Ha Nam",
      "location": "Dong Van IP, Ha Nam province",
      "client": "CNC Tech",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Dock Leveler, Doors packages",
      "imgs": [
        "VNP.jpg"
      ],
      "id": 42
    },
    {
      "name": "VIETLOG Logistic",
      "location": "Hung Yen province",
      "client": "Kansai Vina",
      "tags": [
        "Logistic"
      ],
      "start_time": null,
      "end_time": null,
      "cate": "Supply and install Doors packages",
      "imgs": [
        "Vietlog.jpg"
      ],
      "id": 43
    },
    {
      "name": "YUSEN LOGISTIC",
      "location": "Vietnam-Singapore IP1, Binh Duong",
      "client": "Obayashi HCM",
      "tags": [
        "Logistic"
      ],
      "start_time": "5/2017",
      "end_time": "11/2017",
      "cate": "Suppllied and install Dock, Leveler",
      "imgs": [
        "YUSENBD.jpg"
      ],
      "id": 44
    },
    {
      "name": "LOTTE COLD STORAGE",
      "location": "Long Hau IP, Long An province",
      "client": "Lotte ",
      "tags": [
        "Logistic"
      ],
      "start_time": "5/2017",
      "end_time": "7/2017",
      "cate": "Suppllied and install High speed door",
      "imgs": [
        "LOTTELA.jpg"
      ],
      "id": 45
    },
    {
      "name": "RYOBI COLD STORAGE",
      "location": "HCM city",
      "client": "Obayashi HCM",
      "tags": [
        "Logistic"
      ],
      "start_time": "11/2015",
      "end_time": "4/2016",
      "cate": "Suppllied and install Docking systems",
      "imgs": [
        "RYOBIHCM.jpg"
      ],
      "id": 46
    }
  ];