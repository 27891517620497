import React, { useMemo, useState } from 'react';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import { products, products_en } from '../../common/product';
import { useParams } from 'react-router-dom';
import { PHONE_NUMBER } from '../../common';
import TestimonialSkylink from '../../Containers/Testimonials/TestimonialSkylink';
import { combine } from './pdf-sumnary/combine';
import useDocumentTitle from '../../Components/useDocumentTitle';
import useCurrentLanguage from '../../Components/userCurrentLanguage';

const icon_bf = [
    "/images/services/cone_3.png",
    "/images/services/cone_4.png",
    "/images/services/cone_7.png"
]

const icon_at = [
    "/images/services/5-blue.png",
    "/images/services/1-white.png",
    "/images/services/7-white.png"
]


const ProductDetail = () => {

    const { route } = useParams();
    const lang = useCurrentLanguage()
    const product = (lang == 'vi' ? products : products_en).find(it => it.route == route)

    useDocumentTitle(product.title)

    const [active, setActive] = useState(0)



    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/san-pham',
                        routeName: 'san_pham'
                    }
                ]}
                pageName={product.title}
            />
            <div className="main_wrapper">
                <div className="container">
                    <div className="project_details section">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="project_details_inner">
                                    {/* <div className="post_img">
                                        <img src={product.bg} alt="blog" />
                                    </div> */}
                                    <div className="post_content">
                                        <div className="post_header">
                                            <h3 className="post_title">{product.title} - {product.contents[active].title}</h3>
                                        </div>
                                        <div className="fulltext">
                                            {combine[product.contents[active].content]}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="sidebar">
                                    <div id="widgetnav" className="widget widget_menu">
                                        <div className="sidenav">
                                            <ul className="side_menu">
                                                {
                                                    product.contents.map((item, index) => (
                                                        <li
                                                            className={`menu-item ${index == active ? 'active' : ''} `}
                                                            onClick={() => setActive(index)}
                                                            key={item.content}>
                                                            <a >
                                                                <img src={icon_bf[0]} alt="" />
                                                                <img src={icon_at[0]} alt="" />
                                                                {/* {product.title} - {item.title} */}
                                                                {`${product.hide_title ? '' : product.title + " - "}${item.title}`}
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <a href={`/pdf/products/${product.contents[active].pdffile}`} target='_blank' style={{ alignItems: 'center', display: 'flex', margin: 30, marginLeft: 0 }}>
                                        <i className='fa fa-file-pdf-o' style={{ fontSize: 30, margin: 20, marginLeft: 0, marginRight: 20 }} />
                                        {product.contents[active].pdffile}
                                    </a>

                                    <div id="custom_2" className="widget widget_side_contact">
                                        <div className="side_contact">
                                            {/* <div className="side_contact_top">
                                                <div className="side_contact_inner">
                                                    <p>Need Construction Service?</p>
                                                    <h4>Make An Appointment</h4>
                                                </div>
                                            </div> */}
                                            <div className="side_phone_inner">
                                                <div>{PHONE_NUMBER}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TestimonialSkylink pb='pd_btom_80 pd_top_80' />
            </div>
        </>
    );
};

export default ProductDetail;