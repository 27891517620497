import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const ProductHome = () => {
    const ProductData = [
        {
            id: 1,
            img: '/images/products/ic_dock.jpg',
            img02: 'images/services/ss1.png',
            number: 'ONE',
            bg: 'bg_1',
            title: 'docking_system',
            route: '/san-pham/docking-system'
        },
        {
            id: 2,
            img: '/images/products/ic_door.jpg',
            img02: 'images/services/ss2.png',
            number: 'TWO',
            bg: 'bg_1',
            title: 'industrial_door',
            route: '/san-pham/industrial-door'
        },
        {
            id: 3,
            img: '/images/products/ic_special.jpg',
            img02: 'images/services/sbg3.png',
            number: 'THREE',
            bg: 'bg_1',
            title: 'special_product',
            route: '/san-pham/special-product'
        },
        // {
        //     id: 4,
        //     img: 'images/services/s3.png',
        //     img02: 'images/services/sbg3.png',
        //     number: 'FOUR',
        //     bg: 'bg_2',
        //     title: 'Dítect Entrematic',
        //     route: '/product'
        // }
    ];

    return (
        <div className="service">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    {
                        ProductData.map(data =>
                            <ProductCardHome
                                key={data.id}
                                data={data}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

const ProductCardHome = ({ data }) => {
    const { id, img, img02, title, number, bg, route } = data;

    return (
        <div className="col-lg-4 col-md-4">
            <div className={`service_inner service_inner2 ${bg}`}>
                <div className="service_content d-flex align-self-center" style={{ justifyContent: 'center' }}>
                    <div className="icon_img">
                        <img src={img} alt="" style={{ marginTop: 20, width: 90, height: 90, borderRadius: 20 }} />
                    </div>
                    <div className="services_content_flex_cenrer">
                        <h6><Trans>{"product"}</Trans></h6>
                        <h4><Link to={route} style={{ color: 'blue', height: 30 }}><Trans>{title}</Trans></Link></h4>
                        <Link to={route} style={{ color: 'blue' }}><Trans>{"read_more"}</Trans> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                        {/* <h4><a href="/services" style={{ color: number == 'TWO' ? 'white' : 'blue' }}>{title}</a></h4>
                        <a href="/service-details" style={{ color: number == 'TWO' ? 'white' : 'blue' }}>Get Service <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a> */}
                    </div>
                </div>
                {/* <div className="main_img">
                    <img src={img02} alt="" />
                </div> */}
            </div>
        </div>
    );
};

export default ProductHome;