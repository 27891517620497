import React from 'react';
import SwiperCore, { Controller, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import TestimonialCard from '../../Components/TestimonialCards/TestimonialCard';
import { Trans } from 'react-i18next';
// import './Testimonial.css';

const TestimonialSkylink = (props) => {
    SwiperCore.use([Autoplay])
    const TestimonialData01 = [
        {
            id: '1',
            country: 'Accredo Asia Packaging',
            name: 'VO TIEN DUC',
            link: 'https://www.accredoasia.com/',
            pdffile: 'Accerdo Asia Packaging 201907.pdf',
            img: "/images/partner/package/2.png",
            content: "We, Accredo Asia Packaging, Inc., thank Skylink for their excellent services on our factory project in VSIP 2A, Binh Duong. Skylink demonstrated professionalism, understanding our project requirements, delivering high-quality products, and ensuring efficient installation. We highly recommend Skylink for future projects.",
        },
        {
            id: '2',
            country: 'DEEPC INDUSTRIAL ZONES',
            name: 'Mr Niels Hubert',
            link: 'https://www.deepc.vn/',
            pdffile: 'DeepC recomendation for Sky link.pdf',
            img: "/images/partner/letter/deepc.png",
            content: "Sky Link was chosen by Hai Phong Warehousing Workshop Worldwide for their supply and installation of industrial doors and docking system. They demonstrated quality products, project understanding, and prompt after-sales service. We recommend Sky Link for future projects.",
        },
        {
            id: '3',
            img: 'images/reviewer3.png',
            country: 'Diana Unicharm JSC.',
            name: 'Osamu Ishikawa',
            link: 'https://www.unicharm.vn/',
            pdffile: 'Diana-Unicharm recomend letter for Sky Link.pdf',
            img: "/images/partner/pharma/11.png",
            content: "Sky Link provided and installed industrial doors and dock levelers for DIANA UNICHARM JSC in Bac Ninh, meeting tight schedules and high standards. Highly recommended for future projects."
        },
        {
            id: '4',
            country: 'NG CONCEPT',
            name: 'Jeremy LEFEVRE',
            link: "https://ngconcept-ec.com/",
            pdffile: 'HNO Sky Link Recommendation Letter 14042020.pdf',
            img: "/images/partner/letter/ngconcept.png",
            content: "Sky Link supplied and installed docking equipment for NG Concept's BATI LOGISTIC HANOI project, meeting high-quality standards, schedule, budget, and safety requirements. Highly recommended for future projects."
        },
        {
            id: '5',
            country: 'IDEC GROUP ASIA VIETNAM',
            name: 'Philippe TREHO',
            link: "https://idecgroup-vietnam.com/",
            pdffile: 'Letter of recomendation for Sky Link- IDEC_20240626.pdf',
            img: "/images/partner/letter/idec.png",
            content: "Sky Link provided high-quality industrial doors and docking system for IDEC Group Asia Vietnam's Bac Ninh project, meeting our high standards and coordinating professionally. We highly recommend Sky Link for future projects."
        },
        {
            id: '6',
            country: 'Kimberly-Clark',
            name: 'MR. Bui Van Tam',
            link: "https://www.kimberly-clark.com.vn/",
            pdffile: 'Letter of recomendation for Sky Link- Kimberly Clark Vietnam-1.pdf',
            img: "/images/partner/pharma/6.png",
            content: "Sky Link renovated the loading system for Kimberly Clark Vietnam's warehouse, providing high-quality products, skilled installation, and excellent coordination. We highly recommend Sky Link for future projects."
        },
        {
            id: '7',
            country: 'VIDIFLOH',
            name: 'Đào Mạnh Sền',
            link: "https://vidifi-dh.vn/",
            pdffile: 'Letter to Skylink- VIDIFI Duyen Hai.pdf',
            img: "/images/partner/RBF_RBW_BTS/18.png",
            content: "Sky Link provided high-quality dock levelers and installation for VIDIFI Duyen Hai's warehouse in Hai Phong, demonstrating professionalism and excellent coordination. We highly recommend Sky Link for future projects."
        },
        {
            id: '8',
            country: 'Sembcorp Parks',
            name: 'Mr. Tan Enn Yong',
            link: "https://www.sembcorp.com/",
            pdffile: 'Recommendation _ Sembcorp Hai Phong_20200415.pdf',
            img:"/images/partner/RBF_RBW_BTS/14.png",
            content: "Sky Link JSC provided high-quality sectional doors and dock levelers for Sembcorp's warehouses in Hai Phong, meeting strict requirements and ensuring timely, professional installation. Highly recommended for future projects."
        },
        {
            id: '9',
            country: 'ARCHETYPE INDUSTRY MYANMAR',
            name: 'Denis Martin',
            link: "https://www.archetype-group.com/",
            pdffile: 'Recommendation CocaCola Myanmar 20160123.pdf',
            img:"/images/partner/food/5.png",
            content: "Sky Link supplied and installed high-quality industrial doors for Tebodin-Archetype at the Coca-Cola factory in Yangon, meeting strict requirements. Highly recommended for future projects."
        },
        {
            id: '10',
            country: 'MEDOCHEMIE FAR EAST LTD',
            name: 'GREGORIS CHARITONOS',
            link: "https://www.medochemie.com/",
            pdffile: 'Recommendation letter _Skylink- Medochemie.pdf',
            img:"/images/partner/pharma/3.png",
            content: "Medochemie (Far East) Co., Ltd. highly recommends Skylink for their professional, high-quality service and efficient coordination on our VSIP 2A factory project in Binh Duong, Vietnam."
        },
        {
            id: '11',
            country: 'SANOFI VIETNAM',
            name: 'DIDIER HUYARD',
            link: "https://www.sanofi.com/vi/vietnam",
            pdffile: 'Sanofi Vietnam for Sky Link,..JSC 20150626.pdf',
            img:"/images/partner/pharma/1.png",
            content: "Sanofi Vietnam highly recommends Skylink for their excellent work in supplying and installing industrial doors, including sectional doors, high-speed doors, and fire shutters for our new factory in the Saigon High Tech Park, Ho Chi Minh City. Skylink met our strict GMP standards with high-quality products and skilled installation."
        },
        {
            id: '12',
            country: 'Sika Limited (Vietnam)',
            name: 'Nguyen Thanh Nhan',
            link: "https://vnm.sika.com/",
            pdffile: 'Sika_Sky Link recomendation letter.pdf',
            img:"/images/partner/other/21.png",
            content: "Sika Limited (Vietnam) highly appreciates Skylink for their professional services on the Sika Factory Extension in VSIP Bac Ninh. Skylink provided high-quality materials, skillful installation, and effective coordination, meeting SIKA standards and ensuring schedule and safety compliance."
        },

    ]
    return (
        <div className={`testimonial ${props.pb} `}>
            <div className="container">
                <div className="section_header text-center">
                    <div className="shadow_icon"><img src="images/shadow_icon3.png" alt="" /></div>
                    <h6 className="section_sub_title"><Trans>{"customer_reviews"}</Trans></h6>
                    <h1 className="section_title"><Trans>{"customer_say"}</Trans></h1>
                    {/* <p className="section_desc">Skylink là công ty sản xuất cửa cuốn an toàn, đáng tin cậy và hiệu quả về chi phí.</p> */}
                </div>
                <div className="row">
                    <div className="col-12" >
                        <Swiper
                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                            className="owl_testimonial1 owl-carousel owl-theme"
                            modules={[Pagination, Controller]}
                            effect={"slide"}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                            }}
                            loop={false}
                            controller={{ inverse: true }}
                            spaceBetween={50}
                            // centeredSlides={true}
                            pagination={{
                                el: '.testimonial-pagination',
                                clickable: true,
                                // dynamicBullets: true,
                            }}
                        >
                            {
                                TestimonialData01.map(data =>
                                    <SwiperSlide>
                                        <TestimonialCard
                                            key={data.id}
                                            data={data}
                                        />
                                    </SwiperSlide>)
                            }
                        </Swiper>

                        {/* <!-- Add Pagination --> */}
                        <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', width: '20%', marginLeft: '41.5%' }}>
                            <div className="testimonial-pagination"></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSkylink;