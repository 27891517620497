import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { APP_NAME } from '../common';

function useDocumentTitle(title, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    const { t } = useTranslation();
    useEffect(() => {
        document.title = t(title) + APP_NAME;
    }, [t, title]);


    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }, [])
}

export default useDocumentTitle