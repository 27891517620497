import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    //Title page
                    "home_title": "Home page",
                    "about_title": "About",
                    "letter_title": "Open Letter",
                    "overview_title": "Overview",
                    "vision_mission_title": "Vision, Mission",
                    "product_title": "Products",
                    "docking_system_title": "Docking System",
                    "industrial_door_title": "Industrial Door",
                    "special_product_title": "Special Product",
                    "project_title": "Projects",
                    "info_title": "More",
                    "news_title": "News",
                    "partner_title": "Partner, Customer",
                    "contact_title": "Contact",

                    // Header
                    "call_now": "Call now",
                    "learn_more": "Learn more",
                    "work_time": "Monday - Friday (8.00am - 8.00pm)",

                    "yeu_cau_bao_gia": "Request Free Quote",
                    "home": "Home",
                    "gioi_thieu": "About Us",
                    "ve_chung_toi": "About Us",
                    "thu_ngo": "Open Letter",
                    "gioi_thieu_chung": "Overview",
                    "tam_nhin_su_menh": "Vision, Mission",
                    'ho_so_nang_luc': "Capacity Profile",
                    "co_cau_to_chuc": "Organizational Structure",
                    "dich_vu_bao_tri": "Maintenance service",
                    "san_pham": "Products",
                    "du_an": "Projects",
                    "lien_he": "Contact",
                    "xd_co_dien": "Sky Link ME",
                    "info": "More",
                    "news": "News",
                    "partner_customer": "Partner & Customer",

                    //Footer
                    "find_skylink": "Looking for Sky Link to collaborate. Sky Link are not just selling products, we are supplying the best solution to customers with dedicated service. Sky Link is always a safe choice.",
                    "address": "Address",
                    "address_main": "Headquarters: Lot C5, Dong Dua Area, Ha Cau Ward, Ha Dong District, Hanoi City.",
                    "address_2": "Southern Office: VSIP 2A Industrial Park, Tan Uyen, Binh Duong.",

                    //Home page  
                    "sustainable_growth": "We deliver sustainable growth",
                    "introskylink": "Sky Link consistently delivers optimal solutions tailored to your every need",
                    "first_choice": "Sky Link: The top choice for every project",
                    "first_choice_1": "Sky Link specializes in providing comprehensive solutions for cargo handling systems in industrial projects.",
                    "first_choice_2": "Leading the market and pioneering in the field of lifting equipment and industrial doors in Vietnam.",
                    "first_choice_3": "Sky Link is confident in providing you with a perfect experience from solution consulting to products and services.",
                    "reliable": "Reliable",
                    "over_100": "Over 100 partners trust us",
                    "efficient": "Efficient",
                    "perfect_efficient": "High-quality products, reasonable prices, perfect service",
                    "skylink_numbers": "Sky Link's Numbers",
                    "our_efforts": "The results speak for our efforts",
                    "staff": "Staffs",
                    "projects": "Projects",
                    "provinces": "Provinces",
                    "revenue_2023": "Revenue for 2023",
                    "billion": " B",
                    "contries": "Countries (Vietnam, Myanmar, Indonesia, Thailand, Laos, Cambodia)",
                    "contries2": "Countries",
                    "notable_projects": "NOTABLE PROJECTS",
                    "customer_reviews": "CUSTOMER REVIEWS",
                    "customer_say": "WHAT CUSTOMERS SAY ABOUT US",

                    //About Page
                    "capture": "Capturing 85% of the market share in Vietnam and being the top choice for both domestic and international investors",
                    "vision": "Vision",
                    "mission": "Mission",
                    "core": "Core Value",
                    "vision_1": "To become the leading company in lifting equipment and industrial door solutions in Vietnam.",
                    "vision_2": "A trusted destination for all customers.",
                    "mission_1": "To contribute to the sustainable success of our customers and elevate Vietnamese brands to the global stage.",
                    "core_1_h": "Integrity: ",
                    "core_1": "The guiding principle for every thought and action of each member at Sky Link.",
                    "core_2_h": "Unity: ",
                    "core_2": "Collective strength that binds all members together, leading to shared success.",
                    "core_3_h": "Optimization: ",
                    "core_3": "Continuously improving to provide comprehensive solutions for each customer, ensuring quality and cost-efficiency.",
                    "core_4_h": "Creativity: ",
                    "core_4": "Constantly innovating and updating solutions so that each project is better than the previous one.",

                    //Product Page
                    "product": "Product",
                    "products": "Products",
                    "docking_system": "Docking System",
                    "industrial_door": "Industrial Door",
                    "special_product": "Special Product",

                    //Project Page
                    "project_infomation": "Project's Infomation",
                    "client": "Customer",
                    "location": "Locaion",
                    "category": "Category",
                    "begin": "Begin",
                    "finish": "Finish",
                    "rating": "Rating",

                    //Partner
                    "partner": "Partner",
                    "in_rbf": "In RBF, RBW, BTS group",
                    "food_factory": "Food factory",
                    "pharma_factory": "Pharmaceutical factory",
                    "package_factory": "Packaging factory",
                    "other": "Other",

                    //Contact Page
                    "send_us": "SEND US A MESSAGE",
                    "contact_us": "Contact us now for the quickest consultation and answers to your questions",

                    // Common
                    "read_more": "Read More",
                    "company_name": "SKYLINK JOINT STOCK COMPANY",
                    "years": "Years",
                    "experience": "of Experience",
                    "all": "All",
                    "read_article": "READ ARTICLE"
                }
            },
            vi: {
                translations: {
                    //Title page
                    "home_title": "Trang chủ",
                    "about_title": "Giới thiệu",
                    "letter_title": "Thư ngỏ",
                    "overview_title": "Tổng quan",
                    "vision_mission_title": "Tầm nhìn, Sứ mệnh",
                    "product_title": "Sản phẩm",
                    "docking_system_title": "Thiết bị nâng hạ",
                    "industrial_door_title": "Cửa công nghiệp",
                    "special_product_title": "Sản phẩm đặc biệt",
                    "project_title": "Dự án",
                    "info_title": "Thông tin",
                    "news_title": "Tin tức",
                    "partner_title": "Đối tác, Khách hàng",
                    "contact_title": "Liên hệ",

                    // Header
                    "call_now": "Gọi ngay",
                    "learn_more": "Tìm hiểu thêm",
                    "work_time": "Thứ 2 - Thứ 7 (8h00 - 20h00)",

                    "yeu_cau_bao_gia": "Yêu cầu báo giá",
                    "home": "Trang chủ",
                    "gioi_thieu": "Giới thiệu",
                    "ve_chung_toi": "Về chúng tôi",
                    "thu_ngo": "Thư ngỏ",
                    "gioi_thieu_chung": "Tổng quan",
                    'tam_nhin_su_menh': "Tầm nhìn, sứ mệnh",
                    'ho_so_nang_luc': "Hồ sơ năng lực",
                    "co_cau_to_chuc": "Cơ cấu tổ chức",
                    "dich_vu_bao_tri": "Dịch vụ bảo trì",
                    "san_pham": "Sản phẩm",
                    "du_an": "Dự án",
                    "lien_he": "Liên hệ",
                    "xd_co_dien": "Sky Link ME",
                    "info": "Thông tin",
                    "news": "Tin tức",
                    "partner_customer": "Đối tác, Khách hàng",

                    //Footer
                    "find_skylink": "Hãy tìm đến Sky Link để hợp tác. Không chỉ là bán sản phẩm, chúng tôi đem đến cho khách hàng giải pháp tốt nhất với dịch vụ tận tâm. Sky Link luôn là một lựa chọn an toàn.",
                    "address": "Địa chỉ",
                    "address_main": "Trụ sở chính: Lô C5, Khu Đồng Dưa, Phường Hà Cầu, Quận Hà Đông, TP Hà Nội.",
                    "address_2": "Văn phòng phía Nam: KCN Vsip 2A, Tân Uyên, Bình Dương.",

                    //Home page
                    "sustainable_growth": "Chúng tôi mang đến sự phát triển bền vững",
                    "introskylink": "Sky Link luôn mang đến giải pháp tối ưu phù hợp với mọi yêu cầu của bạn",
                    "first_choice": "Sky Link Sự lựa chọn hàng đầu của mọi công trình",
                    "first_choice_1": "Sky Link chuyên cung cấp giải pháp toàn diện về hệ thống bốc dỡ hàng hóa trong công trình công nghiệp.",
                    "first_choice_2": "Là công ty dẫn đầu thị trường và tiên phong trong lĩnh vực thiết bị nâng hạ và cửa công nghiệp tại Việt Nam.",
                    "first_choice_3": "Sky Link tự tin mang đến cho bạn trải nghiệm hoàn hảo từ tư vấn giải pháp, sản phẩm và dịch vụ.",
                    "reliable": "Đáng tin cậy",
                    "over_100": "Hơn 100 đối tác tin tưởng chúng tôi",
                    "efficient": "Hiệu quả",
                    "perfect_efficient": "Sản phẩm tốt, giá thành phù hợp, dịch vụ hoàn hảo",
                    "skylink_numbers": "NHỮNG CON SỐ CỦA SKYLINK",
                    "our_efforts": "Kết quả nói lên nỗ lực của chúng tôi",
                    "staff": "Nhân sự",
                    "projects": "Dự án",
                    "provinces": "Tỉnh thành",
                    "revenue_2023": "Doanh thu năm 2023",
                    "billion": " tỷ",
                    "contries": "Quốc gia (Việt Nam, Myanmar, Indonesia, Thái Lan, Lào, Campuchia)",
                    "contries2": "Quốc gia",
                    "notable_projects": "DỰ ÁN TIÊU BIỂU",
                    "customer_reviews": "Đánh giá của khách hàng",
                    "customer_say": "KHÁCH HÀNG NÓI GÌ VỀ CHÚNG TÔI",

                    //About Page
                    "capture": "Chiếm lĩnh 85% thị phần tại Việt Nam và là sự lựa chọn hàng đầu của các nhà đầu tư trong và ngoài nước",
                    "vision": "Tầm nhìn",
                    "mission": "Sứ mệnh",
                    "core": "Cốt lõi",
                    "vision_1": "Trở thành công ty hàng đầu về giải pháp thiết bị nâng hạ và cửa công nghiệp tại Việt Nam.",
                    "vision_2": "Điểm đến tin cậy của mọi khách hàng.",
                    "mission_1": "Góp phần vào sự thành công bền vững của khách hàng. Mang thương hiệu Việt vươn tầm thế giới.",
                    "core_1_h": "Chính trực: ",
                    "core_1": "Là kim chỉ nam cho từng suy nghĩ, hành động của mỗi thành viên trong Sky Link.",
                    "core_2_h": "Đoàn kết: ",
                    "core_2": "Sức mạnh tập thể gắn kết mọi thành viên, đi đến thành công chung.",
                    "core_3_h": "Tối ưu: ",
                    "core_3": "Không ngừng cải tiến, đưa ra giải pháp toàn diện cho từng khách hàng, đáp ứng chất lượng, tối ưu chi phí.",
                    "core_4_h": "Sáng tạo: ",
                    "core_4": "Không ngừng sáng tạo, cập nhật giải pháp mới để công trình sau luôn tốt hơn công trình trước.",

                    //Product Page
                    "product": "Sản phẩm",
                    "products": "Sản phẩm",
                    "docking_system": "Thiết bị nâng hạ",
                    "industrial_door": "Cửa công nghiệp",
                    "special_product": "Sản phẩm đặc biệt",

                    //Project Page
                    "project_infomation": "Thông tin dự án",
                    "client": "Khách hàng",
                    "location": "Địa điểm",
                    "category": "Hạng mục",
                    "begin": "Bắt đầu",
                    "finish": "Kết thúc",
                    "rating": "Đánh giá",

                    // Partner Page
                    "partner": "Đối tác",
                    "in_rbf": "Trong nhóm RBF, RBW, BTS",
                    "food_factory": "Nhà máy thực phẩm",
                    "pharma_factory": "Nhà máy dược",
                    "package_factory": "Nhà máy bao bì",
                    "other": "Khác",

                    // Contact Page
                    "send_us": "GỬI TIN NHẮN CHO CHÚNG TÔI",
                    "contact_us": "Liên hệ ngay với chúng tôi để được tư vấn và giải thích các thắc mắc nhanh nhất",

                    // Common
                    "read_more": "Xem thêm",
                    "company_name": "CÔNG TY CỔ PHẦN SKYLINK",
                    "years": "Năm",
                    "experience": "Kinh Ngiệm",
                    "all": "Tất cả",
                    "read_article": "ĐỌC BÀI VIẾT"
                }
            }
        },
        fallbackLng: 'vi',

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;
