import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MobileNav from './MobileNav/MobileNav';
import { EMAIL_SKYLINK, PHONE_NUMBER, WORK_TIME } from '../../common';
import { Trans } from 'react-i18next';

const Header = () => {
    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                document.querySelector('header').classList.add('dark_mode');
                document.querySelector('header .top_bar').classList.add('top-up');
                if (window.innerWidth <= 768) {
                    document.querySelector('header .logo').classList.add('mobile-scrolled');
                }
            } else {
                document.querySelector('header').classList.remove('dark_mode');
                document.querySelector('header .top_bar').classList.remove('top-up');
                if (window.innerWidth <= 768) {
                    document.querySelector('header .logo').classList.remove('mobile-scrolled');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const [isActive, setActive] = useState(false);

    const handleToggle = () => {
        setActive(!isActive);
    };

    const [showLinks, setShowLinks] = useState(false);
    const handleMouseEnter = () => {
        if (window.innerWidth > 768) { // Adjust the width for your breakpoints
            setShowLinks(true);
        }
    };

    const handleMouseLeave = () => {
        if (window.innerWidth > 768) {
            setShowLinks(false);
        }
    };

    const handleClick = () => {
        if (window.innerWidth <= 768) {
            setShowLinks(!showLinks);
        }
    };

    return (
        <>
            <header className="header header_color">
                <div className="top_bar" id='top-bar'>
                    <div className="container">
                        <div className="top_bar_inner">
                            <div className="header_social">
                                <h6>Follow Us</h6>
                                <ul className="top_social">
                                    <li className="facebook"><a href="https://www.facebook.com/Skylink.com.vn" target='_blank'><i className="ion-social-facebook" style={{ fontSize: '24px' }}></i></a></li>
                                    <li className="twitter"><a href="https://wa.me/84932318582" target='_blank'><i className="ion-social-whatsapp" style={{ fontSize: '23px' }}></i></a></li>
                                    <li className="twitter"><a href="https://u.wechat.com/kD69stR7Ce7Va5-AiCAput0" target='_blank'><i className="fa fa-wechat" style={{ fontSize: '22px' }}></i></a></li>
                                    <li className="linkedin"><a href="https://www.linkedin.com/company/sky-link-joint-stock-company" target='_blank'><i className="ion-social-linkedin" style={{ fontSize: '24px' }}></i></a></li>
                                    <li className="zalo"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                    >
                                        <a target='_blank' >
                                            <img src='/images/icons/light_zalo.svg' className="light_mode_icon" style={{ marginTop: -10 }}></img>
                                        </a>
                                        <a target='_blank' >
                                            <img src='/images/icons/dark_zalo.svg' className="dark_mode_icon" style={{ marginTop: -10 }}></img>
                                        </a>
                                        {showLinks && (
                                            <div className="more-links">
                                                <a href="https://zalo.me/0932318582" target="_blank" rel="noopener noreferrer"><span style={{ fontSize: 12 }}>John Nguyen</span></a>
                                                <a href="https://zalo.me/0904568536" target="_blank" rel="noopener noreferrer"><span style={{ fontSize: 12 }}>Thang Trinh</span></a>
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className="header_info">
                                <div className="schedule">
                                    <div className="hide_icon d-md-none" ><Trans>{WORK_TIME}</Trans> <span><i className="fa fa-clock-o"></i></span></div>
                                    <div className="d-none d-md-block"><span><i className="fa fa-clock-o"></i></span> <Trans>{WORK_TIME}</Trans></div>
                                </div>
                                <div className="schedule">
                                    <div className="hide_icon d-md-none" data-text={EMAIL_SKYLINK}> <span><i className="fa fa-envelope"></i></span></div>
                                    <div className="d-none d-md-block"><span><i className="fa fa-envelope"></i></span> {EMAIL_SKYLINK}</div>
                                </div>
                                <div className="free_contact">
                                    <Link to="/contact" className="btn"><Trans>{"yeu_cau_bao_gia"}</Trans></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle_bar">
                    <div className="container">
                        <div className="middle_bar_inner">
                            <div className="logo">
                                {/* <Link to="/" className="light_mode_logo"><img src="images/logo.svg" alt="logo" /></Link> */}
                                {/* <Link to="/" className="dark_mode_logo"><img src="images/logo_foot.svg" alt="logo" /></Link> */}
                                <Link to="/" className="light_mode_logo"><img src="/images/logo-skylink.png" alt="logo" style={{ padding: 10 }} /></Link>
                                <Link to="/" className="dark_mode_logo"><img src="/images/logo-skylink.png" alt="logo" style={{ padding: 10 }} /></Link>
                            </div>

                            <div className="header_right_part">
                                <div className="mainnav">
                                    <ul className="main_menu">
                                        <li className="menu-item"><Link to="/"><Trans>{"home"}</Trans></Link></li>
                                        <li className="menu-item menu-item-has-children"><Link to="/gioi-thieu"><Trans>{"gioi_thieu"}</Trans></Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/thu-ngo"><Trans>{"thu_ngo"}</Trans></Link></li>
                                                <li className="menu-item"><Link to="/gioi-thieu-chung"><Trans>{"gioi_thieu_chung"}</Trans></Link></li>
                                                <li className="menu-item"><Link to="/tam-nhin-su-menh"><Trans>{"tam_nhin_su_menh"}</Trans></Link></li>
                                                <li className="menu-item"><a href="/pdf/SKY LINK PROFILE.pdf" target='_blank'><Trans>{"ho_so_nang_luc"}</Trans></a></li>
                                            </ul>
                                        </li>

                                        <li className="menu-item menu-item-has-children"><a href="/san-pham"><Trans>{"san_pham"}</Trans></a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><a href="/san-pham/docking-system"><Trans>{"docking_system"}</Trans></a></li>
                                                <li className="menu-item"><a href="/san-pham/industrial-door"><Trans>{"industrial_door"}</Trans></a></li>
                                                <li className="menu-item"><a href="/san-pham/special-product"><Trans>{"special_product"}</Trans></a></li>
                                                <li className="menu-item"><a href="/pdf/Catalogue-After Sales SKL.pdf" target='_blank'><Trans>{"dich_vu_bao_tri"}</Trans></a></li>
                                            </ul>
                                        </li>

                                        <li className="menu-item"><Link to="/du-an"><Trans>{"du_an"}</Trans></Link></li>

                                        {/* <li className="menu-item"><Link to="http://skylinkme.vn/" target='_blank'><Trans>{"xd_co_dien"}</Trans></Link></li> */}
                                        <li className="menu-item menu-item-has-children"><Link to="/tin-tuc"><Trans>{"info"}</Trans></Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/tin-tuc"><Trans>{"news"}</Trans></Link></li>
                                                <li className="menu-item"><Link to="/doi-tac"><Trans>{"partner_customer"}</Trans></Link></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item"><Link to="/contact"><Trans>{"lien_he"}</Trans></Link></li>

                                    </ul>
                                </div>
                                <div className="phone">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <div><span><Trans>{"call_now"}</Trans></span><br /><span className="phn_number">{PHONE_NUMBER}</span></div>
                                </div>
                                {/* <div className='header_search' onClick={handleToggle}>
                                    <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                </div>
                                <div id='open_search' className={isActive ? "active" : null}>
                                    <form className="search_form" action="search.php">
                                        <input type="text" name="search" className="keyword form-control" placeholder="Search..." />
                                        <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                    </form>
                                </div> */}

                                <button className="ma5menu__toggle position-relative d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasHome" aria-controls="offcanvasHome">
                                    <i className="ion-android-menu"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <MobileNav />
        </>
    );
};

export default Header;
