import React, { useState } from 'react';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink'
import './NewsStyle.css'
import { useParams } from 'react-router-dom';
import { newscombine } from './newscombine';

const NewsDetail = () => {

    const { route } = useParams();

    console.log(route)

    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="main_wrapper section">
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/tin-tuc',
                        routeName: 'news'
                    }
                ]}
                pageName='news'
            />
            <div className="container">
                <div className="blog_details section">
                    {newscombine(route, openModal, closeModal, selectedImage)}
                </div>
            </div>
        </div>
    )
}

export default NewsDetail