import React from 'react';
import CountUp from 'react-countup';
import { Trans } from 'react-i18next';

const FunBox01 = ({ data }) => {
    const { img, name, number, bf, at } = data;
    return (
        <div className="col-lg-6 col-sm-6">
            <div className="funbox1">
                <div className="fun_img" style={{minWidth: 78}}>
                    <img src={img} alt="icon" style={{width: 78, height: 78}} />
                </div>
                <div className="fun_content">
                    <h1><Trans>{bf}</Trans><CountUp start={0} end={number} delay={1.5}><span className="fun-number">{number}</span></CountUp><Trans>{at}</Trans></h1>
                    <p><Trans>{name}</Trans></p>
                </div>
            </div>

        </div>
    );
};

export default FunBox01;