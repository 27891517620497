import React from 'react';
import { Trans } from 'react-i18next';

const AboutBelowContent = ({ data }) => {
    const { title, description } = data;

    return (
        <div className="about_below_content">
            <i className="ion ion-ios-checkmark-outline" aria-hidden="true"></i>
            <div className="about_below_content_text">
                <h5><Trans>{title}</Trans></h5>
                <h6><Trans>{description}</Trans></h6>
            </div>
        </div>
    );
};

export default AboutBelowContent;