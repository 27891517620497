import React from 'react';
import FunBox01 from '../../Components/FunFactElements/FunBox01';
import { INFO } from '../../common';
import { Trans } from 'react-i18next';


const FunFact01 = () => {
    const FunData = [
        {
            id: '1',
            img: 'images/funfact/p3.png',
            number: INFO.staff,
            name: 'staff'
        },
        {
            id: '2',
            img: 'images/funfact/p2.png',
            number: INFO.finished_project,
            name: 'projects',
            bf:"> "
        },
        {
            id: '3',
            img: 'images/funfact/vietnam.png',
            number: INFO.province,
            // name: 'Expert Builders'
            name: 'provinces',
        },
       
        {
            id: '5',
            img: 'images/funfact/money.png',
            number: INFO.doanh_thu,
            // name: 'Ongoing Project'
            name: 'revenue_2023',
            at:'billion'
        },
        {
            id: '4',
            img: 'images/funfact/earth.png',
            number: INFO.national,
            // name: 'Ongoing Project'
            name: 'contries'
        }, 
    ];

    return (
        <div id="funfacts" className="funfacts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="section_header">
                            <h6 className="section_title" ><Trans>{"skylink_numbers"}</Trans></h6>
                            <h1 className="section_sub_title" style={{ color: 'white' }}><Trans>{"our_efforts"}</Trans></h1>
                        </div>
                        <div className="fun_bottom">
                            <div className="row">
                                {
                                    FunData.map(data =>
                                        <FunBox01
                                            key={data.id}
                                            data={data}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="man_img">
                            <img src="images/funfact/img_fun.png" alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunFact01;