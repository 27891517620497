import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ data }) => {
    const { img, bg, title, description, tag, route } = data;
    return (
        <div className={`element-item ${tag}`}>
            <div class="iconbox">
                <div class="iconbox_wrapper">
                    <div class="iconbox_image">
                        <img src={bg} alt="img" />
                        <div class="iconbox_icon">
                            <Link to={`/san-pham/${route}`}>
                                <img src={img} alt="icon" />
                            </Link>

                        </div>
                    </div>
                    <div class="iconbox_content" style={{ paddingBottom: 15 }}>
                        <h3 style={{ height: 40 }}><Link to={`/san-pham/${route}`}>{title}</Link></h3>
                        <p style={{ height: 120 }}>{description}</p>
                        <div class="read_more">
                            <Link to={`/san-pham/${route}`}><span>Xem thêm</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;