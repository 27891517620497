import React from 'react';
import VisionMissionBlock from '../../Components/AboutBlocks/VisionMissionBlock';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import useDocumentTitle from '../../Components/useDocumentTitle';

const VisionMission = () => {

    useDocumentTitle("vision_mission_title")
    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/gioi-thieu',
                        routeName: 'gioi_thieu'
                    },
                    {
                        route: '/tam-nhin-su-menh',
                        routeName: 'tam_nhin_su_menh'
                    }
                ]}
                pageName='tam_nhin_su_menh'
            />
            <div className="main_wrapper">
                <VisionMissionBlock />
            </div>
        </>

    );
};

export default VisionMission;