
import React from 'react';
import VisionDetail from '../../Components/AboutBlocks/VisionDetail';
import { INFO } from '../../common';
import { Trans } from 'react-i18next';

const VisionMissionBlock = () => {

    const headerData = {
        heading: 'NO THING IS IMPOSIBLE',
        subheading: 'Công ty cổ phần Skylink',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'vision',
            descriptions: [
                {
                    highlight: "",
                    content: "vision_1"
                },
                {
                    highlight: "",
                    content: "vision_2"
                }
            ],
            img: 'images/about/t2.png'

        },
        {
            id: '2',
            title: 'mission',
            descriptions: [
                {
                    highlight: "",
                    content: "mission_1"
                },
                // {
                //     highlight: "",
                //     content: "Mang thương hiệu Việt vươn tầm thế giới."
                // },
            ],
            img: 'images/about/t1.png'
        },
        {
            id: '3',
            title: 'core',
            descriptions: [
                {
                    highlight: "core_1_h",
                    content: "core_1"
                },
                {
                    highlight: "core_2_h",
                    content: "core_2"
                },
                {
                    highlight: "core_3_h",
                    content: "core_3"
                },
                {
                    highlight: "core_4_h",
                    content: "core_4"
                }
            ],
            img: 'images/logo-skylink.png'
        },
    ];
    return (
        <div className="experience">
            <div className="container">
                <div className="row" style={{ alignItems: 'center', display: 'flex' }}>
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>{INFO.experience}</h1>
                                    <p><Trans>{"years"}</Trans></p>
                                    <p><Trans>{"experience"}</Trans></p>
                                </div>
                            </div>
                            <img src="/images/about/2_new.png" alt="" />
                            {/* <img src="images/vision-skylink.png" alt="" /> */}
                            <div className="object">
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/3.png" alt="About" />
                                {/* <img src="images/about/s1.png" alt="About" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content about">
                            <div className="section_header">
                                {/* <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1> */}
                                {/* <p className="section_desc">Builderrine is the safe, reliable and cost effective construction company. We offer best construction Services. We have more than 35 years of experience in the field of building & construction. If yo</p> */}
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <VisionDetail
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            {/* <a className="button" href="/about">Learn More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisionMissionBlock;