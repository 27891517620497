import React, { useEffect } from 'react';
import i18n from '../../i18n';

const LangSwitcher = () => {
    useEffect(() => {
        // Day-Night Mode Switcher
        var icon = document.getElementById("lang_switcher");
        let localData = localStorage.getItem("language");

        if (localData === "vi") {
            icon.innerHTML = '<span class="fi fi-vn fis"></span>';
            i18n.changeLanguage('vi')
        } else if (localData === "en") {
            icon.innerHTML = '<span class="fi fi-gb fis"></span>';
            i18n.changeLanguage('en')
        }

        if (!localData) {
            localStorage.setItem("language", "vi");
        }

        icon.onclick = function () {
            const currentLanguage = localStorage.getItem("language");
            if (currentLanguage == "vi") {
                // icon.innerHTML = '<span><i class="ion-ios-moon"></i></span>';
                i18n.changeLanguage('en')
                localStorage.setItem("language", "en");
                window.location.reload()
            } else {
                // icon.innerHTML = '<span class="fi fi-vn fis"></span>';
                i18n.changeLanguage('vi')
                localStorage.setItem("language", "vi");
                window.location.reload()
            }
        }
    }, []);
    return (
        <div id="lang_switcher">
            <span className="fi fi-vn fis"></span>
        </div>
    );
};

export default LangSwitcher;