const HighSpeedDoorSkylink = () => {
    return (
        <>
            <h3>Technical Specifications</h3>
            <p>9000x8000 Width x Height (mm)</p>
            <p>RAL9006 Color</p>
            <p>Slats width 220mm, aluminum thickness: 0.7mm Panel</p>
            <p>Slat width 136mm Non insulated panel</p>
            <p>Bottom bar Width: 185mm</p>
            <p>Double Layer Ps Glass Window Vision</p>
            <p>Galvanized steel 2.5mm Side guide</p>
            <p>Servo motor, power supply 220V/380V ± 10% V Motor</p>
            <p>Photo beam, wireless airbag Emergency manual switch Safety systems</p>
            <p>Radar or control box Optional operation methods</p>

            <h3>Repair and Maintenance</h3>
            <p>All Sky Link products have repair and maintenance spare parts stocked in Binh Duong Province and Ha Noi City.</p>
            <p>Our standard is providing after-sales service within 8 working hours.</p>
        </>
    )
}

const DitecSectorReset = () => {
    return (
        <>
            <h3>Ditec Sector Reset</h3>
            <p>The Ditec Sector Reset is a high-performance industrial door designed to offer durability, reliability, and efficiency. It features advanced automation and safety features suitable for various industrial applications.</p>

            <h3>Technical Specifications</h3>
            <p>Dimensions: Max PL 6000 x PH 5000 mm, Min PL 900 x PH 1700 mm</p>
            <p>Power supply: 400 V 3-phase 10 A for single speed version, 230 V single-phase 16 A for INVERTERmotion</p>
            <p>Frequency: 50/60 Hz</p>
            <p>Power of motor: 0.9 kW</p>
            <p>Max. current for 24 Vdc accessories: 0.5 A</p>
            <p>IP degree for control board: IP55</p>

            <h3>Opening Speed</h3>
            <p>Single speed version: 1.0 m/s for both opening and closing</p>
            <p>INVERTERmotion version: Opening up to 3 m/s, Closing up to 0.8 m/s</p>

            <h3>Service Level</h3>
            <p>Life cycles: 1,000,000 - very heavy duty</p>
            <p>Number of operations: 600 cycles/day, max 120 cycles/hour</p>

            <h3>Extra Features</h3>
            <p>Manual opening: Semi-automatic by counterweights operated from ground by release lever, available on both sides (optional).</p>
            <p>Frame: Standard in hot galvanized steel. Optional AISI 304 stainless steel bright finishing or smooth finish oven-baked epoxy coating.</p>

            <h3>Recommended Operating Temperature</h3>
            <p>Motor + control board: -5°C to +50°C</p>
            <p>Curtain: -5°C to +50°C</p>
            <p>The limit of -5°C is related to the PVC transparent windows. For further details on the use in cold environments, please contact Ditec technical or sales support.</p>

            <h3>Standards and Certificates</h3>
            <p>{"Wind resistance class EN 12424: Class 3 with optional SOW for all dimensions, Class 2 (PL ≤ 3000 mm x PH 5000 mm), Class 1 (3000 mm < PL ≤ 4750 mm x PH 5000 mm), Class 0 (4750 < PL ≤ 6000 mm x PH 5000 mm)"}</p>
            <p>Air permeability EN 12426: NPD</p>
            <p>Water permeability EN 12425: NPD</p>
            <p>Closing door safety devices EN 12453: TOP Safety Pack, TOP T Safety Pack</p>
            <p>Available Certifications: Certificate n° DE/3627/10, Notified Laboratory n° 0497</p>

            <h3>Curtain Technical Specifications</h3>
            <p>Material: Polyester fabric PVC coated on both sides, Insulated curtain by polyester fabric PVC coated on one side and foamed on the other.</p>
            <p>Polyester thickness & weight: Standard curtain: 0.7 mm - 900 g/m², Optional 1.0 mm - 1300 g/m², Insulated: 3.5 mm - 1300 g/m²</p>
            <p>Vertical reinforcement strips: Clear PVC</p>
            <p>Thermal resistance coefficient: 5.8 W/m²K, optional 5.5 W/m²K, Insulated: 4.0 W/m²K</p>
            <p>Noise reduction: ~12 dB, Insulated: 17.5 dB</p>
            <p>Windows: Clear PVC 1 mm, available in various sizes</p>
            <p>Available colors: Standard curtain in multiple RAL colors, Insulated curtain in specific RAL colors</p>

            <h3>Contact Information</h3>
            <p>For more information, contact Ditec at info.ditec.eu@entrematic.com or visit www.ditecdoor.com.</p>
        </>
    )
}

const DitectSoftReset = () => {
    return (
        <>
            <h3>DITEC SOFT RESET</h3>
            <p>The DITEC Soft Reset door is a high-performance door designed for industrial and commercial use. It features a self-repairing function and various safety packages, including a Safety Linear Encoder and built-in photocells.</p>

            <h3>Technical Datasheet</h3>
            <p>Dimensions: Max. PL 4500 x PH 4600 mm, Min. PL 900 x PH 1000 mm</p>
            <p>Power supply: 230 V single-phase 16 A - INVERTERmotion, Frequency: 50/60 Hz, Power of motor: 0,6 kW, Max. current for 24 Vdc accessories: 0,5 A, IP degree for control board: IP55</p>
            <p>Opening speed: Opening up to 2,3 m/s - closing up to 0,8 m/s</p>
            <p>Service level: Life cycles: 500.000 - heavy duty, Number of operations: 500 cycles/day - max 100 cycles/hour</p>
            <p>Extra features: Manual opening by crank operated from ground, Optional extra sealing to improve air tightness, Optional 6m cable for PH ≤ 3500 mm</p>
            <p>Frame: Standard in hot galvanised steel. Optional: AISI 304 stainless steel, PVC column cover in black, Galvanised steel upper casing cover, Galvanised steel motor cover</p>
            <p>Recommended operating temperature: Motor + control board: - 5°C ÷ + 50°C, Curtain: - 5°C ÷ + 50°C</p>

            <h3>Standards and Certificates</h3>
            <p>Wind resistance class EN 12424: class 2 for PL ≤ 3000 mm x PH 4600 mm, class 1 for 3000 &lt; PL ≤ 4500 mm x PH 4600 mm</p>
            <p>Air permeability EN 12426: NPD</p>
            <p>Water permeability EN 12425: NPD</p>
            <p>Closing door safety devices EN 12453: Self-testing SLE Safety Linear Encoder + built-in IP67 photocells, IP65 sequential infra-red light barrier</p>

            <h3>Curtain Technical Specifications</h3>
            <p>Material: Polyester fabric PVC coated on both sides, Insulated curtain by polyester fabric PVC coated on one side and foamed on the other</p>
            <p>Thickness & weight: Standard curtain: 0,7 mm - 900 g/m², Optional: 1,0 mm - 1300 g/m², Insulated: 3,5 mm - 1300 g/m²</p>
            <p>Vertical reinforcement strips: Clear PVC (not applicable on insulated curtain)</p>
            <p>Thermal resistance coefficient: 5,8 W/m²K - optional 5,5 W/m²K, Insulated: 4,0 W/m²K</p>
            <p>Noise reduction: ~12 dB, Insulated: 17,5 dB</p>
            <p>Windows: Clear PVC 1 mm, available in various sizes</p>
            <p>Available colours: Standard and optional colours available</p>

            <h3>Dealer Information</h3>
            <p>DITEC DEALER ENTREMATIC BELGIUM NV, Waverstraat 21, B-9310 Moorsel-Aalst (Belgium)</p>
            <p>Tel: +32 53 72 98 98</p>
            <p>Fax: +32 53 72 98 50</p>
            <p>Email: info.ditec.eu@entrematic.com</p>
            <p>Website: www.ditecdoor.com</p>

            <h3>Company Information</h3>
            <p>Entrematic offers a comprehensive range of products for industrial, commercial, institutional, and residential applications, including sectional doors, loading dock equipment, high-performance doors, residential garage doors, pedestrian doors, gates and barriers, and automation and operators. Built on nearly 200 years of accumulated expertise, Entrematic aims to be the most dynamic, innovative company in the entrance automation industry, and the best partner for dealers and installers.</p>
            <p>Our well-known brands include Normstahl, Dynaco, Ditec, EM, and Nergeco.</p>
            <p>Disclaimers: For a correct use of our products, please refer to our manual. Everything mentioned in this brochure is only valid under the terms of use in the user manual and on condition that the door was properly installed and maintained and has not undergone abuse nor neglect.</p>
        </>
    )
}

const DitecSectorResetFood = () => {
    return (
        <>
            <h3>DITEC SOFT RESET FOOD</h3>
            <p>The DITEC SOFT RESET FOOD is designed with specific technical specifications and features that cater to various needs in the industrial and food sectors.</p>

            <h3>Technical Specifications</h3>
            <p>Dimensions: Max. PL 4500 x PH 4600 mm, Min. PL 900 x PH 1000 mm</p>
            <p>Power supply: 230 V single-phase 16 A - INVERTERmotion, Frequency: 50/60 Hz, Power of motor: 0.6 kW, Max. current for 24 Vdc accessories: 0.5 A, IP degree for control board: IP66</p>
            <p>Opening speed: Opening up to 2.3 m/s, closing up to 0.8 m/s</p>
            <p>Service level: Life cycles: 500,000 - heavy duty, Number of operations: 500 cycles/day - max 100 cycles/hour</p>
            <p>Extra features: Manual opening by crank operated from the ground, Optional bottom drainage system on both sides of the bottom pocket to collect water from washing/condensing</p>
            <p>Frame: Standard - bright finishing stainless steel AISI 304 frame, motor cover, side cover, and rolling shaft; Optional - Column cover, Upper casing cover in stainless steel AISI 304</p>
            <p>Recommended operating temperature: Motor + control board: -5°C to +50°C, Curtain: -5°C to +50°C</p>
            <p>Cablings: Without intermediate connections between control unit and each device to assure waterproof operation</p>

            <h3>Standards and Certificates</h3>
            <p>{"Wind resistance class EN 12424: Class 2 for PL ≤ 3000 mm x PH 4600 mm, Class 1 for 3000 < PL ≤ 4500 mm x PH 4600 mm"}</p>
            <p>Air permeability EN 12426: NPD</p>
            <p>Water permeability EN 12425: NPD</p>
            <p>Closing door safety devices EN 12453: TOP Safety Pack - Self-testing SLE Safety Linear Encoder + built-in IP67 photocells, TOP L Safety Pack - Self-testing SLE Safety Linear Encoder + sequential infra-red light barrier H1830 mm</p>
            <p>Available Certifications: Certificate n° DE/3382/09, Notified Laboratory n° 0497</p>

            <h3>Curtain Technical Specifications</h3>
            <p>Type of material: Standard, Non-tear, and Food curtain - polyester fabric PVC coated on both sides, Insulated curtain - polyester fabric PVC coated on one side and foamed on the other, Double insulated - previous one with foamed side covered with an additional layer of standard polyester PVC-coated curtain</p>
            <p>Material thickness and weight: Standard curtain - 0.7 mm, 900 g/m²; Non-tear - 1.0 mm, 1300 g/m²; Insulated - 3.5 mm, 1300 g/m²; Double-insulated - 4.2 mm, 2200 g/m²; Food curtain - 0.5 mm, 700 g/m²</p>
            <p>Vertical reinforcement strips: Clear PVC, not applicable on insulated and double insulated curtain</p>
            <p>Thermal Transmittance coefficient: Standard - 5.8 W/m²K, Non-tear - 5.5 W/m²K, Insulated and Double insulated - 4.0 W/m²K</p>
            <p>Noise reduction: ~12dB, Insulated - 17.5 dB</p>
            <p>Windows: Clear PVC 1 mm, available in various sizes, not applicable on double insulated curtain</p>
            <p>Available colors for standard curtain: Red RAL 3000, Blue RAL 5010, Orange RAL 2004, Grey RAL 7038, Yellow RAL 1021, Green RAL 6026, Cream RAL 1014, White RAL 9010, Light blue RAL 5012, Black RAL 9005</p>
            <p>Available colors for Non-tear curtain: Yellow RAL 1004, Grey RAL 7046</p>
            <p>Available colors for Insulated and Double insulated curtain: Blue RAL 5002, Grey RAL 7038</p>
            <p>Available colors for Food curtain: White RAL 9016</p>
        </>
    )
}

const DitecTraficCM = () => {
    return (
        <>
            <h3>Ditec Traffic CM</h3>
            <p>The Ditec Traffic CM is a high-performance industrial door designed for various applications, with advanced automation, high durability, and compliance with international standards.</p>

            <h3>Technical Specifications</h3>
            <p>Dimensions: Max PL 11000 x PH 7000 mm or PL 9000 x PH 8500 mm, Min PL 4500 x PH 3000 mm</p>
            <p>Power supply: 400 V 3-phase 10 A for single speed version, Frequency: 50 Hz</p>
            <p>Power of motor: 0.9 kW for K10; 1.8 kW for 2 K10; 0.55 kW for K21; 1.1 kW for 2 K21</p>
            <p>Max. current for 24 Vdc accessories: 0.5 A, IP degree for control board: IP55</p>

            <h3>Opening Speed</h3>
            <p>Single speed version: opening and closing at 0.7 m/s</p>

            <h3>Service Level</h3>
            <p>Life cycles: 1,000,000 - very heavy duty, Number of operations: 600 cycles/day, max 75 cycles/hour</p>

            <h3>Extra Features</h3>
            <p>Manual opening: semi-automatic by counterweights operated from ground by release lever, available on both sides (optional).</p>
            <p>Frame: standard in hot galvanized steel, optional smooth finish oven-baked epoxy coating.</p>

            <h3>Recommended Operating Temperature</h3>
            <p>Motor + control board: -5°C to +50°C, Curtain: -5°C to +50°C</p>
            <p>The limit of -5°C is related to the PVC transparent windows and/or modular sections. For further details on the use in cold environments, please contact your Ditec technical or sales support.</p>

            <h3>Curtain Technical Specifications</h3>
            <p>Material: Polyester fabric PVC coated on both sides. Availability of transparent PVC windows.</p>
            <p>Polyester thickness & weight: 0.7 mm - 900 g/m²</p>
            <p>Noise reduction: ~12 dB</p>
            <p>Horizontal reinforcements: Galvanized steel pipes with different configurations for higher wind resistance</p>
            <p>Available colors: Standard curtain in multiple RAL colors</p>

            <h3>Standards and Certificates</h3>
            <p>{"Wind resistance class EN 12424: Configuration with V3 steel pipes. Class 1 (4500 mm < PL ≤ 5000 mm x PH 4600 mm), Class 0 (PL > 5000 mm x PH 8500 mm)"}</p>
            <p>Air permeability EN 12426: NPD</p>
            <p>Water permeability EN 12425: NPD</p>
            <p>Closing door safety devices EN 12453: TOP Safety Pack, TOP W Safety Pack, TOP T Safety Pack</p>
            <p>Available Certifications: Certificate n° DE/3382/09, Notified Laboratory n° 0497</p>

            <h3>Contact Information</h3>
            <p>For more information, contact Entrematic Belgium NV at info.ditec.eu@entrematic.com or visit www.ditecdoor.com.</p>
        </>
    )
}

const DynacoD311 = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO D-311, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO D-311 High-Speed Door</p>
            <p><strong>Material:</strong> High-quality fabric with reinforced structure</p>
            <p><strong>Opening Speed:</strong> Up to 2.7 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 1.2 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Coated fabric for durability</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 1.5 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Wind Resistance:</strong> Rated up to class 3</p>
            <p><strong>Thermal Insulation:</strong> U-value of 1.5 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 20 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Energy Efficiency:</strong> Excellent thermal insulation to reduce energy costs.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const DynacoD313CleanRoom = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO D-313 Cleanroom door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO D-313 Cleanroom High-Speed Door</p>
            <p><strong>Material:</strong> High-quality PVC fabric with reinforced structure</p>
            <p><strong>Opening Speed:</strong> Up to 2.0 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.5 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy cleaning</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 1.5 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for cleanroom environments</p>
            <p><strong>Thermal Insulation:</strong> U-value of 2.2 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 18 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Cleanroom Compatibility:</strong> Designed to meet the stringent requirements of cleanroom environments.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const DynacoD313Emergency = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO D-313 Emergency door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO D-313 Emergency High-Speed Door</p>
            <p><strong>Material:</strong> High-quality PVC fabric with reinforced structure</p>
            <p><strong>Opening Speed:</strong> Up to 1.8 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.5 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy cleaning</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 1.5 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for emergency situations</p>
            <p><strong>Thermal Insulation:</strong> U-value of 2.0 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 18 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Emergency Compatibility:</strong> Designed to meet the stringent requirements of emergency situations.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const DynacoD501Compact = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO D-501 Compact door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO D-501 Compact High-Speed Door</p>
            <p><strong>Material:</strong> High-quality PVC fabric with reinforced structure</p>
            <p><strong>Opening Speed:</strong> Up to 2.2 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.5 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy cleaning</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 1.5 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for various environments</p>
            <p><strong>Thermal Insulation:</strong> U-value of 2.5 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 20 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Compact Design:</strong> Space-saving design suitable for various applications.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}
const DynacoD501Power = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO D-501 Power door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO D-501 Power High-Speed Door</p>
            <p><strong>Material:</strong> High-quality reinforced PVC fabric</p>
            <p><strong>Opening Speed:</strong> Up to 2.5 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.7 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy maintenance</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 2.2 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for high-pressure environments</p>
            <p><strong>Thermal Insulation:</strong> U-value of 2.2 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 22 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Powerful Performance:</strong> Designed for high-speed and high-frequency operations.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const DynacoM2Compact = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO M2 Compact door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO M2 Compact High-Speed Door</p>
            <p><strong>Material:</strong> High-quality reinforced PVC fabric</p>
            <p><strong>Opening Speed:</strong> Up to 2.0 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.5 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy cleaning</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 1.1 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for various environments</p>
            <p><strong>Thermal Insulation:</strong> U-value of 2.8 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 18 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Compact Design:</strong> Space-saving design suitable for various applications.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const DynacoM2Freezer = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO M2 Freezer door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO M2 Freezer High-Speed Door</p>
            <p><strong>Material:</strong> High-quality reinforced PVC fabric with insulation</p>
            <p><strong>Opening Speed:</strong> Up to 2.4 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.6 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy cleaning</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 1.5 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for freezer environments</p>
            <p><strong>Thermal Insulation:</strong> U-value of 1.5 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 20 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance in cold environments.</p>
            <p><strong>Thermal Insulation:</strong> Excellent insulation properties to maintain freezer temperatures.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective, especially in freezer environments. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const DynacoM2Power = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the DYNACO M2 Power door, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> DYNACO M2 Power High-Speed Door</p>
            <p><strong>Material:</strong> High-quality reinforced PVC fabric designed for power applications</p>
            <p><strong>Opening Speed:</strong> Up to 2.5 m/s</p>
            <p><strong>Closing Speed:</strong> Up to 0.7 m/s</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Smooth surface for easy cleaning</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Motorized with advanced control system</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 2.0 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Pressure Resistance:</strong> Suitable for high-pressure environments</p>
            <p><strong>Thermal Insulation:</strong> U-value of 1.8 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 22 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance in demanding environments.</p>
            <p><strong>High Speed:</strong> Fast opening and closing speeds to enhance operational efficiency.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with advanced motorized control.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door fabric and ensure it is aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the high-speed door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door fabric, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

export {
    HighSpeedDoorSkylink,
    DitecSectorReset, DitectSoftReset, DitecSectorResetFood, DitecTraficCM,
    DynacoD311, DynacoD313CleanRoom, DynacoD313Emergency,
    DynacoD501Compact, DynacoD501Power,
    DynacoM2Compact, DynacoM2Freezer, DynacoM2Power
}