import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const SkylinkSlider = () => {
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slide_content">
                                {/* <h5 className="sub_heading"><Trans>{"call_now"}</Trans></h5> */}
                                {/* <span style={{ color: 'white', backgroundColor: '#1F51FF', borderRadius: 10, padding: 3, marginLeft: -3 }}>(+84) 904 129 118</span> */}
                                <h2 className="heading" 
                                style={{ color: 'blue', borderRadius: 20, backdropFilter: 'blur(3px)', overflow: 'hidden' }}
                                >
                                    <Trans>{"sustainable_growth"}</Trans>
                                </h2>
                                <p className="desc" style={{ backgroundColor: 'AppWorkspace', borderRadius: 10, color: 'blue' }}><Trans>{"introskylink"}</Trans></p>
                                {/* <p className="desc"><Trans>{"intro1"}</Trans></p>
                                <p className="desc"><Trans>{"intro2"}</Trans></p> */}
                                <div className="slider_button">
                                    <Link to="/gioi-thieu" className="button"><Trans>{"learn_more"}</Trans></Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-5 d-flex align-items-end">
                            <div className="layer_object">
                                <img src="images/slider/layer_2.png" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkylinkSlider;