import React, { useEffect } from 'react';
import Experience from '../../Containers/Experiences/Experience';
import FunFact01 from '../../Containers/Funfacts/FunFact01';
import SkylinkSlider from '../../Containers/ThemeSliders/SkylinkSlider';
import ProductHome from '../../Containers/ServiceContainers/ProductHome';
import TestimonialSkylink from '../../Containers/Testimonials/TestimonialSkylink';
import PartnersSkylink from '../../Components/Partners/PartnersSkylink';
import ProjectHomeSkylink from '../../Containers/ProjectContainers/ProjectHomeSkylink';
import useDocumentTitle from '../../Components/useDocumentTitle';

const Home01 = () => {

    useDocumentTitle("home_title")

    return (
        <div className="main_wrapper">
            <SkylinkSlider />
            <ProductHome />
            <Experience />
            <FunFact01 />

            {/* <ProjectHome01 /> */}
            <ProjectHomeSkylink />

            <TestimonialSkylink pb='pd_btom_80' />

            {/* <Blog /> */}
            {/* <Partners01 pt='pd_tp_110' /> */}
            <PartnersSkylink />
        </div>
    );
};

export default Home01;