const DockLight = () => {
    return (
        <>
            <h3>DL619 Corrosion Proof LED Warehouse Loading Dock Light with Flexible Arm</h3>

            <h3>Light Source</h3>
            <p>5pcs Cree LED</p>
            <p>50,000 hours average rated life</p>
            <p>Beam Angle: 25° standard (15°, 45°, 60° optional)</p>

            <h3>Lens</h3>
            <p>Toughened glass (standard)</p>
            <p>Optional: Impact-resistant clear polycarbonate, impact-resistant diffuse polycarbonate</p>

            <h3>Specifications</h3>
            <p>Power Supply: Rated Voltage AC 85-265V</p>
            <p>Rated Power: 30W</p>
            <p>Luminous Flux: 3800Lm</p>
            <p>Average Lifespan: ≥50,000 hours</p>
            <p>Service Cable: Lead-in cable φ6—φ8 mm</p>
            <p>Dimension (Light Head): Φ142*167*71 mm</p>
            <p>Weight (Light Head): 1750 g</p>
            <p>Corrosion Proof: WF2</p>
            <p>Insulation Grade: I</p>
            <p>IP Grade: IP66</p>

            <h3>Features</h3>
            <ul>
                <li>Reliability: High impact resistance with appropriate structure design; polyester powder coated surface; long-term use in high temperature and corrosive environments.</li>
                <li>Safety: Dust proof and water proof with a tightening structure.</li>
                <li>Energy-Saving: High efficient semiconductor light-emitting technology, saving 70% energy compared to traditional light sources.</li>
                <li>Economy: Long lifespan light-emitting diodes; maintenance-free.</li>
                <li>Convenience: U-tape bracket mounting.</li>
                <li>Uniqueness: Excellent optical performance with high reflecting, high transmittance focus lens.</li>
            </ul>

            <h3>Application</h3>
            <p>DL619 is suitable for area illumination in plants, ports, warehouses, goods shelves, containers, etc.</p>

            <h3>Flexible Arm Specifications</h3>
            <p>Flexible Arm Length: 1m / 1.8m optional</p>
            <p>Tube Section Size: 4*4 cm</p>
            <p>Tube Wall Thickness: 2 mm</p>
            <p>Includes switch and plug socket (cable runs through the flexible arm)</p>

            <h3>Product Show</h3>
            <p>One piece per carton</p>
            <p>Gross Weight: 3 kg</p>
        </>
    )
}

export { DockLight }