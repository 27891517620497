import React from 'react';
import Pagination from '../../Components/common/Pagination';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import NewsCard from './NewsCard';
import useDocumentTitle from '../../Components/useDocumentTitle';
import useCurrentLanguage from '../../Components/userCurrentLanguage';

const NewsData = [
    {
        id: '1',
        img: 'images/news/muine1.jpg',
        blogClass: 'col-lg-4 col-md-6',
        title: 'Chuyến Du Lịch Phan Thiết - Mũi Né 2024 của Skylink JSC',
        description: 'Chuyến đi đầy kỷ niệm và hoạt động team building sôi nổi...',
        day: 6,
        month: 'Jun',
        route: 'du-lich-mui-ne-2024'
    },
    {
        id: '2',
        img: 'images/news/yep2023-1.JPG',
        blogClass: 'col-lg-4 col-md-6',
        title: 'Skylink JSC tổ chức Year end Party 2023',
        description: 'Tiệc Cuối Năm 2023 của Công Ty...',
        day: 22,
        month: 'Dec',
        route: 'yep-2023'
    },
];

const NewsData_en = [
    {
        id: '1',
        img: 'images/news/muine1.jpg',
        blogClass: 'col-lg-4 col-md-6',
        title: 'Skylink JSC’s 2024 Phan Thiet - Mui Ne Trip',
        description: 'A trip full of memories and lively team-building activities...',
        day: 6,
        month: 'Jun',
        route: 'du-lich-mui-ne-2024'
    },
    {
        id: '2',
        img: 'images/news/yep2023-1.JPG',
        blogClass: 'col-lg-4 col-md-6',
        title: 'Skylink JSC Year-End Party 2023',
        description: 'Year-End Party 2023 of the Company...',
        day: 22,
        month: 'Dec',
        route: 'yep-2023'
    },
];
const BlogSkyLink = () => {

    useDocumentTitle("news_title")
    const lang = useCurrentLanguage()

    return (
        <div className="main_wrapper">
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/tin-tuc',
                        routeName: 'news'
                    }
                ]}
                pageName='news'
            />

            <div className="blog section">
                <div className="container">
                    <div className="blog_grid">
                        <div className="row">
                            {
                                (lang == 'vi' ? NewsData : NewsData_en).map(data => <NewsCard
                                    key={data.id}
                                    data={data}
                                />)
                            }
                            {/* <Pagination /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogSkyLink;