import React, { useEffect, useRef, useState } from 'react';
import './AutoScrollSlider.css';

const customers = [
    {
        id: 0,
        group: "Đối tác",
        folder: 'core',
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    {
        id: 2,
        group: "Nhà máy thực phẩm",
        folder: 'food',
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    },
    {
        id: 1,
        group: "Trong nhóm RBF, RBW, BTS",
        folder: 'RBF_RBW_BTS',
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    },

    {
        id: 3,
        group: "Nhà máy dược",
        folder: 'pharma',
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
    {
        id: 4,
        group: "Nhà máy bao bì",
        folder: 'package',
        items: [1, 2, 3, 4, 5]
    },
    {
        id: 5,
        group: "Khác",
        folder: 'other',
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
    }
]


const list = []
for (const element of customers) {
    for (const number of element.items) {
        list.push(`/images/partner/${element.folder}/${number}.png`)
    }
}

const PartnersSkylink = () => {

    // const [list_partner, setListPartner] = useState([])
    // useEffect(() => {
    //     const list = []
    //     for (const element of customers) {
    //         for (const number of [1, 2, 3]) {
    //             list.push(`/images/partner/${element.folder}/${number}.png`)
    //         }
    //     }
    //     setListPartner(list)
    // }, [])

    const sliderRef = useRef(null);

    const [isHovered, setIsHovered] = useState(false);

    const scrollAmount = useRef(0); // Use a ref to store the scroll amount

    useEffect(() => {
        const slider = sliderRef.current;
        const scrollStep = 8; // Speed of scrolling
        const maxScroll = slider.scrollWidth - slider.clientWidth;

        const scroll = () => {
            if (!isHovered) {
                scrollAmount.current += scrollStep;
                if (scrollAmount.current >= maxScroll) {
                    scrollAmount.current = 0;
                }
                slider.scrollTo({
                    left: scrollAmount.current,
                    behavior: 'smooth',
                });
            }
        };

        const intervalId = setInterval(scroll, 100);

        return () => {
            clearInterval(intervalId);
        };
    }, [isHovered]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    return (
        <div className="slider-container" ref={sliderRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="slider-content" style={{ backgroundColor: 'white' }}>
                {/* Add your slider items here */}
                {list.map((it, index) => <img src={it} style={{ width: 200, height: 130, margin: 5, borderRadius: 10, padding: index <= 11 ? 15 : 0 }} />)}
                {/* ... more items */}
            </div>
        </div>
    );
};
export default PartnersSkylink;
