import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const NewsCard = ({ data }) => {
    const { blogClass, title, description, img, day, month, route } = data;
    return (
        <div className={blogClass}>
            <article className="blog_post">
                <div className="post_img">
                    <a href="/blog-1"><img src={img} alt="img" /></a>
                    <div className="calendar">
                        <a href="#"><span className="date">{day}</span><br />{month}</a>
                    </div>
                </div>
                <div className="post_content">
                    <div className="post_header">
                        <h3 className="post_title" style={{ height: 90 }}>
                            <Link to={`/tin-tuc/${route}`}>{title}</Link>
                        </h3>
                    </div>
                    <div className="post_intro">
                        <p style={{ height: 90 }}>{description}</p>
                    </div>
                    <div className="post_footer">
                        <div className="read_more">
                            <Link to={`/tin-tuc/${route}`}><span><Trans>{"read_article"}</Trans></span></Link>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
};

export default NewsCard;