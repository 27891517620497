import { RouterProvider } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './assets/css/style.css';
import './assets/css/fonts/font-awesome/css/font-awesome.css'
import "flag-icons/css/flag-icons.min.css";


import ThemeSwitcher from './Components/common/ThemeSwitcher';
import { routes } from './Routes/Routes';

import { withTranslation } from 'react-i18next';
import { useEffect } from 'react';
import i18n from './i18n';
import LangSwitcher from './Components/common/LangSwitcher';

import toast, { Toaster } from 'react-hot-toast';
import SkyLinkME from './Components/common/SkyLinkMe';

function App() {

  // useEffect(()=> {
  //   i18n.changeLanguage('vi')
  // }, [])

  return (
    <div>
      {/* <Loader/> */}
      {/* <ThemeSwitcher /> */}
      <LangSwitcher />
      <SkyLinkME />
      <RouterProvider router={routes} />
      <Toaster />
    </div>
  );
}

export default withTranslation('translations')(App);
