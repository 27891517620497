import { createBrowserRouter } from 'react-router-dom';

// Home Layouts
import HomeLayout from '../Layouts/HomeLayout';
import ContactPage from '../Pages/ContactPage';
import FAQ from '../Pages/FAQ';
import Home01 from '../Pages/Homes/Home01';
import ProjectDetails from '../Pages/Projects/ProjectDetails';
import TeamPage from '../Pages/TeamPage';

// Shop Layouts
import ShopLayout from '../Layouts/ShopLayout';
import ProductDetails from '../Pages/Shops/ProductDetails';
import Shop01 from '../Pages/Shops/Shop01';
import Shop02 from '../Pages/Shops/Shop02';
import Shop03 from '../Pages/Shops/Shop03';
import ShopCart from '../Pages/Shops/ShopCart';
import ShopCheckout from '../Pages/Shops/ShopCheckout';

// Shared Layouts
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Letter from '../Pages/About/Letter';
import Introduction from '../Pages/About/Introduction';
import VisionMission from '../Pages/About/VisionMission';
import AboutUs from '../Pages/About/AboutUs';
import Products from '../Pages/Product/Products';
import BlogSkyLink from '../Pages/Blogs/BlogSkyLink';
import Project from '../Pages/Projects/Project';
import ProductDetail from '../Pages/Product/ProductDetail';
import NewsDetail from '../Pages/Blogs/NewsDetail';
import Customer from '../Pages/Customer/Customer';
import Customer2 from '../Pages/Customer/Customer2';

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout />,
        children: [
            {
                path: '/',
                element: <Home01 />
            },
            // Project
            {
                path: '/du-an',
                element: <Project />,
            },
            {
                path: '/du-an/:route',
                element: <ProjectDetails />,
            },
            // ===============


            // ABOUT
            {
                path: '/gioi-thieu',
                element: <AboutUs />
            },
            {
                path: '/thu-ngo',
                element: <Letter />
            },
            {
                path: '/gioi-thieu-chung',
                element: <Introduction />
            },
            {
                path: '/tam-nhin-su-menh',
                element: <VisionMission />
            },
            // ===============

            // Product
            {
                path: '/san-pham',
                element: <Products />
            },
            {
                path: '/san-pham/docking-system',
                element: <Products />
            },
            {
                path: '/san-pham/industrial-door',
                element: <Products />
            },
            {
                path: '/san-pham/special-product',
                element: <Products />
            },
            {
                path: '/san-pham/:route',
                element: <ProductDetail />
            },
            // ===============

            {
                path: '/team',
                element: <TeamPage />
            },
            {
                path: '/tin-tuc',
                element: <BlogSkyLink />
            },
            {
                path: '/tin-tuc/:route',
                element: <NewsDetail />
            },
            // {
            //     path: '/doi-tac',
            //     element: <Customer />
            // },
            {
                path: '/doi-tac',
                element: <Customer2 />
            },
            // {
            //     path: '/faq',
            //     element: <FAQ />
            // },
            {
                path: '/contact',
                element: <ContactPage />
            },
            {
                path: '*',
                element: <ErrorPage />
            }
        ]
    }
    // {
    //     path: "/",
    //     element: <HomeLayout02/>,
    //     children: [
    //         {
    //             path: '/home-2',
    //             element: <Home02/>
    //         },
    //         {
    //             path: '*',
    //             element: <ErrorPage/>
    //         }
    //     ]
    // },
    // {
    //     path: "/",
    //     element: <ShopLayout/>,
    //     children: [
    //         {
    //             path: '/shop-1',
    //             element: <Shop01/>
    //         },
    //         {
    //             path: '/shop-2',
    //             element: <Shop02/>
    //         },
    //         {
    //             path: '/shop-3',
    //             element: <Shop03/>
    //         },
    //         // {
    //         //     path: '/shop-4',
    //         //     element: <Shop04/>
    //         // },
    //         {
    //             path: '/product',
    //             element: <ProductDetails/>
    //         },
    //         {
    //             path: '/cart',
    //             element: <ShopCart/>
    //         },
    //         {
    //             path: '/checkout',
    //             element: <ShopCheckout/>
    //         },
    //         {
    //             path: '*',
    //             element: <ErrorPage/>
    //         }
    //     ]
    // },
])

