const SectionalDoorSkylink = () => {
    return (
        <>
            <h3>Industrial Sectional Doors</h3>
            <p>When the door is opened, the door panels move under the ceiling or move vertically along the warehouse wall.</p>
            <p>• Space-saving</p>
            <p>• Durability</p>
            <p>• Safety</p>
            <p>• Insulation</p>
            <p>• Ease of installation and use</p>

            <h3>Technical Specifications</h3>
            <p>Door opening: Maximum width 9000, Maximum height 8000</p>
            <p>Panel: Double layer galvanized steel, Slat height: 500mm, Galvanized steel thickness: 0.35mm or 0.45mm, Insulated: Polyurethane (45 kg/m<sup>3</sup>), Thickness: 40 mm, 80 mm</p>
            <p>Motor: 180W, 380W</p>
            <p>Power supply: 1 phase 220V/50Hz or 3 phase 380V/50Hz</p>
            <p>Heat insulation: K~1.1W/m<sup>2</sup>.K</p>
            <p>Sound insulation: ~23db</p>
            <p>Applicable temperature: -40°C~50°C</p>
            <p>Resistance to wind load: 1.75kPa</p>
            <p>Operating speed: 0.2m/s</p>
            <p>Industrial sectional doors are made of double-layer galvanized steel, with a PU core inside the curtain, providing outstanding features of heat transfer and soundproofing compartments. With design increase the weather-resistance of the doors. They eliminate draughts and sudden drops in temperature. This is important if the premises are used to store goods that require special temperature conditions. Have good thermal and acoustic insulation properties. They reduce noise from outside and noise within working areas.</p>

            <h3>Color Options</h3>
            <p>Standard colors: RAL 9002 Grey white, RAL 9006 White aluminum, RAL 9016 Traffic white</p>
            <p>Custom colors: Other RAL colors available with additional fee</p>

            <h3>Panel</h3>
            <p>Polyester coating, Zinc coating, Steel plate, Zinc coating, Bonding layer, Polyurethane foaming (cyclopentane)</p>
            <p>Normal panel: DOW PU foam CFC free, Panel thickness 80 mm, High insulation value, Reinforcement, Better gasket, Broken bridge design</p>
            <p>Wing panel: DOW PU foam CFC free, Panel thickness 40 mm, Reinforcement, Better gasket, Broken bridge design</p>
            <p>{"Extra insulating panel: DOW PU foam CFC free, Wing design, Reinforcement, applicable for mega door width > 8m, Broken bridge design, Better insulation, Male & Female head reinforcement, stronger fixing screw, Better gasket"}</p>

            <h3>Track Systems</h3>
            <p>The options are suitable for the architectural design of the building while still ensuring the door can operate well.</p>
            <p>Vertical track, Standard track, High track</p>
            <p>The choice of hardware depends on the design characteristics of the building. One important aspect to consider is the available headroom above the door opening. With the vertical track system, it is used in applications where there is additional free headroom above the door. In cases where headroom space is limited, the high track system is a suitable option.</p>

            <h3>Safety</h3>
            <p>• Spring break protection</p>
            <p>• Slack cable protection</p>
            <p>• Cable break protection</p>
            <p>• Obstacle detection safety</p>
            <p>• Finger protection</p>
            <p>• Burglary prevention, anti-lift safety device</p>
            <p>• Torsion springs and lifting cables</p>
            <p>• Motor protection using a thermal relay</p>
            <p>• Automatic ground leveling adjustment</p>

            <h3>Window</h3>
            <p>Window frame color — signal black (RAL 9004)</p>
            <p>Size available — 650x320 mm</p>

            <h3>Operating Method</h3>
            <p>Sectional doors can be operated manually or electrically by means of a door motor. The choice of operation will be influenced by factors such as the weight of the door and frequency of use. All our doors are equipped with torsion springs which are specifically manufactured to ensure the door is perfectly balanced. Thus, minimum effort is required from the motor or your personnel when opening or closing the door.</p>
            <p>Manual operation: Standard operation is by means of chain operation</p>
            <p>Electrical operation: We supply motors suitable for dimension doors. The standard motors supplied with electrical doors have an emergency control system that allows the opening and closing of the door in the event of a power failure or emergency. Motors are provided with digital limit switches making them simpler, more accurate, and quicker to adjust. Modifications can be programmed directly from the control box, saving installation time and costs.</p>
            <p>Operation: Overhead doors have a standard three-button ‘up-stop-down’ control system. Dependent on your preferred safety options, this can be operated either by deadman control or automatically. Due to the modular concept of Loading Systems controls, these can easily be expanded with a number of additional control options. Possible expansion options are: remote control, automatic closing, infrared sensors, and detection loops or radars.</p>

            <h3>Other Door Types</h3>
            <p>Wicket and side door: To increase safety in and around the vicinity of your building. Sometimes have to separate pedestrian and vehicle traffic. We have a complete range of wickets and side doors. In cases where there is ample space around the overhead door, a side door offers an economical and safe solution. Where this space is unavailable, a wicket door can be integrated into the overhead door. The wicket doors and side doors can be fully customized, to open inside or out and to the left or right. Wicket and side doors with a low threshold also decrease the risk of pedestrians tripping. They also facilitate the passage of internal traffic. With a lower threshold, the side doors can also serve as escape doors.</p>
            <p>Full vision doors: When optimal natural light is required, we supply a full vision panel overhead door. This overhead door is available in an almost endless number of variations and is designed and manufactured completely to your bespoke requirements. The anodized aluminum sections of the full vision overhead door can be produced with a wide variety of panel options and colors. Maximum natural light and cost savings. Due to the optimum amount of natural light, additional secondary internal lighting becomes superfluous which can save you considerable energy costs.</p>
        </>
    )
}

const SectionalDoorLoadingSystem = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about our range of industrial sectional doors, including their features, specifications, benefits, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> Industrial Sectional Doors</p>
            <p><strong>Material:</strong> High-quality steel and aluminum</p>
            <p><strong>Insulation:</strong> Polyurethane foam core</p>
            <p><strong>Panel Thickness:</strong> 40 mm</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Galvanized and powder-coated</p>
            <p><strong>Color:</strong> Standard colors available, custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Manual and motorized options available</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 0.75 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Wind Resistance:</strong> Up to class 3</p>
            <p><strong>Thermal Insulation:</strong> U-value of 1.0 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 25 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Energy Efficiency:</strong> Excellent thermal insulation reduces energy costs.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with both manual and motorized options.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door panels and ensure they are aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the manual or motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the industrial sectional door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door panels, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const SectionalDoor21EN = () => {
    return (
        <>
            <h3>1. Introduction</h3>
            <p>This brochure provides detailed information about the Sectional Door 21EN-601N, including its features, specifications, and installation guidelines.</p>

            <h3>2. Product Specifications</h3>
            <p><strong>Door Type:</strong> Sectional Door 21EN-601N</p>
            <p><strong>Material:</strong> High-quality steel with an aluminum frame</p>
            <p><strong>Insulation:</strong> Polyurethane foam core for thermal efficiency</p>
            <p><strong>Panel Thickness:</strong> 45 mm</p>
            <p><strong>Dimensions:</strong> Customizable to fit various openings</p>
            <p><strong>Finish:</strong> Galvanized and powder-coated for durability</p>
            <p><strong>Color:</strong> Standard colors available, with custom colors on request</p>
            <p><strong>Windows:</strong> Optional vision panels available</p>

            <h3>3. Technical Information</h3>
            <p><strong>Operating Mechanism:</strong> Manual and motorized options available</p>
            <p><strong>Motor Specifications:</strong></p>
            <p><strong>Voltage:</strong> 230V AC, 50/60Hz</p>
            <p><strong>Power:</strong> 0.75 kW</p>
            <p><strong>Control System:</strong> PLC-based control panel with advanced safety features</p>
            <p><strong>Safety Features:</strong> Safety edge, photoelectric sensors, and emergency stop</p>
            <p><strong>Wind Resistance:</strong> Rated up to class 3</p>
            <p><strong>Thermal Insulation:</strong> U-value of 1.0 W/m²K</p>
            <p><strong>Sound Insulation:</strong> Up to 25 dB</p>

            <h3>4. Features</h3>
            <p><strong>Durability:</strong> Robust construction using high-quality materials ensures long-lasting performance.</p>
            <p><strong>Energy Efficiency:</strong> Excellent thermal insulation to reduce energy costs.</p>
            <p><strong>Safety:</strong> Equipped with multiple safety features to protect users and property.</p>
            <p><strong>Customization:</strong> Available in various sizes, colors, and configurations to meet specific needs.</p>
            <p><strong>Ease of Operation:</strong> Smooth and reliable operation with both manual and motorized options.</p>
            <p><strong>Low Maintenance:</strong> Designed for minimal maintenance requirements.</p>

            <h3>5. Installation Instructions</h3>
            <p><strong>Step 1:</strong> Measure the opening to ensure the door fits properly.</p>
            <p><strong>Step 2:</strong> Prepare the mounting surface and ensure it is level and clean.</p>
            <p><strong>Step 3:</strong> Install the door frame and secure it using appropriate hardware.</p>
            <p><strong>Step 4:</strong> Attach the door panels and ensure they are aligned correctly.</p>
            <p><strong>Step 5:</strong> Connect the manual or motorized operation mechanism as specified.</p>
            <p><strong>Step 6:</strong> Test the door to ensure it operates smoothly and securely.</p>

            <h3>6. Maintenance</h3>
            <p>Regular maintenance is essential to ensure the sectional door remains functional and effective. This includes periodic inspections, cleaning, and lubrication of moving parts.</p>
            <p><strong>Monthly:</strong> Inspect door panels, frame, and operation mechanism for wear and damage.</p>
            <p><strong>Quarterly:</strong> Lubricate moving parts and test the operation of the door.</p>
            <p><strong>Annually:</strong> Conduct a thorough inspection and servicing by a certified technician.</p>
            <p><strong>Control Panel Check:</strong> Ensure the PLC control panel is functioning correctly and update firmware if needed.</p>
        </>
    )
}

const SectionalDoorFastLink = () => {
    return (
        <>
            <h3>About FastLink</h3>
            <p>Shanghai FastLink Door Co., Limited is a national high-tech enterprise and leading provider of access intelligence solutions, specializing in industrial doors and handling equipment. Established in 2004, FastLink has grown into a trusted brand with a modern factory and advanced technology, including ERP and CRM systems.</p>

            <h3>Product Overview</h3>
            <p>FastLink offers various industrial doors such as insulation sectional doors, single panel sectional doors, and high-speed doors for cold storage. Their products are known for excellent performance, safety, reliability, and durability.</p>

            <h3>Advantages</h3>
            <p>FastLink’s products feature intelligent production, quality management according to ISO standards, reliable after-sales service, and professional consultation. They provide a 10-year supply guarantee for original accessories.</p>

            <h3>Insulation Sectional Doors (SSD-IAS)</h3>
            <p>The SSD-IAS insulation sectional doors offer excellent insulation with double-layer panels filled with polyurethane foam. They are designed to reduce heat loss and improve energy efficiency.</p>

            <h3>Insulation Sectional Doors for Cold Storage (SSD-CAV)</h3>
            <p>SSD-CAV doors are suited for cold storage environments, featuring aluminum-magnesium alloy panels, electric heating windows, and effective sealing to prevent condensation and rust.</p>

            <h3>Single Panel Sectional Doors (SSP-SAS)</h3>
            <p>SSP-SAS doors provide excellent wind resistance with aluminum-magnesium alloy panels and a specialized molding process. They are available in various sizes and colors.</p>

            <h3>Diversified Lifting Methods</h3>
            <p>FastLink offers various lifting methods to accommodate different building requirements, including standard, vertical, high-level, low-level, and special lifting configurations.</p>

            <h3>Electromotor and IoT Control System</h3>
            <p>FastLink’s electromotors include features like emergency manual chains and precise electronic limits. Their IoT control box enhances intelligent management with remote fault diagnosis and automated service requests.</p>

            <h3>FastLink’s Advantages</h3>
            <p>FastLink emphasizes rapid response and extensive after-sales service. They offer various maintenance packages, third-party insurance, and an intelligent service management platform for efficient customer support.</p>
        </>
    )
}

export { SectionalDoorSkylink, SectionalDoorLoadingSystem, SectionalDoor21EN, SectionalDoorFastLink }