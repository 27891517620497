import React, { useState } from 'react';
import { ADDRESS_MAIN, ADDRESS_2, EMAIL_SKYLINK, WORK_TIME } from '../../common';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const Footer = () => {

    const [showLinks, setShowLinks] = useState(false);
    const handleMouseEnter = () => {
        if (window.innerWidth > 768) { // Adjust the width for your breakpoints
            setShowLinks(true);
        }
    };

    const handleMouseLeave = () => {
        if (window.innerWidth > 768) {
            setShowLinks(false);
        }
    };

    const handleClick = () => {
        if (window.innerWidth <= 768) {
            setShowLinks(!showLinks);
        }
    };

    return (
        <footer className="footer">
            <div className="footer_above">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget footer_widget_padding">
                                {/* <h4 className="widget_title">
                                    About SkyLink,.JSC
                                </h4> */}
                                <div className="logo_footer">
                                    <a href="/"><img src="/images/logo-skylink-long.png" alt="Logo" /></a>
                                </div>
                                <div style={{ height: 10 }} />
                                <p style={{textAlign:'justify'}}><Trans>{"find_skylink"}</Trans></p>
                                {/* <div className="subscribe">
                                    <form className="form-inline">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <input type="email" className="form-control" placeholder="Địa chỉ Email" />
                                        </div>
                                        <button type="submit" className="btn">Nhận thông báo</button>
                                    </form>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                <h4 className="widget_title">
                                    <Trans>{"products"}</Trans>
                                </h4>
                                <div className="footer_nav">
                                    <ul className="footer_menu">
                                        <li className="menu-item"><a href="/san-pham/docking-system"><Trans>{"docking_system"}</Trans></a></li>
                                        <li className="menu-item"><a href="/san-pham/industrial-door"><Trans>{"industrial_door"}</Trans></a></li>
                                        <li className="menu-item"><a href="/san-pham/special-product"><Trans>{"special_product"}</Trans></a></li>
                                        <li className="menu-item"><a href="/pdf/Catalogue-After Sales SKL.pdf" target='_blank'><Trans>{"dich_vu_bao_tri"}</Trans></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                <h4 className="widget_title" style={{ color: 'transparent' }}>
                                    {"."}
                                </h4>
                                <div className="footer_nav">
                                    <ul className="footer_menu">
                                        <li className="menu-item"><a href="/pdf/SKY LINK PROFILE.pdf" target='_blank'><Trans>{"ho_so_nang_luc"}</Trans></a></li>
                                        {/* <li className="menu-item"><a href="/gioi-thieu"><Trans>{"gioi_thieu"}</Trans></a></li> */}
                                        <li className="menu-item"><a href="/tin-tuc"><Trans>{"news"}</Trans></a></li>
                                        <li className="menu-item"><a href="/doi-tac"><Trans>{"partner_customer"}</Trans></a></li>
                                        <li className="menu-item"><a href="http://skylinkme.vn/" target='_blank'><Trans>{"xd_co_dien"}</Trans></a></li>
                                        {/* <li className="menu-item"><a href="/about">About Us</a></li>
                                        <li className="menu-item"><a href="/blog-details">Courses</a></li>
                                        <li className="menu-item"><a href="/project-details">Enrollment</a></li>
                                        <li className="menu-item"><a href="/service-details">Be an Instructor</a></li>
                                        <li className="menu-item"><a href="/contact">Contact Us</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">
                                    <Trans>{"address"}</Trans>
                                </h4>
                                <ul>
                                    <li><i className="fa fa-map-marker" aria-hidden="true" style={{ color: 'white' }}></i><span><Trans>{ADDRESS_MAIN}</Trans></span></li>
                                    <li><i className="fa fa-map-marker" aria-hidden="true" style={{ color: 'white' }}></i><span><Trans>{ADDRESS_2}</Trans></span></li>
                                    <li><i className="fa fa-envelope" aria-hidden="true" style={{ color: 'white' }}></i><span>{EMAIL_SKYLINK}</span></li>
                                    <li><i className="fa fa-clock-o" aria-hidden="true" style={{ color: 'white' }}></i><span><Trans>{WORK_TIME}</Trans></span></li>
                                </ul>
                                <div className="side_footer_social">
                                    <ul className="bottom_social">
                                        {/* <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                        <li className="dribbble"><a href="#"><i className="ion-social-dribbble-outline"></i></a></li>
                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li> */}
                                        <li className="facebook"><a href="https://www.facebook.com/Skylink.com.vn" target='_blank'><i className="ion-social-facebook" style={{ fontSize: '24px' }}></i></a></li>
                                        <li className="twitter"><a href="https://wa.me/84932318582" target='_blank'><i className="ion-social-whatsapp" style={{ fontSize: '23px' }}></i></a></li>
                                        <li className="twitter"><a href="https://u.wechat.com/kD69stR7Ce7Va5-AiCAput0" target='_blank'><i className="fa fa-wechat" style={{ fontSize: '22px' }}></i></a></li>
                                        <li className="linkedin"><a href="https://www.linkedin.com/company/sky-link-joint-stock-company" target='_blank'><i className="ion-social-linkedin" style={{ fontSize: '24px' }}></i></a></li>
                                        <li className="zalo"
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                        >
                                            <a target='_blank' >
                                                <img src='/images/icons/dark_zalo.svg' style={{ marginTop: -5, width: 30, height: 30 }}></img>
                                            </a>

                                            {showLinks && (
                                                <div className="footer more-links">
                                                    <a href="https://zalo.me/0932318582" target="_blank" rel="noopener noreferrer" style={{ borderRadius: 0 }}><span style={{ fontSize: 12 }}>John Nguyen</span></a>
                                                    <a href="https://zalo.me/0904568536" target="_blank" rel="noopener noreferrer" style={{ borderRadius: 0 }}><span style={{ fontSize: 12 }}>Thang Trinh</span></a>
                                                </div>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="footer_bottom_inner">
                        {/* <div className="logo">
                            <a href="index"><img src="images/logo-skylink-long.png" alt="Logo" style={{padding: -10}}/></a>
                        </div> */}
                        <div className="copyright">
                            <p>Copyright &copy;2016 Skylink JSC. All rights reserved.</p>
                            <p style={{ fontSize: 12, fontStyle: "italic", }}>Designed by <a href="https://portfolio.hieutm9x.com" target="_blank" style={{ color: 'yellow' }}><b> Hieutm.</b></a></p>
                        </div>
                        {/* <div className="footer_nav_bottom">
                            <ul>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div> */}
                        <div className="totop">
                            <a href="#"><i className="ion-ios-arrow-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;