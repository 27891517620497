import React from 'react';
import { Trans } from 'react-i18next';

const Achievement = () => {
    return (
        <div className="top_feature">
            <div className="container">
                <div className="full_image_holder">
                    <img src="images/skylink-team.jpg" alt="" />
                </div>
                {/* <div className="logo_image_holder">
                    <img src="images/about/badge1.png" alt="" />
                    <img src="images/about/badge2.png" alt="" />
                    <img src="images/about/badge3.png" alt="" />
                </div> */}
                <div className="content_inner">
                    <h1><Trans>{"capture"}</Trans></h1>
                </div>
            </div>
        </div>
    );
};

export default Achievement;