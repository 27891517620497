import React from 'react';
import CountUp from 'react-countup';
import { Trans } from 'react-i18next';

const FunBox02 = ({ data }) => {
    const { img, name, number, bf, at } = data;
    return (
        <div className="funbox2">
            <div className="fun_img">
                <img src={img} alt="icon" />
            </div>
            <div className="fun_content">
                <h1><Trans>{bf}</Trans><CountUp start={0} end={number} delay={1.5}><span className="fun-number">{number}</span></CountUp><Trans>{at}</Trans></h1>
                <p><Trans>{name}</Trans></p>
            </div>
        </div>
    );
};

export default FunBox02;