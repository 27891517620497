
const DockLevelerSkyLink = () => {
    return (
        <>
            <p></p>
            <h3>SKY LINK HDL-02 Dock Leveler with Hinged Lip</h3>
            <p>Sky Link's HDL-02 Dock Leveler with Hinged Lip is designed to bridge the gap between the dock and the truck, providing a smooth transition for loading and unloading operations. This leveler is suitable for various loading capacities, ensuring efficient and safe material handling.</p>

            <h3>Specifications</h3>
            <p>The HDL-02 Dock Leveler is available in different power capacities to suit various loading requirements:</p>
            <ul>
                <li>0.75kW - 6, 8, 10 tons</li>
                <li>1.1kW - 16 tons</li>
            </ul>

            <h3>Key Features</h3>
            <p>The HDL-02 Dock Leveler is equipped with a hinged lip that ensures a reliable connection between the dock and the truck, accommodating different heights and ensuring stability during operations. It is built to withstand heavy loads and frequent usage, making it an ideal choice for busy warehouses and distribution centers.</p>

            <h3>Contact Information</h3>
            <p><strong>Business Licensed:</strong> Lot C5, Dong Dua Area, Ha Cau Ward, Ha Dong District, Hanoi, Vietnam</p>
            <p><strong>Binh Duong Service Office:</strong> Vsip IIA IZ, Tan Uyen, Binh Duong province</p>
            <p><strong>Tel:</strong> 024 3350 4259</p>
            <p><strong>Email:</strong> info@skylink.com.vn</p>
            <p><strong>Website:</strong> <a href="http://www.skylink.com.vn" target="_blank">www.skylink.com.vn</a></p>
        </>
    )
}

const DockLevelerLoadingSystem = () => {
    return (
        <>
            <p>Providing safe and efficient loading systems that comply with European Machinery Directive Guidelines and include CE-marking. Certified according to ISO 9001:2000, VCA, Investors in People, and HACCP.</p>
            <h3>We Take Care</h3>
            <p>Loading Systems provides comprehensive services from design to maintenance, ensuring maximum safety and efficiency in loading and unloading operations. We focus on fulfilling your business needs, offering bespoke design details and construction drawings.</p>

            <h3>Advanced Control Centre</h3>
            <p>The Advanced Control Centre (ACC) includes:</p>
            <p>Dock Management: Live status of loading/unloading docks, vehicle reservations, and efficiency improvements.</p>
            <p>Facility Management: Real-time status of docks, remote control of doors, and energy-saving features.</p>
            <p>Service: 24/7 monitoring, immediate problem analysis and resolution, and periodic maintenance planning.</p>
            <p>Statistics & Reporting: Optimizes transport and goods flow, with comprehensive data for operational efficiency.</p>

            <h3>Dock Design</h3>
            <p>Loading Systems assists in site layout, platform height determination, dock leveller dimensions, and more to ensure optimal functionality and safety.</p>

            <h3>Dock Levellers</h3>
            <p>Loading Systems' dock levellers provide efficient and safe loading/unloading, with options for hydraulic, extendable, and bespoke solutions tailored to specific needs.</p>

            <div class="contact-info">
                <p><strong>Business Licensed:</strong> Lot C5, Dong Dua Area, Ha Cau Ward, Ha Dong District, Hanoi, Vietnam</p>
                <p><strong>Binh Duong Service Office:</strong> Vsip IIA IZ, Tan Uyen, Binh Duong province</p>
                <p><strong>Tel:</strong> 024 3350 4259</p>
                <p><strong>Email:</strong> info@skylink.com.vn</p>
                <p><strong>Website:</strong> <a target='_blank' href="http://www.skylink.com.vn">www.skylink.com.vn</a></p>
            </div>
        </>
    )
}

const DockLevelerFastLink = () => {
    return (
        <>
            <h3>About Fastlink</h3>
            <p>Shanghai FastLink Door Co., Limited, a national high-tech enterprise titled "Shanghai Famous Brand" , is an access intelligence solution provider and manufacturer of industrial doors and handling equipment. We independently develop and manufacture products including insulation sectional doors, single panel sectional doors, PVC high speed doors, cold storage high speed doors, dock levelers, dock shelters and have a number of technology patents and European CE certification.</p>
            <p>Since its establishment in 2004, FastLink has already become a strong brand in the industry and won trust and recognition of customers in many industries and fields with excellent product quality and perfect service support. It maintains good partnership with well-known enterprises such as GLP, Vanke, ESR, Goodman, Mapletree, Yupei, Ping An Real Estate, SinoTrans, Shenzhen International, JD, Cainiao, Suning, Hema, GFS, Bosch, Benz, BMW, Bayer, BASF, P&G, L'Oreal and RT Mart.</p>
            <p>In 2010, FastLink built a modern factory of 20,000 square meters in Jiading, Shanghai, and introduced German technology and production lines, which laid a solid foundation for the company to become a leading industrial equipment manufacturer in China.</p>
            <p>At present, FastLink has established a sales network and after-sales service system in more than 20 cities nationwide. Meanwhile, we have fully introduced ERP and CRM systems, and realized the "information system + cloud platform" by the technology of the Internet of Things, so as to comprehensively improve order fulfillment and service quality.</p>
            <p>With the international management mechanism, the design and development teams with "innovation as the first competitiveness", and the service network integrating marketing, installation and after-sales service, FastLink is steadily growing into China's leading provider of access intelligence solutions.</p>

            <h3>Fastlink Dock Leveler</h3>
            <p>Fastlink dock leveler can realize the safe and fast handling process and improve the efficiency of logistics handling. The Fastlink dock levelers can effectively adjust the height difference between the carriage bottom plate of different transport vehicles and the platform, and form a slope between the rear of the vehicle and the platform, so that the handling process can be completed once, avoiding the possible accidental damage and unnecessary risks of vertical handling.</p>
            <p>The common dock leveler is a flap type structure. For places with high insulation and sealing requirements such as a cold-storage, the telescopic platform is a better solution.</p>

            <h3>Door Seal/Door Shelter Series</h3>
            <p>Fastlink door seals/door shelters can protect the cold-storage against wind, rain and extreme weather in summer and winter, with better thermal insulation performance to provide staff with a more comfortable working environment and stable environment temperature. Door seals can be divided into mechanical door shelters, sponge dock seals and inflatable door seals.</p>

            <h3>Industrial Door Series</h3>
            <p>Fastlink has a complete product portfolio of industrial doors. Fastlink adopts German continuous production equipment, and our products have passed the European CE certification, gaining a good reputation with excellent quality, first-class technology and unique design. Our products are widely used in industrial plants, warehousing, logistics and other industries.</p>

            <h3>Technical Consultation & After-Sales Services</h3>
            <p>Fastlink can provide customers with professional advice on handling bay planning and design. Our professional project engineers will be engaged in site investigation and technical disclosure, and assist in design, construction, delivery and acceptance of the whole project. Fastlink has a perfect after-sales services network that can provide customers with timely and thoughtful repair and maintenance services.</p>

        </>
    )
}
export { DockLevelerSkyLink, DockLevelerFastLink, DockLevelerLoadingSystem }