import React, { useEffect } from 'react';
import Achievement from '../../Components/AboutBlocks/Achievement';
import VisionMissionBlock from '../../Components/AboutBlocks/VisionMissionBlock';
import FunFactAbout from '../../Containers/Funfacts/FunFactAbout';
import Team from '../../Containers/Team';
import Banner from '../../Containers/Banner';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import TestimonialSkylink from '../../Containers/Testimonials/TestimonialSkylink';
import PartnersSkylink from '../../Components/Partners/PartnersSkylink';
import useDocumentTitle from '../../Components/useDocumentTitle';

const AboutUs = () => {

    useDocumentTitle('about_title')
    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/gioi-thieu',
                        routeName: 'gioi_thieu'
                    }
                ]}
                pageName='ve_chung_toi'
            />
            <div className="main_wrapper">
                <Achievement />
                <VisionMissionBlock />

                <FunFactAbout />

                {/* <Team pb='pd_btom_110' /> */}
                {/* 
                <Banner
                    title='Is Your House Secured Enough? Call Us to install Security Devices'
                    heading='Call Us Anytime'
                    phone={PHONE_NUMBER}
                    bannerType='banner type_3'
                /> */}

                <TestimonialSkylink pb='pd_btom_110' />
                <PartnersSkylink />
            </div>
        </>
    );
};

export default AboutUs;