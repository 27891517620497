import React, { useEffect } from 'react';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import useDocumentTitle from '../../Components/useDocumentTitle';
import useCurrentLanguage from '../../Components/userCurrentLanguage';

const Letter = () => {

    useDocumentTitle("letter_title")
    const lang = useCurrentLanguage()
    
    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/gioi-thieu',
                        routeName: 'gioi_thieu'
                    },
                    {
                        route: '/thu-ngo',
                        routeName: 'thu_ngo'
                    }
                ]}
                pageName='thu_ngo' />

            {
                lang == 'vi' ?
                    <Vi_html />
                    : <En_html />
            }
        </>
    );
};

const Vi_html = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="blog_details">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 pe-4">
                            <div className="blog_details_inner">
                                <div className="post_content">
                                    <div className="post_header">
                                        {/* <div className="categ"><a >SKYLINK JSC</a></div> */}
                                        <h3 className="post_title">THƯ NGỎ CỦA TỔNG GIÁM ĐỐC</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p className="highlight">
                                            Chân thành cảm ơn sự quan tâm của bạn cho Công ty Cổ phần SKYLINK.
                                        </p>
                                        <p>
                                            Từ khi thành lập vào năm 2010, Công ty Cổ phần SKYLINK đã phấn đấu hết sức mình cho sự phát triển liên tục với các kết quả kinh doanh vượt trội và sự hài lòng bởi nhiều khách hàng với chất lượng hàng đầu của các loại cửa cho nhà ở và công trình công nghiệp cùng với chất lượng dịch vụ tận tâm chuyên nghiệp.
                                        </p>
                                        <p>
                                            Từ năm 2010, SKYLINK, .JSC đã là đại lý cung cấp sản phẩm của tập đoàn Loading System và Dynaco và các dịch vụ của tập đoàn đi kèm trên toàn lãnh thổ Việt Nam. Đầu tiên và trước hết, chúng tôi rất tôn trọng yêu cầu của khách hàng và sẵn sàng để phục vụ cho lợi ích của khách hàng và công chúng, do đó nó là mong muốn mạnh mẽ của chúng tôi để cung cấp cho các khách hàng các sản phẩm có giá trị tốt nhất và dịch vụ sau bán hàng tốt nhất. Sự thành công của khách hàng là động lực to lớn để chúng tôi phát triển.
                                        </p>
                                        <p className="highlight">
                                            Sau 10 năm trên thị trường. Công ty Cổ phần SKYLINK đã liên tục phát triển và cần mở rộng phạm vi kinh doanh nhiều sản phẩm. Từ cuối năm 2013, SKYLINK trở thành nhà phân phối được ủy quyền của nhiều tên tuổi nổi tiếng cho hệ thống cửa và hệ thống sàn nâng hạ như:
                                        </p>
                                        <ul className="point_order">
                                            <li> DYNACO ENTREMATIC GROUP (Cửa cuốn nhanh và cửa phòng sạch của Bỉ)</li>
                                            <li> LOADING SYSTEM (Tổng thể giải pháp cho hệ thống bốc dỡ hàng và cửa công nghiệp của Hà Lan)</li>
                                            <li> DíTec ENTREMATIC GROUP (Cửa cuốn nhanh  của Ý)</li>
                                            <li> Kelley & Serco (Hệ thống bốc dỡ hàng cho công nghiệp thực phẩm số 1 của Mỹ)</li>
                                            <li> DORTEK (Giải pháp cửa cho phòng sạch và y tê của Anh) </li>
                                            <li> UNIFLOW (Cửa Swing và Qiuck shutter số 1 Nhật Bản )</li>
                                            <li> MobilFlex (Cửa Folding và cửa cuốn cho siêu thị và shop bán hàng cao cấp của Canada)</li>
                                            <li> Seng Kong Shutter ( Cửa cuốn chống cháy của Malaysia)</li>
                                        </ul>

                                        <p >
                                            Cùng với sự tăng trưởng kinh tế nhanh chóng của Việt Nam, đặc biệt là trong xây dựng công ty cổ phần SKYLINK cũng đã tham gia vào lĩnh vực xây lắp cơ điện công trình và đã phấn đấu hết mình vì sự phát triển liên tục không ngừng.
                                        </p>
                                        <p>
                                            Trong quãng thời gian 07 năm kinh doanh các sản phẩm cửa và hệ thống bốc xếp hàng chúng tôi đã cống hiến hết sức mình cho sự thịnh vượng của khách hàng nhưng chúng tôi cảm thấy mình còn phải phấn đấu nhiều hơn nữa để đáp ứng những nhu cầu vô tận từ các khách hàng hiện tại và các khách hàng mới, vì vậy bây giờ chúng tôi muốn nói rằng đây là thời điểm thích hợp để đổi mới kinh doanh hiện tại của chúng tôi làm cho mối quan hệ với khách hàng nhiều hơn nữa mạnh mẽ hơn nữa cho những thập kỷ mới từ bây giờ ...,
                                        </p>
                                        <p>
                                            Chúng tôi luôn lắng nghe và sẵn sàng thay đổi để đáp ứng các yêu cầu của khách hàng từ những kinh nghiệm và trải nghiệm mà chúng tôi biết và đã được chứng minh trong thị trường này.
                                        </p>
                                        <p>
                                            Xin hãy liên hệ với chúng tôi nếu chúng tôi có thể phục vụ cho doanh nghiệp của bạn bằng mọi cách và mọi phương pháp tốt nhất có thể
                                        </p>
                                        <p>
                                            Xin cảm ơn đã quan tâm.
                                        </p>
                                        <div className="post_gallery">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="anim_box">
                                                        <img src="images/sign.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="border_line"></div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

const En_html = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="blog_details">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 pe-4">
                            <div className="blog_details_inner">
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">OPEN LETTER FROM THE CEO</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p className="highlight">
                                            Sincerely thank you for your interest in Skylink Joint Stock Company.
                                        </p>
                                        <p>
                                            Since its establishment in 2010, Skylink JSC has made every effort to achieve continuous development with outstanding business results and the satisfaction of many customers through the top quality of residential and industrial doors, along with dedicated and professional service.
                                        </p>
                                        <p>
                                            Since 2010, Skylink, JSC has been an authorized distributor of Loading System and Dynaco products and services throughout Vietnam. First and foremost, we greatly respect the needs of our customers and are always ready to serve their interests and the public. Therefore, it is our strong desire to provide our customers with the best value products and the best after-sales service. The success of our customers is a great motivation for us to grow.
                                        </p>
                                        <p className="highlight">
                                            After 10 years in the market, Skylink JSC has continuously developed and needed to expand its business scope to include more products. Since the end of 2013, Skylink has become an authorized distributor for many famous names in door systems and lifting equipment such as:
                                        </p>
                                        <ul className="point_order">
                                            <li>DYNACO ENTREMATIC GROUP (High-speed doors and cleanroom doors from Belgium)</li>
                                            <li>LOADING SYSTEM (Comprehensive solutions for loading bay systems and industrial doors from the Netherlands)</li>
                                            <li>DíTec ENTREMATIC GROUP (High-speed doors from Italy)</li>
                                            <li>Kelley & Serco (Top loading bay systems for the food industry from the USA)</li>
                                            <li>DORTEK (Door solutions for cleanrooms and healthcare from the UK)</li>
                                            <li>UNIFLOW (No. 1 swing doors and quick shutters from Japan)</li>
                                            <li>MobilFlex (Folding and rolling doors for supermarkets and premium shops from Canada)</li>
                                            <li>Seng Kong Shutter (Fire-resistant rolling doors from Malaysia)</li>
                                        </ul>

                                        <p>
                                            Along with Vietnam's rapid economic growth, especially in the construction sector, Skylink JSC has also entered the field of mechanical and electrical construction and has made every effort for continuous development.
                                        </p>
                                        <p>
                                            In the seven years we have been in the door and loading system business, we have dedicated ourselves to the prosperity of our customers, but we feel we must strive even more to meet the endless demands of our current and new customers. Therefore, we now believe this is the right time to innovate our current business to strengthen our relationships with customers even further for the coming decades...
                                        </p>
                                        <p>
                                            We are always listening and ready to change to meet customer requirements based on the experiences and knowledge we have proven in this market.
                                        </p>
                                        <p>
                                            Please contact us if we can serve your business in any way and by any method possible.
                                        </p>
                                        <p>
                                            Thank you for your attention.
                                        </p>
                                        <div className="post_gallery">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="anim_box">
                                                        <img src="images/sign.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border_line"></div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Letter;