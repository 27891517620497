import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const PageHeaderSkylink = ({ routes, pageName }) => {

    return (
        <div className="page_header">
            <div className="page_header_content">
                <div className="container">
                    <ul style={{ paddingLeft: 0 }}>
                        <Link to="/"><div className="categ_header"><Trans>{"home"}</Trans></div></Link>
                        {routes.map(it => (
                            <RenderPath
                                route={it.route}
                                routeName={it.routeName}
                                key={it.route}
                            />
                        ))}
                    </ul>
                    <h1 className="heading" style={{ color: 'blue' }}><Trans>{pageName}</Trans></h1>
                </div>
            </div>
        </div>

    );
};

const RenderPath = ({ route, routeName }) => {
    return (
        <>
            <span style={{ color: 'gray' }}>{">"}</span>
            <Link to={route}> <div className="categ_header"><Trans>{routeName}</Trans></div></Link>
        </>
    )
}
export default PageHeaderSkylink;