import { DockLevelerFastLink, DockLevelerLoadingSystem, DockLevelerSkyLink } from "./dock-leveler";
import { DockShelterLoadingSystem, DockShelterSkylink } from "./dock-shelter";
import { FireDoorCurtain, FireDoorEl120, FireDoorEl60 } from "./fire-door";
import { DitecSectorReset, DitecSectorResetFood, DitecTraficCM, DitectSoftReset, DynacoD311, DynacoD313CleanRoom, DynacoD313Emergency, DynacoD501Compact, DynacoD501Power, DynacoM2Compact, DynacoM2Freezer, DynacoM2Power, HighSpeedDoorSkylink } from "./high-speed-door";
import { DoubleLayer, RollShutterSkylink, SingleLayer } from "./roll-shutter";
import { SectionalDoor21EN, SectionalDoorFastLink, SectionalDoorLoadingSystem, SectionalDoorSkylink } from "./sectional-door";
import { DockLight } from "./skylink-accessory";
import { SteelDoorSkylink } from "./steel-door";
import { TableLiftSkylink } from "./table-lift";

export const combine = {
    'dock-leveler-skylink': <DockLevelerSkyLink />,
    'dock-leveler-loading-system': <DockLevelerLoadingSystem />,
    'dock-leveler-fastlink': <DockLevelerFastLink />,

    'dock-shelter-skylink': <DockShelterSkylink />,
    'dock-shelter-loading-system': <DockShelterLoadingSystem />,

    'fire-door-curtain': <FireDoorCurtain />,
    'fire-shutter-el60': <FireDoorEl60 />,
    'fire-shutter-el120': <FireDoorEl120 />,

    'skylink-high-speed-door-catalogue': <HighSpeedDoorSkylink />,
    'ditect-sector-reset': <DitecSectorReset />,
    'ditect-soft-reset': <DitectSoftReset />,
    'ditect-soft-reset-food': <DitecSectorResetFood />,
    'ditect-traffic-cm': <DitecTraficCM />,

    'dynaco-d311': <DynacoD311 />,
    'dynaco-d313-cleanroom': <DynacoD313CleanRoom />,
    'dynaco-d313-emegency': <DynacoD313Emergency />,
    'dynaco-d501-compact': <DynacoD501Compact />,
    'dynaco-d501-power': <DynacoD501Power />,
    'dynaco-m2-compact': <DynacoM2Compact />,
    'dynaco-m2-freezer': <DynacoM2Freezer />,
    'dynaco-m2-power': <DynacoM2Power />,

    'roll-shutter-skylink-catalogue': <RollShutterSkylink />,
    'roll-shutter-single-layer': <SingleLayer />,
    'roll-shutter-double-layer': <DoubleLayer />,

    'skylink-section-door': <SectionalDoorSkylink />,
    'industrial-section-door': <SectionalDoorLoadingSystem />,
    'section-door-21en': <SectionalDoor21EN />,
    'fastlink-section-door': <SectionalDoorFastLink />,

    'skylink-steel-door': <SteelDoorSkylink />,

    'skylink-table-lift': <TableLiftSkylink />,

    'dock-light': <DockLight />
}