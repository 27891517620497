import React, { Suspense } from 'react';
import Loader from '../../Components/common/Loader';
import PageHeaderSkylink from '../../Components/common/PageHeaderSkylink';
import ProjectsContainers01 from '../../Containers/ProjectContainers/ProjectsContainers01';
import useDocumentTitle from '../../Components/useDocumentTitle';

// const ProjectsContainers01 = React.lazy(() => import("../../Containers/ProjectContainers/ProjectsContainers01"));

const Project = () => {

    useDocumentTitle("project_title")
    return (
        <>
            <PageHeaderSkylink
                routes={[
                    {
                        route: '/du-an',
                        routeName: 'du_an'
                    }
                ]}
                pageName='du_an'
            />
            {/* <Suspense fallback={<Loader />}> */}
            <ProjectsContainers01 />
            {/* </Suspense> */}
        </>
    );
};

export default Project;