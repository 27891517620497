import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import LazyLoad from 'react-lazyload';
import { forceVisible } from 'react-lazyload';

const ProjectCard01 = ({ data, updateLazy }) => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const monitorWidth = windowSize.current[0];

    // console.log(monitorWidth);

    useEffect(() => {
        if (updateLazy > 0) forceVisible()
    }, [updateLazy])

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);

    let elHeight = Number(dimensions.height);
    // console.log(typeof elHeight);

    const { imgs, location, name, tags, route } = data;

    return (
        <div ref={targetRef} className={`element-item ${tags[0]}`}>
            <div className="teambox">
                <LazyLoad height={monitorWidth >= 1536 ? 350 : 460} once={true}>
                    {/* <img src={img} alt="" /> */}
                    <img
                        src={imgs[0] ? `/images/projects_skl/${imgs[0]}` : '/images/team/Project_2_Images/5.png'}
                        alt=""
                        style={{
                            width: '100%', height: monitorWidth >= 1536 ? 350 : 460,
                            objectFit: 'cover', objectPosition: 'center'
                        }}

                    />
                    <h6 style={{ position: 'absolute', zIndex: 300, bottom: 15, left: 10, right: 10, color: 'white' }}><a href={'/du-an/' + name} style={{ color: 'white' }}>{name}</a></h6>
                </LazyLoad>

                <div className="teambox_inner">
                    {/* <div className="team_social">
                        <div className="share"><i className="ion-android-share-alt"></i></div>
                        <ul>
                            <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                            <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                            <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                            <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li>
                        </ul>
                    </div> */}
                    <div className="teambox_intro">
                        <div className="team_flex team_size">
                            <h6>{location}</h6>
                            {/* <h5><a href={'/du-an/' + name} style={{ color: 'white' }}>{name}</a></h5> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard01;