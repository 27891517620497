import Isotope from 'isotope-layout';
import React, { useEffect, useRef, useState } from 'react';
import ProjectCard01 from '../../Components/ProjectElements/ProjectCard01';
import { projects } from '../../common/projects';

const ProjectsContainers01 = () => {

    const tags = [
        {
            name: 'Factory',
            count: 3
        },
        {
            name: 'Logistic',
            count: 77
        }
    ]

    const [filterKey, setFilterKey] = useState('*');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const isoRef = useRef(null);
    const [filteredProjects, setFilteredProjects] = useState(projects);

    useEffect(() => {
        isoRef.current = new Isotope('.grid', {
            itemSelector: '.element-item',
            percentPosition: true,
            transformsEnabled: true,
            transitionDuration: '700ms',
            resize: true,
            fitWidth: true,
        });

        return () => isoRef.current.destroy();
    }, []);

    const handleFilterKeyChange = key => () => setFilterKey(key);

    useEffect(() => {
        const filtered = filterKey === '*'
            ? projects
            : projects.filter(project => project.tags.includes(filterKey));
        setFilteredProjects(filtered);
        setCurrentPage(1);
    }, [filterKey]);

    const [updateLazy, setUpdateLazy] = useState(0)

    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        const itemsToShow = filteredProjects.slice(start, end);

        isoRef.current.arrange({
            filter: itemElem => {
                const itemIndex = Array.from(itemElem.parentNode.children).indexOf(itemElem);
                const matchesPage = itemsToShow.some(project => project.id === projects[itemIndex].id);
                return matchesPage;
            }
        });
        setUpdateLazy(updateLazy + 1)

    }, [filteredProjects, currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

    return (
        <div className="main_wrapper">
            <div className="project_iso section">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {/* <div className="button-group filters-button-group button_color">
                                <button className="button" onClick={handleFilterKeyChange('*')}>Tất cả <sup className="filter-count"></sup></button>
                                {
                                    tags.map(tag => (
                                        <button style={{ marginBottom: 10 }} className="button" onClick={handleFilterKeyChange(tag.name)} key={tag}>{tag.name} <sup className="filter-count">{tag.count}</sup></button>
                                    ))
                                }
                            </div> */}

                            <div className="grid grid-4 gutter-15">
                                {
                                    projects.map(data => <ProjectCard01
                                        key={data.id}
                                        data={data}
                                        updateLazy={updateLazy}
                                    />)
                                }

                            </div>

                        </div>

                        {/* <PaginationDiv /> */}
                        <div className="pagination-div">
                            <ul className="pagination">
                                <li>
                                    <a
                                        onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                        disabled={currentPage === 1}
                                    >
                                        <i className="ion-chevron-left" />
                                    </a>
                                </li>
                                {Array.from({ length: totalPages }, (_, index) => {
                                    const page = index + 1;
                                    const isCurrentPage = currentPage === page;
                                    const startPage = Math.max(currentPage - 1, 1);
                                    const endPage = currentPage == 1 ? Math.min(3, totalPages) : Math.min(currentPage + 1, totalPages);

                                    if (page >= startPage && page <= endPage) {
                                        return (
                                            <li key={page}>
                                                <a
                                                    className={`page-number ${isCurrentPage ? 'current' : ''}`}
                                                    onClick={() => handlePageChange(page)}
                                                >
                                                    {page}
                                                </a>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                                <li>
                                    <a
                                        onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                    >
                                        <i className="ion-chevron-right" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsContainers01;